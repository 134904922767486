import { useRouter } from "hooks/useRouter";
import { useEffect, useState } from "react";
import UserService from "services/user.service";
import { MetricCard } from "./BlackSparrow";
import { formatDate } from "utils/funcs";
import { Loader } from "common/Loader";

const service = new UserService();

export const SpecificMediaAd = () => {
  const { params, push } = useRouter();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>({
    total_ad: 0,
    total_spend: "0",
    total_applicant: "0",
    total_scheduled: "0",
    total_attendees: "0",
    total_not_attendeed: "0",
    badge_id: "",
    state: "",
    city_name: "",
    notes: null,
    ad_type: "",
    ad_approval_status: "",
    ad_status: " ",
    season: " ",
    ad_amount: "",
    package_name: "",
    first_name: "",
    last_name: "",
    full_name: " ",
    who_is_paying_for_ad:"",
    meet_link: "",
    package_start_date: "",
    package_end_date: "",
    ad_run_day_count: 3,
  });

  useEffect(() => {
    setLoading(true);
    service
      .getMediaAdDetail(params.id)
      .then((resp) => {
        setData(resp.data);
      })
      .catch((err) => console.error(err))
      .finally(() => setLoading(false));
  }, [params]);

  if (loading) return <Loader />;

  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        <div className="flex  gap-2">
          <button
            type="button"
            onClick={() => push("/media-ads")}
            className="bg-white rounded-md p-2 border"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="h-6 w-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
              />
            </svg>
          </button>
          <div>
            <h1 className="font-medium text-gray-700 tracking-wide text-xl">
              Media Ad Details
            </h1>
            <p className="text-gray-500 text-sm">View details</p>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-2 mb-5">
        <p className="text-2xl font-semibold text-zinc-700">
          {data?.data?.city_name}, {data?.data?.state}
        </p>
        <span className="text-green-600 leading-none">
          {data?.data?.ad_status}
        </span>
      </div>
      <div className="grid grid-cols-4 gap-4">
        <MetricCard displayName="Total Spend" value={`$${data.total_spend}`} />
        <MetricCard
          displayName="Total Applicats"
          value={data.total_applicant}
        />
        <MetricCard
          displayName="Total Shceduled"
          value={data.total_scheduled}
        />
        <MetricCard
          displayName="Total Attendees"
          value={data.total_attendees}
        />
      </div>
      <div className="flex gap-4 items-start mt-5">
        <div className="flex flex-col w-full md:w-[35%] gap-2">
          <div className="bg-white shadow-sm border border-zinc-200 flex flex-col  px-4 py-2 rounded-lg divide-y divide-zinc-200">
          
            <div className="flex justify-between gap-4 py-3">
              <span>Ad Type</span>
              <span>{data?.data?.ad_type}</span>
            </div>
            <div className="flex justify-between gap-4 py-3">
              <span>City</span>
              <span className="break-all">{data?.data?.city_name}</span>
            </div>
            <div className="flex justify-between gap-4 py-3">
              <span>State</span>
              <span>{data?.data?.state}</span>
            </div>
            <div className="flex justify-between gap-4 py-3">
              <span>Creation Date</span>
              <span>{formatDate(data?.data?.createdAt)}</span>
            </div>
            <div className="flex justify-between gap-4 py-3">
                <span className="whitespace-nowrap">Meet Link</span>
                <span className="text-right">{data?.data?.meet_link}</span>
              </div>
              <div className="flex justify-between gap-4 py-3">
                <span>Who is paying for ad?</span>
                <span>{data?.data?.who_is_paying_for_ad}</span>
              </div>
              {data?.data?.who_is_paying_for_ad !== "Myself" && <div className="flex justify-between gap-4 py-3">
                <span>User Paying for Ad</span>
                <span>{data?.data?.bill_split_between.map(item => item.name).join(',')}</span>
              </div>}
            
          </div>
          <div className="bg-white  shadow-sm border border-zinc-200 flex flex-col  px-4 py-2 rounded-lg divide-y divide-zinc-200">
            <div className="flex justify-between gap-4 py-3">
              <span>Email</span>
              <span>{data?.data?.personal_gmail_account}</span>
            </div>
            <div className="flex justify-between gap-4 py-3">
              <span>Start Date</span>
              <span>{formatDate(data?.data?.package_start_date)}</span>
            </div>
            <div className="flex justify-between gap-4 py-3">
              <span>End Date</span>
              <span>{formatDate(data?.data?.package_end_date)}</span>
            </div>
            <div className="flex justify-between gap-4 py-3">
              <span>Ad Fee</span>
              <span>$ {data?.data?.ad_amount}</span>
            </div>
            <div className="flex justify-between gap-4 py-3">
              <span>Override per FP+</span>
              <span>$ 20</span>
            </div>
            {data?.data?.notes && (
              <div className="flex justify-between gap-4 py-3">
                <span>Admin Notes</span>
                <span>{data?.data?.notes}</span>
              </div>
            )}
            {data?.data?.ad_spend && (
              <div className="flex justify-between gap-4 py-3">
                <span>Ad Spend</span>
                <span>$ {data?.data?.ad_spend}</span>
              </div>
            )}
          </div>
        </div>

        <div className="grid grid-cols-2 place-items-start gap-4 w-full">
          {data?.data?.recruiting_interview?.map((item) => (
            <div
              key={item.block_name}
              className="flex flex-col  bg-white w-full shadow-sm border border-zinc-200 px-4 py-2 rounded-lg   divide-y divide-zinc-200"
            >
              <div className="flex  gap-4 py-3">
                <span>Interview Session | {item.block_name}</span>
                <span></span>
              </div>
              <div className="flex justify-between gap-4 py-3">
                <span>Date</span>
                <span className="break-all">{formatDate(item?.date)}</span>
              </div>
              <div className="flex justify-between gap-4 py-3">
                <span>Start Time</span>
                <span>{item?.start_time}</span>
              </div>
              <div className="flex justify-between gap-4 py-3">
                <span>End Time</span>
                <span>{item?.end_time}</span>
              </div>
              <div className="flex justify-between gap-4 py-3">
                <span>Timezone</span>
                <span>{item?.time_zone}</span>
              </div>
              <div className="flex justify-between gap-4 py-3">
                <span>Interview Type</span>
                <span>{item?.interview_type}</span>
              </div>

              {item?.office_name && (
                <div className="flex justify-between gap-4 py-3">
                  <span>Office Name</span>
                  <span>{item?.office_name}</span>
                </div>
              )}
              {item?.interview_type === "In-Person" && (
                <div className="flex justify-between text-right gap-4 py-3">
                  <span>Address</span>
                  <span>
                    {item?.address}, {item?.state}, {item?.city},{" "}
                    {item?.zip_code}
                  </span>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
