import { useEffect, useState } from "react";
import UserService from "services/user.service";
import { tabStyles } from "./GeneralData";
import classNames from "classnames";
import { useRouter } from "hooks/useRouter";
import { DATE } from "./trainings/WeeklyStats";
import {  ManagerStats } from "./media-ads/ManagerStats";
import { AdStats } from "./media-ads/AdStats";
import { AdSpending } from "./media-ads/AdSpending";

const service = new UserService();

export const MediaStats = () => {
  const [showCustom, setShowCustom] = useState(false);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [date, setDate] = useState("YEARTODATE");

  const [data, setData] = useState<any>({
    manager_wise_stats: [],
    social_media_ad_stats: [],
    ad_counts_with_price: []
  });

  const { query, push } = useRouter();

  useEffect(() => {
    setLoading(true);
    service
      .getMediaStats({ date })
      .then((resp) => {
        setData(resp.data);
      })
      .catch((err) => {
        console.log(err);
      }).finally(() => {
        setLoading(false);
      })
  }, [date]); 

  return (
    <div>
      <div className="flex items-center gap-2">
      <button
          onClick={() => push("/media-ads")}
          className="rounded-md bg-white border border-gray-200 px-4 py-2"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-5 h-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
            />
          </svg>
        </button>
        <select
        value={date}
        className="rounded-md focus:border-gray-300 font-light focus:outline-none w-60 focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
        onChange={(e) => {
          if (e.target.value !== "CUSTOM") {
            setDate(e.target.value);
          } else {
            setShowCustom(true);
          }
        }}
      >
        <option>Select</option>
        {DATE.map((item) => (
          <option value={item} key={item}>
            {item}
          </option>
        ))}
      </select>
      </div>
     
      <div className="text-sm my-4 p-2 text-center text-gray-500 rounded-md bg-gray-200">
        <div className="flex flex-wrap -mb-px gap-3">
          <button
            type="button"
            onClick={() => push("/media-stats")}
            className={classNames(tabStyles.default, {
              [tabStyles.active]: !query.tab,
            })}
          >
            Manager Stats
          </button>
          <button
            type="button"
            onClick={() => push("/media-stats?tab=ad-stats")}
            className={classNames(tabStyles.default, {
              [tabStyles.active]: query.tab === "ad-stats",
            })}
          >
             Ad Stats
          </button>
          <button
            type="button"
            onClick={() => push("/media-stats?tab=spending")}
            className={classNames(tabStyles.default, {
              [tabStyles.active]: query.tab === "spending",
            })}
          >
             Ad Sepnding
          </button>
        </div>
      </div>
      {!query.tab ? <ManagerStats loading={loading} data={data?.manager_wise_stats} /> : query.tab === "ad-stats" ? <AdStats data={data.social_media_ad_stats} /> : <AdSpending stats={data.ad_counts_with_price} /> }
    </div>
  );
};
