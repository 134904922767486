export const NAMES = [
  "first name",
  "last name",
  "email",
  "phone",
  "contact leader id",
  "d2d exp",
  "other",
  "notes",
  "date_submitted",
];

export const CONTACT = [
  "first contact created date",
  "first contact+ date",
  "first signed date",
  "first signed+ date",
  "first recruiter date",
  "first recruiter+",
  "first sh pro date",
  "first sh pro+ date",
];

export const SESSIONS = [
  "online info session (registered)",
  "online info session (attended)",
  "weekly training (rsvp)",
  "weekly training (attended)",
  "applicaiton (submitted)",
  "agreement sent",
  "agreement signed",
  "onboard date",
  "last street genie login",
  "completed onboard training",
  "manager interview",
  "preseason blitz (registered)",
  "preseason blitz (attended)",
  "list started 100",
  "list completed 100",
];

export const UPGRADE = [
  "first upgrade date",
  "first install date",
  "last sh install date",
  "last solar lead date",
  "last solar closed date",
  "last solar permit date",
  "last solar install date",
  "last recruit with sale date",
];

export const MANAGEMENT = [
  "team",
  "management group",
  "senior management group",
  "region",
  "senior region",
  "partnership",
  "division",
];

export const LEADER = [
  "contact leader phone number",
  "contact leader email",
  "recruiter id",
  "recruiter name",
  "recruiter email",
  "team leader id",
  "team leader name",
  "team leader phone number",
  "team leader email",
  "management group leader id",
  "management group leader name",
  "management group leader email",
  "senior_management_group_leader_id",
  "senior management group leader",
  "senior management group leader phone number",
  "senior management group leader email",
  "region leader id",
  "region leader name",
  "region leader phone number",
  "region leader email",
  "senior region id",
  "senior region name",
  "senior region leader phone number",
];

export const CANDIDATE_DETAILS = [
  "ORIGINATION DATE",
  "CANDIDATE TYPE",
  "FIRST NAME",
  "LAST NAME",
  "BIRTHDAY",
];

export const SOURCE_DETAILS = [
  "SOURCE CATEGORY",
  "SOURCE NOTE",
  "SOURCE ID",
  "CAMPAIGN ID",
  "RECRUITING LOCATION",
];

export const HOME_DETAILS = [
  "HOME COUNTRY",
  "HOME ADDRESS",
  "HOME CITY",
  "HOME STATE",
  "HOME ZIP CODE",
  "HOME TIMEZONE",
];

export const INTERVIEW = [
  "Scheduled",
  "Rescheduled",
  "Completed",
  "No-show",
  "Cancelled",
];

export const TIMEZONES = [
  "America/Detroit",
  "America/Indiana/Indianapolis",
  "America/Indiana/Marengo",
  "America/Indiana/Petersburg",
  "America/Indiana/Vevay",
  "America/Indiana/Vincennes",
  "America/Indiana/Winamac",
  "America/Kentucky/Louisville",
  "America/Kentucky/Monticello",
  "America/New_York",
  "America/Chicago",
  "America/Indiana/Knox",
  "America/Indiana/Tell_City",
  "America/Menominee",
  "America/North_Dakota/Beulah",
  "America/North_Dakota/Center",
  "America/North_Dakota/New_Salem",
  "America/Boise",
  "America/Denver",
  "America/Phoenix",
  "America/Los_Angeles",
  "America/Anchorage",
  "America/Juneau",
  "America/Metlakatla",
  "America/Nome",
  "America/Sitka",
  "America/Yakutat",
  "America/Adak",
  "Pacific/Honolulu",
];

export const CANDIDATE_STAGES = {
  Bootcamp: "/Bootcamp.png",
  "New Candidate": "/New Candidate.png",
  INTERVIEW: "/Interview.png",
  Onboarding: "Onboarding",
  "Lead Closed": "/Lead Closed.png",
  "Lead Set": "/Lead Set.png",
  Screening: "/Screening.png",
};

export const States = [
  'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA',
  'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD',
  'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ',
  'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC',
  'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'
]

export const SeasonList = ['Summer', 'Year Round', 'Summer & Year Round'];
export const PositionList = ['Smart Home Sales Rep']

export const PackageList = [
  { day: 3 , price: 200, packageName : 'BASIC'},
  { day: 4 , price: 250, packageName : 'STANDARD'},
  { day: 5 , price: 300, packageName : 'PREMIUM'},
]

export const NEW_TIMEZONE = ['Eastern' ,'Central' , 'Mountain' , 'Pacific' , 'Alaska' , 'Hawaii']

export const ALPHABETS = {
  1: "A",
  2: "B",
  3: "C",
  4: "D",
  5: "E",
  6: "F",
  7: "G",
  8: "H",
  9: "I",
  10: "J",
  11: "K",
  12: "L",
  13: "M",
  14: "N",
  15: "O",
  16: "P",
  17: "Q",
  18: "R",
  19: "S",
  20: "T",
  21: "U",
  22: "V",
  23: "W",
  24: "X",
  25: "Y",
  26: "Z"
}