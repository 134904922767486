import { Table } from "common/Table/Table";
import { Tooltip } from "common/Tooltip";
import { exportToCSV } from "utils/funcs";

export const AdStats = ({ data }) => {
  const columns = [
      { key: "first_name", dbColName: "first_name", title: "first name" },
      { key: "last_name", dbColName: "last_name", title: "last name" },
      { key: "ad_amount", dbColName: "ad_amount", title: "ad amount" },
      { key: "notes", dbColName: "notes", title: "notes", 
          render: (notes) => (
                <Tooltip message={notes}>
                  {notes && notes?.toString()?.slice(0, 40)}
                </Tooltip>
              ),
       },
    {
      key: "total_applicants_count",
      dbColName: "total_applicants_count",
      title: "total applicants count",
    },
    {
      key: "total_attendees_count",
      dbColName: "total_attendees_count",
      title: "total attendees count",
    },
    {
      key: "total_rsvp_count",
      dbColName: "total_rsvp_count",
      title: "total rsvp count",
    },
    {
      key: "total_training_count",
      dbColName: "total_training_count",
      title: "total training count",
    },
  ]

  const handleExport = () => {
    exportToCSV(data, "adsstats")
  }

  return <Table columns={columns} data={data} handleExport={handleExport} totalItems={data.length} />;
}
