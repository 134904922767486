import { Table } from "common/Table/Table";
import { useRouter } from "hooks/useRouter";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { userSelector } from "redux/selectors/user";
import UserService from "services/user.service";
import moment from "moment";
import { exportToCSV, formatDate } from "utils/funcs";

const service = new UserService();

export const Meetings = () => {
  const router = useRouter();
  const user = useSelector(userSelector);

  const columns = [
    { key: "title", title: "title", dbColName: "title" },
    { key: "location", title: "location", dbColName: "location" },
    {
      key: "meeting_date",
      title: "meeting date",
      dbColName: "meeting_date",
      render: (date) => formatDate(date),
    },
    { key: "creator_name", title: "creator name", dbColName: "creator_name" },
    {
      key: "no_of_attendees",
      title: "no of attendees",
      dbColName: "no_of_attendees",
    },
    {
      key: "action",
      dbColName: "_id",
      freeze: true,
      freezeIndex: "right-0",
      title: "action",
      render: (id) => {
        return (
          user?.admin === "admin" && (
            <div className="flex items-center gap-4 justify-center">
              <button
                type="button"
                onClick={() => router.push(`/weekly-trainings/${id}`)}
                className="text-blue-500"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="h-6 w-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                </svg>
              </button>
            </div>
          )
        );
      },
    },
  ];

  const calculateDates = () => {
    const today = moment().startOf("day");

    // Start date: Sunday of the current week
    const endDate = today.clone().day(0).startOf("day");

    // End date: Previous Saturday (one week before the start date)
    const startDate = endDate.clone().subtract(7, "day").startOf("day");

    return {
      endDate: endDate.format("YYYY-MM-DD"),
      startDate: startDate.format("YYYY-MM-DD"),
    };
  };

  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [offices, setOffices] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [pagination, setPagination] = useState({
    pageNumber: 1,
    limit: 100,
    startDate: calculateDates().startDate,
    endDate: calculateDates().endDate,
  });

  useEffect(() => {
    setLoading(true);
    service
      .getWeeklyMeetings(pagination)
      .then((resp) => {
        setData(resp.data.data);
        setOffices(resp.data.office_list_with_no_trainings);
        setTotalItems(resp.data.data.length);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [pagination]);

  
  const handleExport = () => {
    exportToCSV(data, "weeklyMeetings")
  }

  return (
    <div>
      <Table
        data={data}
        loadingData={loading}
        pagination={pagination}
        setPagination={setPagination}
        setData={setData}
        totalItems={totalItems}
        columns={columns}
        columnFilters={
          <ColumnFilters
            pagination={pagination}
            setPagination={setPagination}
          />
        }
        handleExport={handleExport}
      />
      <p className="text-zinc-800 mt-3 font-medium">Offices with no meeting this week</p>
      <div className="grid grid-cols-4 gap-2 mt-1">
        {offices?.map((item: any) => (
          <div key={item._id} className="bg-white flex flex-col gap-0.5 rounded-lg p-3 mt-2">
            <p className="text-zinc-800">Office: {item.office_name}</p>
            <p className="text-sm text-zinc-600">Address: {item?.office_address}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

const ColumnFilters = ({ pagination, setPagination }) => {
  return (
    <div className="flex gap-4 mt-2">
      <div className="flex-1">
        <label className="text-xs text-gray-700">Start Date</label>
        <input
          className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
          type="date"
          value={pagination.startDate}
          onChange={(e) =>
            setPagination({
              ...pagination,
              startDate: e.target.value,
            })
          }
        />
      </div>

      <div className="flex-1">
        <label className="text-xs text-gray-700">End Date</label>
        <input
          placeholder="Start date"
          className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
          type="date"
          value={pagination.endDate}
          onChange={(e) => {
            setPagination({
              ...pagination,
              endDate: e.target.value,
            });
          }}
        />
      </div>
    </div>
  );
};
