import classNames from "classnames"
import { Modal } from "common/Modal"
import { useNotifications } from "hooks/useNotification"
import { useState } from "react"
import UserService from "services/user.service"
import { PackageList, PositionList, SeasonList, States } from "utils/constants"

const service = new UserService()

export const AddMediaModal = ({
  showModal,
  selected,
  fetchData,
  setSelected,
  setShowModal,
}) => {
  const [state, setState] = useState({
    notes: "",
    ad_spend: "",
  })
  const [submitting, setSubmitting] = useState(false)
  const [selectedPackage, setSelectedPackage] = useState(0)

  const { successMessage } = useNotifications()

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true);
    service
      .updateMediaAd(selected.id, {
        ad_status: selected.ad_status,
        ad_approval_status: selected.ad_approval_status,
        ad_type: selected.ad_type,
        state: selected.state,
        season: selected.season,
        position: selected.position,
        meet_link: selected.meet_link,
        package_start_date: selected.package_start_date,
        package_end_date: selected.package_end_date,
        city_name: selected.city_name,
        ...state,
      })
      .then(() => {
        fetchData()
        successMessage("Successfully updated")
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        setSubmitting(false)
        setShowModal(false)
      })
  }

  const calculateNewDate = (days, date) => {
      const updatedDate = new Date(date);
      updatedDate.setDate(updatedDate.getDate() + days);
      return updatedDate
  };

  const onChangePackage = (days, packageName) => {
    setSelectedPackage(days)
    setSelected({ 
      ...selected,
      package_start_date: selected.package_start_date,
      package_end_date: calculateNewDate(days, selected.package_start_date),
      package_name: packageName 
    })
  }

  const handleChangeStart = (date) => {
    const currentDays = selected?.package_name === "PREMIUM" ? 5 : selected?.package_name === "BASIC" ? 3 : 4
    setSelected({
      ...selected,
      package_start_date: date,
      package_end_date: calculateNewDate(currentDays, date),
    })
  }

  return (
    <Modal
      width="w-full md:w-1/2"
      showModal={showModal}
      setShowModal={setShowModal}
      title="Update Media Ad"
    >
      <form onSubmit={handleSubmit} className="flex flex-col gap-3">
        <div className="flex gap-3">
          <div className="flex-1 flex flex-col gap-1.5">
            <label className="text-gray-700">Approval Status</label>
            <select
              onChange={(e) =>
                setSelected({
                  ...selected,
                  ad_approval_status: e.target.value,
                })
              }
              value={selected.ad_approval_status}
              className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2  text-gray-800"
            >
              <option value="All">All</option>
              <option value="UNDER REVIEW">UNDER REVIEW</option>
              <option value="DENIED">DENIED</option>
              <option value="APPROVED">APPROVED</option>
            </select>
          </div>
          <div className="flex-1 flex flex-col gap-1.5">
            <label className="text-gray-700">Ad Status</label>
            <select
              onChange={(e) =>
                setSelected({
                  ...selected,
                  ad_status: e.target.value,
                })
              }
              value={selected.ad_status}
              className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2  text-gray-800"
            >
              <option value="All">All</option>
              <option value="In Process">In Process</option>
              <option value="Upcoming">Upcoming</option>
              <option value="Completed">Completed</option>
            </select>
          </div>
        </div>
        <div className="flex-1 flex flex-col gap-1.5">
          <label className="text-gray-700">Ad Spend</label>
          <input
            type="text"
            value={state.ad_spend}
            onChange={(e) => setState({ ...state, ad_spend: e.target.value })}
            placeholder="$0.00"
            className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2  text-gray-800"
          />
        </div>
        <div className="flex-1 flex flex-col gap-1.5">
          <label className="text-gray-700">Meet Link</label>
          <input
            type="text"
            value={selected.meet_link}
            onChange={(e) => setSelected({ ...selected, meet_link: e.target.value })}
            placeholder="Link"
            className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2  text-gray-800"
          />
        </div>
        <div className="flex gap-3">
          <div className="flex-1 flex flex-col gap-1.5">
            <label className="text-gray-700">State</label>
            <select
              onChange={(e) =>
                setSelected({
                  ...selected,
                  state: e.target.value,
                })
              }
              value={selected.state}
              className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2  text-gray-800"
            >
              <option value="">Select State</option>
              {States.map((item) => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>
          <div className="flex-1 flex flex-col gap-1.5">
            <label className="text-gray-700">City Name</label>
            <input
              type="text"
              value={selected.city_name}
              onChange={(e) =>
                setSelected({ ...selected, city_name: e.target.value })
              }
              placeholder="City Name"
              className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2  text-gray-800"
            />
          </div>
        </div>
        <div className="flex-1 flex flex-col gap-1.5">
          <label className="text-gray-700">Ad Type</label>
          <div className="flex gap-3">
            <button className="rounded-md px-4 py-2 border border-black bg-black text-white">
              RECRUITING
            </button>
            <button
              disabled={true}
              className="disabled:cursor-not-allowed rounded-md px-4 py-2 border border-black "
            >
              SALES LEAD
            </button>
          </div>
        </div>
        <div>
          <h1 className="text-lg font-semibold text-zinc-800">Ad Details</h1>
          <div className="flex gap-3">
            <div className="flex-1 flex flex-col gap-1.5">
              <label className="text-gray-700">Season</label>
              <select
                onChange={(e) =>
                  setSelected({
                    ...selected,
                    season: e.target.value,
                  })
                }
                value={selected.season}
                className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2  text-gray-800"
              >
                <option value="">Select Season</option>
                {SeasonList.map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex-1 flex flex-col gap-1.5">
              <label className="text-gray-700">Position</label>
              <select
                onChange={(e) =>
                  setSelected({
                    ...selected,
                    position: e.target.value,
                  })
                }
                value={selected.position}
                className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2  text-gray-800"
              >
                <option value="">Select Position</option>
                {PositionList.map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="flex-1 flex flex-col gap-1.5 mt-2">
            <label className="text-gray-700">Ad Package</label>
            <div className="flex gap-3">
              {PackageList.map((item) => (
                <div key={item.day} onClick={() => onChangePackage(item.day, item.packageName)} className={classNames("flex-1 text-center border border-black cursor-pointer hover:bg-black hover:text-white flex flex-col  px-4 py-3 rounded-md", {
                  "bg-black text-white": item.packageName === selected.package_name,
                })}>
                  <span className="text-lg font-semibold">${item.price}</span>
                  <span className="">
                    {item.day} Days 
                  </span>
                </div>
              ))}
            </div>
          </div>
          <div className="flex gap-3 mt-3">
            <div className="flex-1 flex flex-col gap-1.5">
              <label className="text-gray-700">Start Date</label>
              <input 
              type="date"
              value={selected?.package_start_date && new Date(selected.package_start_date)?.toISOString().split('T')[0]}
              onChange={(e) => handleChangeStart(e.target.value)}
              className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2  text-gray-800"
              />
            </div>
            <div className="flex-1 flex flex-col gap-1.5">
              <label className="text-gray-700">End Date</label>
              <input 
              type="date"
              disabled={true}
              value={selected?.package_end_date && new Date(selected.package_end_date)?.toISOString().split('T')[0]}
              onChange={(e) => setSelected({ ...selected, package_end_date: e.target.value })}
              className="rounded-md disabled:bg-gray-100 focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2  text-gray-800"
              />
            </div>
          </div>
        </div>
        <div className="flex-1 flex flex-col gap-1.5">
          <label className="text-gray-700">Admin Notes</label>
          <textarea
            value={state.notes}
            onChange={(e) => setState({ ...state, notes: e.target.value })}
            rows={4}
            className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2  text-gray-800"
          />
        </div>

        <button type="submit" className="bg-black py-2 rounded-md text-white">
          {submitting ? "Updating..." : "Update"}
        </button>
      </form>
    </Modal>
  )
}
