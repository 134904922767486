import React, { FC, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

type Props = {
  children: React.ReactElement | React.ReactNode;
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  cancelButtonRef?: React.RefObject<HTMLButtonElement>;
  setIsEditing?: (value: boolean) => void;
  title: string;
  onClick?: any;
  width?: string;
  padding?: string;
};

export const Modal: FC<Props> = ({
  children,
  showModal,
  setShowModal,
  onClick,
  cancelButtonRef,
  setIsEditing = () => {},
  title,
  width,
  padding,
}) => {
  return (
    <Transition.Root show={showModal} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={showModal}
        onClose={setShowModal}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={`${
                width ? width : "w-full md:w-2/4"
              } inline-block align-bottom bg-dark-black rounded bg-white text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle`}
            >
              <div className="max-w-none mx-auto">
                <div>
                  <div
                    className="absolute right-7 top-5 cursor-pointer"
                    onClick={() => {
                      onClick && onClick();
                      setShowModal(false);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 text-gray-800"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </div>
                  <h3 className="w-full pt-5 pb-4 shadow-inherit border-b font-bold leading-6 text-lg px-8 text-gray-700 ">
                    {title}
                  </h3>
                  <div
                    className={`bg-dark-black overflow-hidden sm:rounded-lg sm:shadow  ${
                      padding ? padding : "sm:px-6 px-16 py-4"
                    }`}
                  >
                    {children}
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
