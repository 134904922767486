import classNames from "classnames";
import { inputStyles } from "common/form/InputField";
import { Loader } from "common/Loader";
import { Table } from "common/Table/Table";
import { useNotifications } from "hooks/useNotification";
import { useRouter } from "hooks/useRouter";
import { Fragment, useEffect, useState } from "react";
import UserService from "services/user.service";
import swal from "sweetalert";
import { exportToCSV, objectToQueryString } from "utils/funcs";
import { tabStyles } from "./GeneralData";
import { BlackSparrowLocation } from "components/blackSparrow/BlackSparrowLocation";

const service = new UserService();

const getRandomColor = () => {
  const colors = [
    "border-t-blue-500 bg-blue-50",
    "border-t-purple-500 bg-purple-50",
    "border-t-green-500 bg-green-50",
    "border-t-rose-500 bg-rose-50",
    "border-t-amber-500 bg-amber-50",
    "border-t-teal-500 bg-teal-50",
    "border-t-indigo-500 bg-indigo-50",
    "border-t-pink-500 bg-pink-50",
  ];
  return colors[Math.floor(Math.random() * colors.length)];
};

export const TAGS = [
  "YESTERDAY",
  "TODAY",
  "WEEKTODATE",
  "LASTWEEK",
  "LASTMONTH",
  "MONTHTODATE",
  "YEARTODATE",
  "CUSTOM",
];

export const displayNames = {
  candidates: "Total Candidates",
  screening_approved: "Screening Approved",
  screening_declined: "Screening Declined",
  screening_completed: "Screening Completed",
  group_infosession: "Group Infosession Scheduled",
  group_infosession_raffle: "Group Infosession Attended",
  attende_offer: "Attendee Offer",
  attende_hire: "Attendee Hire",
  contract_sent: "Contract Sent",
  contract_signed: "Agreement Signed",
  last_street_genie_login: "Street Genie Login",
  completed_training: "Completed Training",
  last_credit_run_date: "Ran Credit",
  last_install_date: "Sales Install",
  onboarded_date: "Onboarded",
  upgrades: "Upgrades",
  register_for_blitz: "Registered for Blitz",
  attended_blitz: "Attended Blitz",
  goals_set: "Goals Set",
  signedReps: "Signed Reps",
};

export const BlackSparrow = () => {
  const { push, query } = useRouter();

  // {
  //   candidates: "Total Candidates",
  //   screening_approved: "Screening Completed",
  //   screening_declined: "Screening Approved",
  //   group_infosession: "Group Infosession Scheduled",
  //   group_infosession_raffle: "Group Infosession Attended",
  //   attende_offer: "Attendee Offer",
  //   attende_hire: "Attendee Hire",
  //   contract_sent: "Contract Sent",
  //   contract_signed: "Agreement Signed",
  //   last_street_genie_login: "Street Genie Login",
  //   completed_training: "Completed Training",
  //   last_credit_run_date: "Ran Credit",
  //   last_install_date: "Sales Install",
  //   onboarded_date: "Onboarded",
  //   upgrades: "Upgrades",
  //   register_for_blitz: "Registered for Blitz",
  //   attended_blitz: "Attended Blitz",
  //   goals_set: "Goals Set"
  // };

  const columns = [
    { key: "manager_id", dbColName: "manager_id", title: "manager id", 
       freeze: true,
      freezeIndex: "left-0"
     },
    { key: "manager_name", dbColName: "manager_name", title: "manager name",
      freeze: true,
      freezeIndex: "left-24"
     },
    // {key: "candidates",dbColName: "candidates", title: "candidates"},
    // {key: "screening_completed",dbColName: "screening_completed", title: "screening_completed"},
    // {key: "screening_approved",dbColName: "screening_approved", title: "screening_approved"},
    {
      key: "group_infosession",
      dbColName: "group_infosession",
      title: "Group Infosession Scheduled",
      render: row => <p className="text-center">{row}</p>
    },
    {
      key: "group_infosession_raffle",
      dbColName: "group_infosession_raffle",
      title: "Group Infosession Attended",
      render: row => <p className="text-center">{row}</p>
    },
    {
      key: "attende_offer",
      dbColName: "attende_offer",
      title: "Attendee Offer",
      render: row => <p className="text-center">{row}</p>
    },
    { key: "attende_hire", dbColName: "attende_hire", title: "attendee hire",
      render: row => <p className="text-center">{row}</p>
     },
    { key: "signedReps", dbColName: "signedReps", title: "signedReps",
      render: row => <p className="text-center">{row}</p>
     },
    {
      key: "contract_sent",
      dbColName: "contract_sent",
      title: "contract sent",
      render: row => <p className="text-center">{row}</p>
    },
    {
      key: "contract_signed",
      dbColName: "contract_signed",
      title: "contract signed",
      render: row => <p className="text-center">{row}</p>
    },
    {
      key: "last_street_genie_login",
      dbColName: "last_street_genie_login",
      title: "street Genie Login",
      render: row => <p className="text-center">{row}</p>
    },
    {
      key: "completed_training",
      dbColName: "completed_training",
      title: "completed training",
      render: row => <p className="text-center">{row}</p>
    },
    {
      key: "last_credit_run_date",
      dbColName: "last_credit_run_date",
      title: "ran credit",
      render: row => <p className="text-center">{row}</p>
    },
    {
      key: "last_install_date",
      dbColName: "last_install_date",
      title: "sales install",
      render: row => <p className="text-center">{row}</p>
    },
    { key: "onboarded_date", dbColName: "onboarded_date", title: "onboarded",
      render: row => <p className="text-center">{row}</p>
     },
    { key: "upgrades", dbColName: "upgrades", title: "upgrades" },
    {
      key: "register_for_blitz",
      dbColName: "register_for_blitz",
      title: "registered for blitz",
      render: row => <p className="text-center">{row}</p>
    },
    {
      key: "attended_blitz",
      dbColName: "attended_blitz",
      title: "attended blitz",
      render: row => <p className="text-center">{row}</p>
    },
    { key: "goals_set", dbColName: "goals_set", title: "goals set",
      render: row => <p className="text-center">{row}</p>
     },
    // {
    //   key: "action",
    //   freeze: true,
    //   freezeIndex: "right-0",
    //   title: "action",
    //   render: (row) => {
    //     return (
    //       <button
    //         type="button"
    //         onClick={() =>
    //           push(
    //             `/sparrow-report/${row.manager_id}?manager=${row.manager_name}`
    //           )
    //         }
    //         className="text-blue-500"
    //       >
    //         <svg
    //           xmlns="http://www.w3.org/2000/svg"
    //           fill="none"
    //           viewBox="0 0 24 24"
    //           strokeWidth={1.5}
    //           stroke="currentColor"
    //           className="h-6 w-6"
    //         >
    //           <path
    //             strokeLinecap="round"
    //             strokeLinejoin="round"
    //             d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
    //           />
    //           <path
    //             strokeLinecap="round"
    //             strokeLinejoin="round"
    //             d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
    //           />
    //         </svg>
    //       </button>
    //     );
    //   },
    // },
  ];

  const [categories, setCategories] = useState<any>([]);
  const [statesFilter, setStatesFilter] = useState({
    flag: "YES",
    date: "LASTWEEK",
    type: "info",
  });
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});


  useEffect(() => {
    setLoading(true);
    service
      .getBlackSparrow(statesFilter)
      .then((resp) => {
        setCategories(resp.data.total_docs);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [statesFilter]);

  const [filter, setFilter] = useState({
    type: "info",
    date: "LASTWEEK",
    flag: "YES",
  });
  const [loadingStats, setLoadingStats] = useState(false)

  useEffect(() => {
    setLoadingStats(true);
    service
      .getBlackSparrowStats(filter)
      .then((stats) => {
        delete stats.data.total_docs.onboarded_date;
        // delete stats.data.total_docs.last_install_date
        setData(stats.data.total_docs);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoadingStats(false);
      });
  }, [filter]);

 

  const filteredData = Object.entries(data).filter(
    ([key]) => key !== "signedReps"
  );

  const handleExport = () => {
    exportToCSV(categories, "categories");
  };

  return (
    <Fragment>
      <div className="flex justify-between items-center mb-4">
        <div className="flex  gap-2">
          <button
            type="button"
            onClick={() => push("/weekly-trainings")}
            className="bg-white rounded-md p-2 border"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="h-6 w-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
              />
            </svg>
          </button>
          <div>
            <h1 className="font-medium text-gray-700 tracking-wide text-xl">
              Black Sparrow
            </h1>
            <p className="text-gray-500 text-sm">
              View all back sparrow report
            </p>
          </div>
        </div>
        {query.tab === "Reports" && (
          <div className=" flex items-center gap-3">
            <select
              className={inputStyles.input}
              value={filter.date}
              onChange={(e) => setFilter({ ...filter, date: e.target.value })}
            >
              {TAGS.map((tag) => (
                <option key={tag} value={tag}>
                  {tag}
                </option>
              ))}
            </select>
            <select
              className={inputStyles.input}
              value={filter.type}
              onChange={(e) => setFilter({ ...filter, type: e.target.value })}
            >
              <option value="info">Group Interview</option>
              <option value="Black">All</option>
            </select>
            <select
              className={inputStyles.input}
              value={filter.flag}
              onChange={(e) => setFilter({ ...filter, flag: e.target.value })}
            >
              {/* <option value="">Select</option> */}
              <option value="NO">Master Date</option>
              <option value="YES">Cohort Date</option>
              {/* <option value="NO">No</option> */}
            </select>
          </div>
        )}
      </div>

      <div className="text-sm mb-4 p-2 text-center text-gray-500 rounded-md bg-gray-200">
        <div className="flex flex-wrap -mb-px gap-3">
          <button
            type="button"
            onClick={() => push("/black-sparrow?tab=Reports")}
            className={classNames(tabStyles.default, {
              [tabStyles.active]: query.tab === "Reports",
            })}
          >
            Reports
          </button>
          <button
            type="button"
            onClick={() => push("/black-sparrow")}
            className={classNames(tabStyles.default, {
              [tabStyles.active]: !query.tab,
            })}
          >
            Reporting By Manager
          </button>
          <button
            type="button"
            onClick={() => push("/black-sparrow?tab=Location")}
            className={classNames(tabStyles.default, {
              [tabStyles.active]: query.tab === "Location",
            })}
          >
            Reporting By Location
          </button>
        </div>
      </div>

      <div className={` ${query.tab === "Reports" ? "block" : "hidden"} `}>
        {loadingStats ? (
          <Loader />
        ) : (
          <div className="w-full">
            <div className="grid grid-cols-4 gap-6 mb-5">
              {filteredData.map(([key, value]) => (
                <div
                  key={key}
                  className="cursor-pointer"
                  onClick={() =>
                   {
                    if(displayNames[key] === "Total Candidates" || displayNames[key] === "Screening Approved" || displayNames[key] === "Screening Completed") {
                      return 
                    } else {
                      push(`/sparrow-users?${objectToQueryString({...filter, tab: key })}`)
                    }
                   }
                  }
                >
                  <MetricCard
                    label={key}
                    displayName={displayNames[key] || key}
                    value={value}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      <div className={` ${!query.tab ? "block" : "hidden"} `}>
        <Table
          data={categories}
          loadingData={loading}
          handleExport={handleExport}
          totalItems={categories.length}
          filters={
            <div className="flex gap-2">
              <select
                className="rounded-md focus:border-gray-300 font-light focus:outline-none w-60 focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
                value={statesFilter.date}
                onChange={(e) =>
                  setStatesFilter({ ...statesFilter, date: e.target.value })
                }
              >
                {TAGS.map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </select>
              {/* <select
                className="rounded-md focus:border-gray-300 font-light focus:outline-none w-60 focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
                value={statesFilter.type}
                onChange={(e) =>
                  setStatesFilter({ ...statesFilter, type: e.target.value })
                }
              >
                <option value="info">Group Interview</option>
                <option value="Black">All</option>
              </select> */}
              <select
                className="rounded-md focus:border-gray-300 font-light focus:outline-none w-60 focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
                value={statesFilter.flag}
                onChange={(e) =>
                  setStatesFilter({ ...statesFilter, flag: e.target.value })
                }
              >
                {/* <option value="">Select</option> */}
                <option value="NO">Master Date</option>
                <option value="YES">Cohort Date</option>
                {/* <option value="NO">No</option> */}
              </select>
            </div>
          }
          columns={columns}
        />
      </div>
      <div className={` ${query.tab === "Location" ? "block" : "hidden"} `}>
        <BlackSparrowLocation />
      </div>
    </Fragment>
  );
};

export const MetricCard = ({ label, className, displayName, value }: any) => {
  return (
    <Card
      color={getRandomColor()}
      className="p-4 flex flex-col items-center justify-center text-center hover:shadow-md transition-all duration-300 group"
    >
      <div className="text-3xl font-bold mb-2 text-gray-800">{value}</div>
      <div className={classNames("text-sm text-gray-600", [className])}>
        {displayName}
      </div>
      <div className="absolute bottom-0 left-0 w-full h-px bg-current opacity-10"></div>
      <div className="absolute bottom-0 right-4 w-8 h-1 rounded-t-lg bg-current opacity-30"></div>
    </Card>
  );
};

export const Card = ({ className, children, color }) => {
  return (
    <div
      className={`relative rounded-lg border border-gray-200 shadow-sm ${color} ${className}`}
    >
      <div className="absolute top-0 left-0 w-full h-1 rounded-t-lg bg-current opacity-20"></div>
      <div className="absolute top-0 left-4 w-8 h-1 rounded-b-lg bg-current"></div>
      {children}
    </div>
  );
};
