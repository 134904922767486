import { Table } from "common/Table/Table";
import { Filters } from "pages/Opportunities";
import { Fragment, useEffect, useState } from "react";
import UserService from "services/user.service";
import { exportToCSV } from "utils/funcs";

const service = new UserService();

export const Sales = () => {
  const columns = [
    {
      key: "office_name",
      title: "office_name",
      dbColName: "office_name",
      freeze: true,
      freezeIndex: "left-0",
    },
    {
      key: "office_type",
      title: "office_type",
      dbColName: "office_type",
      freeze: true,
      freezeIndex: "left-40",
    },
    {
      key: "management_group_leader_name",
      title: "management_group_leader_name",
      dbColName: "management_group_leader_name",
    },
    {
      key: "management_group_leader_id",
      title: "management_group_leader_id",
      dbColName: "management_group_leader_id",
    },
    {
      key: "management_group_leader_phone",
      title: "management_group_leader_phone",
      dbColName: "management_group_leader_phone",
    },
    {
      key: "senior_management_group_leader_name",
      title: "senior_management_group_leader_name",
      dbColName: "senior_management_group_leader_name",
    },
    {
      key: "senior_management_group_leader_id",
      title: "senior_management_group_leader_id",
      dbColName: "senior_management_group_leader_id",
    },
    {
      key: "senior_management_group_leader_phone",
      title: "senior_management_group_leader_phone",
      dbColName: "senior_management_group_leader_phone",
    },
    {
      key: "region_leader_name",
      title: "region_leader_name",
      dbColName: "region_leader_name",
    },
    {
      key: "region_leader_id",
      title: "region_leader_id",
      dbColName: "region_leader_id",
    },
    {
      key: "region_leader_phone",
      title: "region_leader_phone",
      dbColName: "region_leader_phone",
    },
    {
      key: "senior_region_leader_name",
      title: "senior_region_leader_name",
      dbColName: "senior_region_leader_name",
    },
    {
      key: "senior_region_leader_id",
      title: "senior_region_leader_id",
      dbColName: "senior_region_leader_id",
    },
    {
      key: "senior_region_leader_phone",
      title: "senior_region_leader_phone",
      dbColName: "senior_region_leader_phone",
    },
    {
      key: "partnership_leader_name",
      title: "partnership_leader_name",
      dbColName: "partnership_leader_name",
    },
    {
      key: "partnership_leader_id",
      title: "partnership_leader_id",
      dbColName: "partnership_leader_id",
    },
    {
      key: "partnership_leader_phone",
      title: "partnership_leader_phone",
      dbColName: "partnership_leader_phone",
    },
    {
      key: "division_name",
      title: "division_name",
      dbColName: "division_name",
    },
  ];

  const [data, setData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    year: 2023,
  });

  useEffect(() => {
    setLoading(true);
    service
      .getSaleOffices(pagination)
      .then((resp) => {
        setTotalItems(+resp.data.total_docs);
        setData(resp.data.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [pagination]);

  const handleExport = () => {
    exportToCSV(data, "sales");
  };

  return (
    <Fragment>
      <div className="flex flex-col mb-4">
        <h1 className="font-semibold text-gray-700 tracking-wide text-xl">
          Sales
        </h1>
        <p className="text-gray-500 text-sm">View all offices </p>
      </div>
      <Table
        data={data}
        pagination={pagination}
        handleExport={handleExport}
        setPagination={setPagination}
        loadingData={loading}
        filters={
          <Filters pagination={pagination} setPagination={setPagination} />
        }
        totalItems={totalItems}
        columns={columns}
      />
    </Fragment>
  );
};
