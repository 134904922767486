import classNames from "classnames";
import { Table } from "common/Table/Table";
import { Fragment, useEffect, useState } from "react";
import UserService from "services/user.service";
import { tabStyles } from "./GeneralData";
import { Attended } from "components/InfoSessions/Attended";
import { getLastWeekDateRange } from "utils/funcs";

const service = new UserService();

export const InfoSessions = () => {
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);

  const columns = [
    { key: "first_name", dbColName: "first_name", title: "first name", freeze: true, freezeIndex: 'left-0' },
    { key: "last_name", dbColName: "last_name", title: "last name", freeze: true, freezeIndex: 'left-20' },
    { key: "phone", dbColName: "phone", title: "phone" },
    { key: "email", dbColName: "email", title: "email" },
    {
      key: "recruiter_badge_id",
      dbColName: "recruiter_badge_id",
      title: "recruiter badge id",
    },
    { key: "cell_phone", dbColName: "phone", title: "cell phone" },
    // {
    //   key: "contact_first_name",
    //   dbColName: "contact_first_name",
    //   title: "contact first name",
    // },
    // {
    //   key: "contact_last_name",
    //   dbColName: "contact_last_name",
    //   title: "contact last name",
    // },
    // {
    //   key: "contact_full_name",
    //   dbColName: "contact_full_name",
    //   title: "contact full name",
    // },
    // {
    //   key: "contact_email",
    //   dbColName: "contact_email",
    //   title: "contact email",
    // },
    // {
    //   key: "web_occurance",
    //   dbColName: "web_occurance",
    //   title: "web occurance",
    //   render: (date) => new Date(date).toLocaleDateString(),

    // },
    // { key: "date_as_text", dbColName: "date_as_text", title: "date as text" },
    // {
    //   key: "createdAt",
    //   dbColName: "createdAt",
    //   title: "created At",
    //   render: (date) => new Date(date).toLocaleDateString(),
    // },
  ];

  const [tab, setTab] = useState("Registered");
  const [pagination, setPagination] = useState({
    pageNumber: 1,
    limit: 100,
    startDate: getLastWeekDateRange().start,
    endDate: getLastWeekDateRange().end,
  });

  useEffect(() => {
    setLoading(true);
    service
      .getInfoSessionsReg(pagination)
      .then((resp) => {
        setData(resp.data.registered);
        setTotalItems(resp.data.totalDocument);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [pagination]);

  return (
    <Fragment>
      <div className="text-sm mb-4 p-2 text-center text-gray-500 rounded-md bg-gray-200">
        <div className="flex flex-wrap -mb-px gap-3">
          <button
            type="button"
            onClick={() => setTab("Registered")}
            className={classNames(tabStyles.default, {
              [tabStyles.active]: tab === "Registered",
            })}
          >
            Registered
          </button>
          <button
            type="button"
            onClick={() => setTab("Attended")}
            className={classNames(tabStyles.default, {
              [tabStyles.active]: tab === "Attended",
            })}
          >
            Attended
          </button>
        </div>
      </div>
      <div className="flex flex-col mb-4">
        <h1 className="font-medium text-gray-700 tracking-wide text-xl">
          Info Sessions {tab}
        </h1>
        <p className="text-gray-500 text-sm">View all {tab}</p>
      </div>
      {tab === "Registered" ? (
        <Table
          data={data}
          loadingData={loading}
          pagination={pagination}
          setPagination={setPagination}
          setData={setData}
          columnFilters={
            <ColumnFilters
              pagination={pagination}
              setPagination={setPagination}
            />
          }
          totalItems={totalItems}
          columns={columns}
          exportButton={false}
        />
      ) : (
        <Attended />
      )}
    </Fragment>
  );
};

const ColumnFilters = ({ pagination, setPagination }) => {
  return (
    <div className="flex gap-4 mt-2">
      <div className="flex-1">
        <label className="text-xs text-gray-700">Start Date</label>
        <input
          className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
          type="date"
          value={pagination.startDate}
          onChange={(e) =>
            setPagination({
              ...pagination,
              startDate: e.target.value,
            })
          }
        />
      </div>

      <div className="flex-1">
        <label className="text-xs text-gray-700">End Date</label>
        <input
          placeholder="Start date"
          className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
          type="date"
          value={pagination.endDate}
          onChange={(e) => {
            setPagination({
              ...pagination,
              endDate: e.target.value,
            });
          }}
        />
      </div>
    </div>
  );
};
