import { Table } from "common/Table/Table";
import { Fragment, useEffect, useMemo, useState } from "react";
import UserService from "services/user.service";
import { InfoSessions } from "components/contacts/InfoSessions";
import { Scholarships } from "components/contacts/Scholarships";
import { useDebounce } from "hooks/useDebounce";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { exportToCSV, formatDateY } from "utils/funcs";
import Select from "react-select";
import { useNotifications } from "hooks/useNotification";
import classNames from "classnames";
import {
  CONTACT,
  LEADER,
  MANAGEMENT,
  NAMES,
  SESSIONS,
  UPGRADE,
} from "utils/constants";
import { Modal } from "common/Modal";

const service = new UserService();

export const formatPhone = (phone) => {
  if (phone && String(phone).length > 7) {
    return `(${phone?.slice(0, 3)}) ${phone?.slice(3, 6)}-${phone?.slice(
      6,
      10
    )}`;
  } else {
    return phone;
  }
};

const Stage = ({ stage, firstName, lastName }) => {
  const getColor = (s) => {
    if (s === "Contact") {
      return "border-[#EE8332]";
    } else if (s === "Contact+") {
      return "border-[#EE8332]";
    } else if (s === "SH Pro") {
      return "border-[#7715F5]";
    } else if (s === "SH Pro+") {
      return "border-[#7715F5]";
    } else if (s === "Recruiter") {
      return "border-[#57C1F9]";
    } else if (s === "Recruiter+") {
      return "border-[#57C1F9]";
    } else if (s === "Signed") {
      return "border-[#02F900]";
    } else if (s === "Signed+") {
      return "border-[#02F900]";
    } else {
      return "border-[#EE8332]";
    }
  };

  return (
    <div
      className={`border-2 ${getColor(
        stage
      )} rounded-full w-12 h-12 text-sm flex items-center justify-center flex-shrink-0`}
    >
      {firstName?.charAt(0) + lastName?.charAt(0)}
    </div>
  );
};

export const Contacts = () => {
  const [showColumns, setShowColumns] = useState({
    names: false,
    contacts: false,
    upgrade: false,
    sessions: false,
    management: false,
    leader: false,
  });

  const columns = [
    {
      key: "badge_id",
      dbColName: "badge_id",
      title: "badge id",
      freeze: true,
      isSort: true,
      color: "bg-[#BDBDBD] text-white",
      freezeIndex: "left-0",
    },
    {
      key: "contact_full_name",
      title: "full name",
      color: "bg-[#BDBDBD] text-white",
      render: (d) =>
        d?.full_name ? d.full_name : `${d?.first_name} ${d?.last_name}`,
      header: () => (
        <button
          type="button"
          onClick={() =>
            setShowColumns((prev) => ({ ...prev, names: !prev.names }))
          }
        >
          {showColumns.names ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
              />
            </svg>
          )}
        </button>
      ),
    },
    {
      key: "first_name",
      dbColName: "first_name",
      title: "first name",
      isSort: true,
      show: false,
      freeze: true,
      color: "bg-[#BDBDBD] text-white",
      freezeIndex: "left-20",
    },
    {
      key: "last_name",
      dbColName: "last_name",
      title: "last name",
      freeze: true,
      isSort: true,
      show: false,
      color: "bg-[#BDBDBD] text-white",
      freezeIndex: "left-44",
    },
    {
      key: "phone",
      dbColName: "phone",
      isSort: true,
      show: false,
      title: "phone",
      color: "bg-[#BDBDBD] text-white",
      render: (phone: string) => formatPhone(phone),
    },
    {
      key: "email",
      dbColName: "email",
      title: "email",
      show: false,
      isSort: true,
      color: "bg-[#BDBDBD] text-white",
    },
    {
      key: "stage",
      title: "stage",
      isSort: true,
      color: "bg-[#BDBDBD] text-white",
      render: (d) => (
        <Stage
          stage={d.stage}
          firstName={d.first_name}
          lastName={d.last_name}
        />
      ),
    },
    {
      key: "source",
      dbColName: "source",
      isSort: true,
      title: "source",
      color: "bg-[#BDBDBD] text-white",
    },
    {
      key: "contact_leader_id",
      dbColName: "contact_leader_id",
      title: "contact leader id",
      isSort: true,
      color: "bg-[#BDBDBD] text-white",
    },
    {
      key: "contact_leader_name",
      dbColName: "contact_leader_name",
      isSort: true,
      title: "contact leader name",
      color: "bg-[#BDBDBD] text-white",
    },
    {
      key: "location",
      dbColName: "location",
      isSort: true,
      title: "location",
      color: "bg-[#BDBDBD] text-white",
    },
    {
      key: "d2d_exp",
      dbColName: "d2d_exp",
      title: "d2d exp",
      isSort: true,
      color: "bg-[#BDBDBD] text-white",
    },
    {
      key: "other",
      dbColName: "other",
      title: "other",
      color: "bg-[#BDBDBD] text-white",
    },
    {
      key: "notes",
      dbColName: "notes",
      isSort: true,
      title: "notes",
      color: "bg-[#BDBDBD] text-white",
    },
    {
      key: "date_submitted",
      isSort: true,
      dbColName: "date_submitted",
      title: "date submitted",
      color: "bg-[#BDBDBD] text-white",
      render: (date) => new Date(date).toLocaleDateString(),
    },
    {
      key: "cell_phone",
      dbColName: "cell_phone",
      title: "cell phone",
      isSort: true,
      color: "bg-[#BDBDBD] text-white",
      render: (phone: string) => formatPhone(phone),
    },
    {
      key: "max_position",
      dbColName: "max_position",
      isSort: true,
      title: "max position",
      color: "bg-[#BDBDBD] text-white",
    },
    {
      key: "tenure",
      dbColName: "tenure",
      isSort: true,
      title: "tenure",
      color: "bg-[#BDBDBD] text-white",
    },
    {
      key: "contact_created",
      dbColName: "contact_created",
      title: "contact created",
      color: "bg-[#BDBDBD] text-white",
      render: (date) => new Date(date).toLocaleDateString(),
      header: () => (
        <button
          type="button"
          onClick={() =>
            setShowColumns((prev) => ({ ...prev, contacts: !prev.contacts }))
          }
        >
          {showColumns.contacts ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
              />
            </svg>
          )}
        </button>
      ),
    },
    {
      key: "first_contact_created_date",
      dbColName: "first_contact_created_date",
      isSort: true,
      title: "first contact created date",
      color: "bg-[#4DD0E0] text-white",
    },
    {
      key: "first_contact_created",
      dbColName: "first_contact_date",
      isSort: true,
      title: "first contact+ date",
      color: "bg-[#4DD0E0] text-white",
    },
    {
      key: "first_signed+_date",
      dbColName: "first_signed_date",
      title: "first signed date",
      isSort: true,
      color: "bg-[#4DD0E0] text-white",
    },
    {
      key: "first_signed_date",
      dbColName: "first_signed_date",
      title: "first signed+ date",
      isSort: true,
      color: "bg-[#4DD0E0] text-white",
    },
    {
      key: "first_recruiter_date",
      dbColName: "first_recruiter_date",
      isSort: true,
      title: "first recruiter date",
      color: "bg-[#4DD0E0] text-white",
    },
    {
      key: "first_recruiter",
      isSort: true,
      dbColName: "first_recruiter",
      title: "first recruiter+",
      color: "bg-[#4DD0E0] text-white",
    },
    {
      key: "first_sh_pro+_date",
      dbColName: "first_sh_pro_date",
      title: "first sh pro date",
      color: "bg-[#4DD0E0] text-white",
    },
    {
      key: "first_sh_pro_date",
      dbColName: "first_sh_pro_date",
      isSort: true,
      title: "first sh pro+ date",
      color: "bg-[#4DD0E0] text-white",
    },
    {
      key: "first_recruiter_date",
      dbColName: "first_sh_pro_date",
      title: "first sh pro+ date",
      color: "bg-[#4DD0E0] text-white",
    },
    {
      key: "last_achievement_date",
      dbColName: "last_achievement_date",
      isSort: true,
      title: "last achievement date",
      color: "bg-[#4DD0E0] text-white",
    },
    {
      key: "last_achievement",
      dbColName: "last_achievement",
      title: "last achievement",
      isSort: true,
      color: "bg-[#4DD0E0] text-white",
    },
    {
      key: "days_since_last_achievement",
      dbColName: "date_since_last_achievement",
      isSort: true,
      title: "days since last achievement",
      color: "bg-[#4DD0E0] text-white",
      header: () => (
        <button
          type="button"
          onClick={() =>
            setShowColumns((prev) => ({ ...prev, sessions: !prev.sessions }))
          }
        >
          {showColumns.sessions ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
              />
            </svg>
          )}
        </button>
      ),
    },

    {
      key: "online_info_session_registered",
      dbColName: "online_info_session_registered",
      isSort: true,
      color: "bg-[#78909C] text-white",
      show: false,
      title: "online info session (registered)",
    },
    {
      key: "online_info_session_attended",
      dbColName: "online_info_session_attended",
      show: false,
      color: "bg-[#78909C] text-white",
      title: "online info session (attended)",
      isSort: true,
    },
    {
      key: "weekly_training_rsvp",
      dbColName: "weekly_training_rsvp",
      show: false,
      color: "bg-[#78909C] text-white",
      title: "weekly training (rsvp)",
      isSort: true,
    },
    {
      key: "weekly_training_attended",
      dbColName: "weekly_training_attended",
      show: false,
      color: "bg-[#78909C] text-white",
      title: "weekly training (attended)",
    },
    {
      key: "application_submitted",
      dbColName: "application_submitted",
      show: false,
      isSort: true,
      color: "bg-[#78909C] text-white",
      title: "application submitted",
    },

    {
      key: "agreement_sent",
      dbColName: "agreement_sent",
      title: "agreement sent",
      show: false,
      isSort: true,
      color: "bg-[#78909C] text-white",
    },
    {
      key: "agreement_signed",
      dbColName: "agreement_signed",
      show: false,
      title: "agreement signed",
      isSort: true,
      color: "bg-[#78909C] text-white",
    },
    {
      key: "onboard_date",
      dbColName: "onboard_date",
      title: "onboard date",
      show: false,
      isSort: true,
      color: "bg-[#78909C] text-white",
    },
    {
      key: "last_street_genie_login",
      dbColName: "last_street_genie_login",
      title: "last street genie login",
      color: "bg-[#78909C] text-white",
      show: false,
    },
    {
      key: "completed_onboard_training",
      dbColName: "completed_onboard_training",
      isSort: true,
      show: false,
      title: "completed onboard training",
      color: "bg-[#78909C] text-white",
    },
    {
      key: "manager_interview",
      dbColName: "manager_interview",
      isSort: true,
      show: false,
      title: "manager interview",
      color: "bg-[#78909C] text-white",
    },

    {
      key: "preseason_blitz_registered",
      isSort: true,
      dbColName: "preseason_blitz_registered",
      show: false,
      title: "preseason blitz registered",
      color: "bg-[#78909C] text-white",
    },
    {
      key: "preseason_blitz_attended",
      dbColName: "preseason_blitz_attended",
      isSort: true,
      show: false,
      title: "preseason blitz attended",
      color: "bg-[#78909C] text-white",
    },
    {
      key: "list_started_100",
      isSort: true,
      dbColName: "list_started_100",
      color: "bg-[#78909C] text-white",
      show: false,
      title: "list started 100",
    },
    {
      key: "list_completed_100",
      dbColName: "list_completed_100",
      color: "bg-[#78909C] text-white",

      title: "list completed 100",
      header: () => (
        <button
          type="button"
          onClick={() =>
            setShowColumns((prev) => ({ ...prev, upgrade: !prev.upgrade }))
          }
        >
          {showColumns.upgrade ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
              />
            </svg>
          )}
        </button>
      ),
    },

    {
      key: "first_upgrade_date",
      isSort: true,
      dbColName: "first_upgrade_date",
      title: "first upgrade date",
      show: false,
      color: "bg-[#24a69a] text-white",
    },
    {
      key: "first_install_date",
      dbColName: "first_install_date",
      color: "bg-[#24A69A] text-white",
      show: false,
      title: "first install date",
    },
    {
      key: "last_install_date",
      dbColName: "last_install_date",
      color: "bg-[#24A69A] text-white",
      show: false,
      title: "last install date",
    },
    {
      key: "last_sh_date",
      dbColName: "last_sh_date",
      color: "bg-[#24A69A] text-white",
      show: false,
      title: "last sh install date",
    },
    {
      key: "last_solar_lead_date",
      dbColName: "last_solar_lead_date",
      color: "bg-[#24A69A] text-white",
      title: "last solar lead date",
    },
    {
      key: "last_solar_closed_date",
      dbColName: "last_solar_closed_date",
      color: "bg-[#24A69A] text-white",
      show: false,
      title: "last solar closed date",
    },
    {
      key: "last_solar_permit_date",
      dbColName: "last_solar_permit_date",
      color: "bg-[#24A69A] text-white",
      show: false,
      title: "last solar permit date",
    },
    {
      key: "last_solar_install_date",
      dbColName: "last_solar_install_date",
      color: "bg-[#24A69A] text-white",
      show: false,
      title: "last solar install date",
    },
    {
      key: "last_recruit_with_sale_date",
      dbColName: "last_recruit_with_sale_date",
      color: "bg-[#24A69A] text-white",
      show: false,
      title: "last recruit with sale date",
    },
    {
      key: "rafp",
      title: "Rafp+",
      dbColName: "rafp",
      color: "bg-[#5B95F9] text-white",
    },
    {
      key: "fp",
      dbColName: "fp",
      color: "bg-[#5B95F9] text-white",
      title: "fp",
    },
    {
      key: "opps",
      dbColName: "opps",
      color: "bg-[#5B95F9] text-white",
      title: "opps",
    },
    {
      key: "cs",
      dbColName: "cs",
      color: "bg-[#5B95F9] text-white",
      title: "cs",
    },
    {
      key: "in",
      dbColName: "in",
      color: "bg-[#5B95F9] text-white",
      title: "in",
    },
    {
      key: "contacts",
      dbColName: "contacts",
      color: "bg-[#5B95F9] text-white",
      title: "contacts",
    },
    {
      key: "signed_reps",
      dbColName: "signed_reps",
      color: "bg-[#5B95F9] text-white",
      title: "signed contacts",
    },
    {
      key: "rws",
      dbColName: "rws",
      color: "bg-[#5B95F9] text-white",
      title: "rws",
    },
    {
      key: "cwra",
      dbColName: "cwra",
      color: "bg-[#5B95F9] text-white",
      title: "cwra",
    },
    {
      key: "cfp",
      dbColName: "cfp",
      color: "bg-[#5B95F9] text-white",
      title: "cfp",
    },
    {
      key: "ccs",
      dbColName: "ccs",
      color: "bg-[#5B95F9] text-white",
      title: "ccs",
    },
    {
      key: "cin",
      dbColName: "cin",
      color: "bg-[#5B95F9] text-white",
      title: "cin",
      header: () => (
        <button
          type="button"
          onClick={() =>
            setShowColumns((prev) => ({
              ...prev,
              management: !prev.management,
            }))
          }
        >
          {showColumns.management ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
              />
            </svg>
          )}
        </button>
      ),
    },

    {
      key: "team",
      dbColName: "team",
      title: "team",
      show: false,
      color: "bg-[#F8CB4D] text-white",
    },
    {
      key: "management_group",
      dbColName: "management_group",
      show: false,
      title: "management group",
      color: "bg-[#F8CB4D] text-white",
    },
    {
      key: "senior_management_group",
      dbColName: "senior_management_group",
      title: "senior management group",
      show: false,
      color: "bg-[#F8CB4D] text-white",
    },
    {
      key: "region",
      dbColName: "region",
      title: "region",
      show: false,
      color: "bg-[#F8CB4D] text-white",
    },
    {
      key: "senior_region",
      dbColName: "senior_region",
      color: "bg-[#F8CB4D] text-white",
      show: false,
      title: "senior region",
    },
    {
      key: "partnership",
      dbColName: "partnership",
      show: false,
      title: "partnership",
      color: "bg-[#F8CB4D] text-white",
    },
    {
      key: "division",
      dbColName: "division",
      title: "division",
      show: false,
      color: "bg-[#F8CB4D] text-white",
      header: () => (
        <button
          type="button"
          onClick={() =>
            setShowColumns((prev) => ({ ...prev, leader: !prev.leader }))
          }
        >
          {showColumns.leader ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
              />
            </svg>
          )}
        </button>
      ),
    },

    {
      key: "contact_leader_phone_number",
      dbColName: "contact_leader_phone_number",
      show: false,
      color: "bg-[#F36524] text-white",
      title: "contact leader phone number",
    },
    {
      key: "contact_leader_email",
      show: false,
      dbColName: "contact_leader_email",
      color: "bg-[#F36524] text-white",
      title: "contact leader email",
    },
    {
      key: "recruiter_id",
      dbColName: "recruiter_id",
      show: false,
      title: "recruiter id",
      color: "bg-[#F36524] text-white",
    },
    {
      key: "recruiter_name",
      dbColName: "recruiter_name",
      show: false,
      color: "bg-[#F36524] text-white",
      title: "recruiter name",
    },
    {
      key: "recruiter_phone_number",
      dbColName: "recruiter_phone_number",
      color: "bg-[#F36524] text-white",
      show: false,
      title: "recruiter phone number",
    },
    {
      key: "recruiter_email",
      show: false,
      dbColName: "recruiter_email",
      color: "bg-[#F36524] text-white",
      title: "recruiter email",
    },
    {
      key: "team_leader_id",
      dbColName: "team_leader_id",
      show: false,
      color: "bg-[#F36524] text-white",
      title: "team leader id",
    },
    {
      key: "team_leader_name",
      dbColName: "team_leader_name",
      color: "bg-[#F36524] text-white",
      show: false,
      title: "team leader name",
    },
    {
      key: "team_leader_phone_number",
      dbColName: "team_leader_phone_number",
      color: "bg-[#F36524] text-white",
      show: false,
      title: "team leader phone number",
    },
    {
      key: "team_leader_email",
      dbColName: "team_leader_email",
      color: "bg-[#F36524] text-white",
      show: false,
      title: "team leader email",
    },
    {
      key: "management_group_leader_id",
      dbColName: "management_group_leader_id",
      show: false,
      color: "bg-[#F36524] text-white",
      title: "management group leader id",
    },
    {
      key: "management_group_leader_name",
      dbColName: "management_group_leader_name",
      show: false,
      color: "bg-[#F36524] text-white",
      title: "management group leader name",
    },
    {
      key: "management_group_leader_phone_number",
      dbColName: "management_group_leader_phone_number",
      color: "bg-[#F36524] text-white",
      show: false,
      title: "management group leader phone number",
    },
    {
      key: "management_group_leader_email",
      dbColName: "management_group_leader_email",
      color: "bg-[#F36524] text-white",
      show: false,
      title: "management group leader email",
    },
    {
      key: "senior_management_group_leader_id",
      dbColName: "senior_management_group_leader_id",
      color: "bg-[#F36524] text-white",
      show: false,
      title: "senior_management_group_leader_id",
    },
    {
      key: "senior_management_group_leader",
      dbColName: "senior_management_group_leader",
      color: "bg-[#F36524] text-white",
      show: false,
      title: "senior management group leader",
    },
    {
      key: "senior_management_group_leader_phone_number",
      dbColName: "senior_management_group_leader_phone_number",
      color: "bg-[#F36524] text-white",
      show: false,
      title: "senior management group leader phone number",
    },
    {
      key: "senior_management_group_leader_email",
      dbColName: "senior_management_group_leader_email",
      show: false,
      color: "bg-[#F36524] text-white",
      title: "senior management group leader email",
    },
    {
      key: "region_leader_id",
      dbColName: "region_leader_id",
      show: false,
      color: "bg-[#F36524] text-white",
      title: "region leader id",
    },
    {
      key: "region_leader_name",
      dbColName: "region_leader_name",
      show: false,
      color: "bg-[#F36524] text-white",
      title: "region leader name",
    },
    {
      key: "region_leader_phone_number",
      show: false,
      dbColName: "region_leader_phone_number",
      color: "bg-[#F36524] text-white",
      title: "region leader phone number",
    },
    {
      key: "region_leader_email",
      dbColName: "region_leader_email",
      color: "bg-[#F36524] text-white",
      show: false,
      title: "region leader email",
    },
    {
      key: "senior_region_id",
      dbColName: "senior_region_id",
      show: false,
      color: "bg-[#F36524] text-white",
      title: "senior region id",
    },
    {
      key: "senior_region_name",
      dbColName: "senior_region_name",
      show: false,
      color: "bg-[#F36524] text-white",
      title: "senior region name",
    },
    {
      key: "senior_region_leader_phone_number",
      dbColName: "senior_region_leader_phone_number",
      show: false,
      color: "bg-[#F36524] text-white",
      title: "senior region leader phone number",
    },
    {
      key: "senior_region_leader_email",
      dbColName: "senior_region_leader_email",
      show: false,
      color: "bg-[#F36524] text-white",
      title: "senior region leader email",
    },
    {
      key: "partner_leader_id",
      dbColName: "partner_leader_id",
      show: false,
      color: "bg-[#F36524] text-white",
      title: "partner leader id",
    },
    {
      key: "partner_leader_name",
      dbColName: "partner_leader_name",
      show: false,
      color: "bg-[#F36524] text-white",
      title: "partner leader name",
    },
    {
      key: "partner_leader_phone_number",
      dbColName: "partner_leader_phone_number",
      color: "bg-[#F36524] text-white",
      title: "partner_leader_phone number",
      show: false,
    },
    {
      key: "division_leader_id",
      dbColName: "division_leader_id",
      color: "bg-[#F36524] text-white",
      show: false,
      title: "division leader id",
    },
    {
      key: "division_leader_name",
      dbColName: "division_leader_name",
      color: "bg-[#F36524] text-white",
      show: false,
      title: "division leader name",
    },
    {
      key: "division_leader_phone_number",
      dbColName: "division_leader_phone_number",
      color: "bg-[#F36524] text-white",
      show: false,
      title: "division leader phone number",
    },

    // {
    //   key: "reminder_text_date",
    //   dbColName: "reminder_text_date",
    //   title: "reminder text date",
    // },
    // {
    //   key: "reminder_text_time",
    //   dbColName: "reminder_text_time",
    //   title: "reminder text time",
    // },
    // {
    //   key: "reminder_text_timezone",
    //   dbColName: "reminder_text_timezone",
    //   title: "reminder text timezone",
    // },
    // {
    //   key: "signed_badge_id",
    //   dbColName: "signed_badge_id",
    //   title: "signed badge id",
    // },

    // {
    //   key: "last_signed_contact_date",
    //   dbColName: "last_signed_contact_date",
    //   title: "last signed contact date",
    // },

    // { key: "efp", dbColName: "efp", title: "efp" },

    // { key: "ad_source", dbColName: "ad_source", title: "ad_source" },
    // { key: "ad_category", dbColName: "ad_category", title: "ad category" },
    // { key: "duplicate_id", dbColName: "duplicate_id", title: "duplicate id" },
    // {
    //   key: "duplicate_phone",
    //   dbColName: "duplicate_phone",
    //   title: "duplicate phone",
    // },
    // {
    //   key: "duplicate_email",
    //   dbColName: "duplicate_email",
    //   title: "duplicate email",
    // },
    // {
    //   key: "campusreel_direct_applicants",
    //   dbColName: "campusreel_direct_applicants",
    //   title: "campusreel direct applicants",
    // },
    // {
    //   key: "campusreel_coregistration",
    //   dbColName: "campusreel_coregistration",
    //   title: "campusreel coregistration",
    // },
    // {
    //   key: "campusreel_scholarship_email",
    //   dbColName: "campusreel_scholarship_email",
    //   title: "campusreel scholarship email",
    // },
    // {
    //   key: "campusreel_scholarship_ytd",
    //   dbColName: "campusreel_scholarship_ytd",
    //   title: "campusreel scholarship ytd",
    // },
    // {
    //   key: "campusreel_form_completed",
    //   dbColName: "campusreel_form_completed",
    //   title: "campusreel form completed",
    // },
    // {
    //   key: "social_website",
    //   dbColName: "social_website",
    //   title: "social website",
    // },
    // {
    //   key: "digitalhire_candidates",
    //   dbColName: "digitalhire_candidates",
    //   title: "digitalhire candidates",
    // },
    // {
    //   key: "digitalhire_summer_outreach",
    //   dbColName: "digitalhire_summer_outreach",
    //   title: "digitalhire summer outreach",
    // },
    // { key: "evo_website", dbColName: "evo_website", title: "evo website" },
    // {
    //   key: "evo_scholarship_bonus",
    //   dbColName: "evo_scholarship_bonus",
    //   title: "evo scholarship bonus",
    // },
    // {
    //   key: "digital_recruit",
    //   dbColName: "digital_recruit",
    //   title: "digital recruit",
    // },
    // { key: "deleted", dbColName: "deleted", title: "deleted" },
    // { key: "last_updated", dbColName: "last_updated", title: "last updated" },
    // { key: "timestamp", dbColName: "timestamp", title: "timestamp" },

    // { key: "uid", dbColName: "uid", title: "uid" },
    // {
    //   key: "signed_phone_lookup",
    //   dbColName: "signed_phone_lookup",
    //   title: "signed phone lookup",
    // },
    // {
    //   key: "signed_full_name_lookup",
    //   dbColName: "signed_full_name_lookup",
    //   title: "signed full name lookup",
    // },
    {
      key: "datesubmitted",
      dbColName: "datesubmitted",
      title: "datesubmitted",
    },
    {
      key: "action",
      dbColName: "_id",
      freeze: true,
      freezeIndex: "right-0",
      title: "action",
      render: (id) => {
        return (
          <button
            type="button"
            className="text-red-500 text-right"
            onClick={() => handleDelete(id)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="h-6 w-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
              />
            </svg>
          </button>
        );
      },
    },
    {
      key: "createdAt",
      dbColName: "createdAt",
      title: "createdAt",
      render: (date) => new Date(date).toLocaleDateString(),
    },
  ];

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [exporting, setExporting] = useState(false);

  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 100,
    source: "",
    location: "",
    contactCreatedStartDate: "",
    contactCreatedEndDate: "",
    fullName: "",
    recruiterName: "",
    recruiterbadgeId: "",
    groupBy: "",
    tenure: "",
    stage: "",
    team: "",
  });
  const [tab, setTab] = useState("Contacts");

  const debounced = useDebounce(pagination, 500);

  const { successMessage } = useNotifications();

  useEffect(() => {
    setLoading(true);
    service
      .getContacts(debounced)
      .then((resp) => {
        if (resp.data.success) {
          setTotalItems(resp.data.totalDocument);
          setData(resp.data.data);
        }
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setLoading(false);
      });
  }, [debounced]);

  const handleExport = () => {
    setExporting(true);
    const pagin: any = { ...pagination };
    delete pagin.pageNumber;
    delete pagin.pageSize;
    service
      .downloadContacts(pagin)
      .then((resp) => {
        exportToCSV(resp.data.data, "contacts");
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setExporting(false);
      });
  };

  const handleDelete = (id) => {
    service
      .deleteContact(id)
      .then((resp) => {
        if (resp.data.success) {
          successMessage("Contact deleted successfully");
          const index = data.findIndex(
            (item: { _id: string }) => item._id === id
          );
          const shallowCopy = [...data];
          shallowCopy.splice(index, 1);
          setData(shallowCopy);
        }
      })
      .catch((error) => console.log(error));
  };

  return (
    <Fragment>
      <div className="flex flex-col mb-4">
        <h1 className="font-medium text-gray-700 tracking-wide text-xl">
          {tab}
        </h1>
        <p className="text-gray-500 text-sm">View all {tab}</p>
      </div>

      {/* Tables */}
      {tab === "Contacts" ? (
        <Table
          data={data}
          loadingData={loading}
          pagination={pagination}
          setPagination={setPagination}
          handleExport={handleExport}
          showSearch={false}
          totalItems={totalItems}
          exporting={exporting}
          setData={setData}
          filters={
            <Filters pagination={pagination} setPagination={setPagination} />
          }
          columnFilters={
            <ColumnFilters
              pagination={pagination}
              setPagination={setPagination}
            />
          }
          columns={columns.map((item) => {
            if (NAMES.includes(item.title)) {
              return {
                ...item,
                show: showColumns.names,
              };
            }
            if (CONTACT.includes(item.title)) {
              return {
                ...item,
                show: showColumns.contacts,
              };
            }
            if (SESSIONS.includes(item.title)) {
              return {
                ...item,
                show: showColumns.sessions,
              };
            }
            if (UPGRADE.includes(item.title)) {
              return {
                ...item,
                show: showColumns.upgrade,
              };
            }
            if (LEADER.includes(item.title)) {
              return {
                ...item,
                show: showColumns.leader,
              };
            }
            if (MANAGEMENT.includes(item.title)) {
              return {
                ...item,
                show: showColumns.management,
              };
            }
            return item;
          })}
        />
      ) : tab === "Info Sessions" ? (
        <InfoSessions />
      ) : (
        <Scholarships />
      )}

      {/* Tabs */}
      {/* <div className="text-sm mt-4 p-2 text-center text-gray-500 rounded-md bg-gray-200">
        <div className="flex flex-wrap -mb-px gap-3">
          <button
            type="button"
            onClick={() => setTab("Contacts")}
            className={classNames(tabStyles.default, {
              [tabStyles.active]: tab === "Contacts",
            })}
          >
            Contacts
          </button>
          <button
            type="button"
            onClick={() => setTab("Info Sessions")}
            className={classNames(tabStyles.default, {
              [tabStyles.active]: tab === "Info Sessions",
            })}
          >
            Info Sessions
          </button>
          <button
            type="button"
            onClick={() => setTab("Scholarships")}
            className={classNames(tabStyles.default, {
              [tabStyles.active]: tab === "Scholarships",
            })}
          >
            Scholarships
          </button>
        </div>
      </div> */}
    </Fragment>
  );
};

const Filters = ({ pagination, setPagination }) => {
  return (
    <div className="flex flex-1 items-center gap-4">
      <input
        className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
        type="text"
        placeholder="Search by Name"
        onChange={(e) => {
          setPagination({
            ...pagination,
            fullName: e.target.value,
          });
        }}
      />
      <input
        className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
        type="text"
        placeholder="Search by Email"
        onChange={(e) => {
          setPagination({
            ...pagination,
            email: e.target.value,
          });
        }}
      />
      <input
        className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
        type="text"
        placeholder="Search by Recruiter Name"
        onChange={(e) => {
          setPagination({
            ...pagination,
            recruiterName: e.target.value,
          });
        }}
      />
      <input
        className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
        type="text"
        placeholder="Search by Badge ID"
        onChange={(e) => {
          setPagination({
            ...pagination,
            recruiterbadgeId: e.target.value,
          });
        }}
      />
      <input
        className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
        type="text"
        placeholder="Location"
        onChange={(e) => {
          setPagination({
            ...pagination,
            location: e.target.value,
          });
        }}
      />
    </div>
  );
};

const ColumnFilters = ({ pagination, setPagination }) => {
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selected, setSelected] = useState<string[]>([]);
  const [search, setSearch] = useState("");
  const [submittedStartDate, setSubmittedStartDate] = useState<any>(null);
  const [submittedEndDate, setSubmittedEndDate] = useState<any>(null);

  const onChangeCreated = (dates) => {
    const [start, end] = dates;

    setStartDate(start);
    setEndDate(end);

    if (start && end) {
      setPagination({
        ...pagination,
        contactCreatedStartDate: start && formatDateY(start),
        contactCreatedEndDate: end && formatDateY(end),
      });
    }
  };
  const [users, setUsers] = useState([]);
  const [tag, setTag] = useState("");
  const [loadingUser, setLoadingUser] = useState(false);

  useEffect(() => {
    setLoadingUser(true);
    service
      .getFilters(tag)
      .then((resp) => {
        setUsers(resp.data.data);
        setSelected(resp.data.data.map((item: any) => item.badgeid));
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoadingUser(false);
      });
  }, [tag]);

  const onChangeSubmitted = (dates) => {
    const [start, end] = dates;

    if (start && end) {
      setPagination({
        ...pagination,
        contact_submitted_startDdate: start && formatDateY(start),
        contact_submitted_end_date: end && formatDateY(end),
      });
    }

    setSubmittedStartDate(start);
    setSubmittedEndDate(end);
  };

  const filteredUsers = users.filter((item: any) =>
    item?.name?.toLowerCase().includes(search.toLowerCase())
  );

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setSelected(filteredUsers.map((item: any) => item.badgeid));
    } else {
      setSelected([]);
    }
  };

  const handleClick = () => {
    const ids = selected.map((i) => i);
    setPagination({
      ...pagination,
      groupBy: tag,
      users: ids.length === users.length ? "All" : ids.join(","),
    });
    setShowModal(false);
  };

  return (
    <div className="flex items-center gap-4 mt-3">
      <div className="flex items-center gap-2">
        <button
          type="button"
          onClick={() => {
            if (!tag) {
              setShowModal(true);
              setTag("Manager");
            } else {
              setShowModal(true);
            }
          }}
          className="text-sm flex text-white bg-black rounded-md px-3 py-2"
        >
          Heirarchy
        </button>
        {tag && (
          <button
            className="text-sm text-gray-700"
            onClick={() => {
              setTag("");
              setSelected([]);
            }}
          >
            X
          </button>
        )}
      </div>
      {/* <select
        placeholder="Stage"
        className="rounded-md flex-1 focus:border-gray-300 font-light focus:outline-none w-72 focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
        onChange={(e) => {
          setTag(e.target.value);
          setPagination({
            ...pagination,
            groupBy: e.target.value,
          });
        }}
      >
        <option value="" disabled selected>
          Tag
        </option>
        <option value="">All</option>
        <option value="Rep">Rep</option>
        <option value="Recruiter">Recruiter</option>
        <option value="Team Lead">Team Lead</option>
        <option value="Manager">Manager</option>
        <option value="Senior Manager">Senior Manager</option>
        <option value="Regional">Regional</option>
        <option value="Senior Regional">Senior Regional</option>
        <option value="Partner">Partner</option>
      </select>

      <div className="flex-shrink-0">
        <Select
          placeholder="Users"
          className={classNames("react-select")}
          classNamePrefix="react-select"
          onChange={(e) => handleChange(e)}
          options={users.map((item: any) => ({
            label: item.name,
            value: item.badgeid,
          }))}
          isMulti
        />
      </div> */}

      <select
        placeholder="Stage"
        className="rounded-md flex-1 focus:border-gray-300 font-light focus:outline-none w-72 focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
        onChange={(e) => {
          setPagination({
            ...pagination,
            stage: e.target.value,
          });
        }}
      >
        <option value="" disabled selected>
          Stage
        </option>
        <option value="">All</option>
        <option value="Contact">Contact</option>
        <option value="Contact+">Contact+</option>
        <option value="Signed">Signed</option>
        <option value="Signed+">Signed+</option>
        <option value="SH Pro">SH Pro</option>
        <option value="SH Pro+">SH Pro+</option>
      </select>

      <select
        placeholder="Source"
        className="rounded-md flex-1 focus:border-gray-300 font-light focus:outline-none w-72 focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
        onChange={(e) => {
          setPagination({
            ...pagination,
            source: e.target.value,
          });
        }}
      >
        <option value="" disabled selected>
          Source
        </option>
        <option value="">All</option>
        <option value="Info Session">Info Session</option>
        <option value="Signedreps">Signedreps</option>
        <option value="Networking">Networking</option>
        <option value="Signed Rep 2024">Signed Rep 2024</option>
        <option value="Signed 2023">Signed 2023</option>
        <option value="Previously Signed">Previously Signed</option>
        <option value="Career Fair">Career Fair</option>
        <option value="ECARD INTERVIEW">ECARD INTERVIEW</option>
        <option value="Direct Contact">Direct Contact</option>

        <option value="Scholarship">Scholarship</option>
        <option value="Campus Recruiting">Campus Recruiting</option>
      </select>

      <select
        placeholder="Tenure"
        className="rounded-md flex-1 focus:border-gray-300 font-light focus:outline-none w-72 focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
        onChange={(e) => {
          setPagination({
            ...pagination,
            tenure: e.target.value,
          });
        }}
      >
        <option value="" disabled selected>
          Tenure
        </option>
        <option value="">All</option>
        <option value="Rookie">Rookie</option>
        <option value="Veteran">Veteran</option>
        <option value="Sophomore">Sophomore</option>
      </select>

      <select
        placeholder="Max Position"
        className="rounded-md flex-1 focus:border-gray-300 font-light focus:outline-none w-72 focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
        onChange={(e) => {
          setPagination({
            ...pagination,
            maxPosition: e.target.value,
          });
        }}
      >
        <option value="" disabled selected>
          Max Position
        </option>
        <option value="">All</option>
        <option value="Rep">Rep</option>
        <option value="Team Lead">Team Lead</option>
        <option value="Manager">Manager</option>
        <option value="Senior Manager">Senior Manager</option>
        <option value="Regional">Regional</option>
        <option value="Senior Regional">Senior Regional</option>
        <option value="Partner">Partner</option>
        <option value="VP">VP</option>
      </select>

      <DatePicker
        selected={startDate}
        placeholderText="Contact Created Date"
        onChange={onChangeCreated}
        startDate={startDate}
        endDate={endDate}
        // showIcon
        isClearable
        className="z-100 py-2"
        customInput={
          <input
            placeholder="Select date"
            className=" bg-white rounded-md border  border-gray-outline outline-none focus-visible:shadow-none placeholder:text-sm focus:ring-2 ring-brown-10 ring-opacity-50 py-2 text-sm text-zinc-600 pl-4 pr-4 disabled:bg-zinc-50 disabled:text-zinc-500 disabled:cursor-not-allowed text-dark-8 font-TTHoves;"
          />
        }
        selectsRange
        selectsDisabledDaysInRange
      />

      {/* <DatePicker
        selected={submittedStartDate}
        placeholderText="Contact Submitted Date"
        onChange={onChangeSubmitted}
        // showIcon
        startDate={submittedStartDate}
        endDate={submittedEndDate}
        isClearable
        className="z-100 py-2"
        customInput={
          <input
            placeholder="Select date"
            className=" bg-white  rounded-md border w-80 border-gray-outline outline-none focus-visible:shadow-none placeholder:text-sm focus:ring-2 ring-brown-10 ring-opacity-50 py-2 text-sm text-zinc-600 pl-4 pr-4 disabled:bg-zinc-50 disabled:text-zinc-500 disabled:cursor-not-allowed text-dark-8 font-TTHoves;"
          />
        }
        selectsRange
        selectsDisabledDaysInRange
      /> */}

      {/* <input
        className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
        type="date"
        onChange={(e) =>
          setPagination({
            ...pagination,
            date_submitted: e.target.value,
          })
        }
      />

      <input
        className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
        type="date"
        onChange={(e) =>
          setPagination({
            ...pagination,
            contact_created: e.target.value,
          })
        }
      /> */}

      <Modal
        showModal={showModal}
        onClick={() => {
          setTag("");
          setSelected([]);
        }}
        setShowModal={setShowModal}
        title={"Heirarchy"}
      >
        <div className="overflow-auto h-[35rem] p-2">
          <div className="grid grid-cols-5 gap-3">
            <button
              type="button"
              onClick={() => setTag("Rep")}
              className={classNames(
                "border-black border hover:bg-black hover:text-white text-center rounded-md px-3 py-2",
                { "bg-black text-white": tag === "Rep" }
              )}
            >
              Rep
            </button>
            <button
              type="button"
              onClick={() => setTag("Recruiter")}
              className={classNames(
                "border border-black hover:bg-black hover:text-white text-center rounded-md px-3 py-2",
                { "bg-black text-white": tag === "Recruiter" }
              )}
            >
              Recruiter
            </button>
            <button
              type="button"
              onClick={() => setTag("Contact Leader")}
              className={classNames(
                "border border-black hover:bg-black hover:text-white text-center rounded-md px-3 py-2",
                { "bg-black text-white": tag === "Contact Leader" }
              )}
            >
              Contact Leader
            </button>
            <button
              type="button"
              onClick={() => setTag("Team Lead")}
              className={classNames(
                "border border-black text-center rounded-md px-3 py-2",
                { "bg-black text-white": tag === "Team Lead" }
              )}
            >
              Team Lead
            </button>
            <button
              type="button"
              onClick={() => setTag("Manager")}
              className={classNames(
                "border border-black hover:bg-black hover:text-white text-center rounded-md px-3 py-2",
                { "bg-black text-white": tag === "Manager" }
              )}
            >
              Manager
            </button>
            <button
              type="button"
              onClick={() => setTag("Senior Manager")}
              className={classNames(
                "border border-black hover:bg-black hover:text-white text-center rounded-md px-3 py-2",
                { "bg-black text-white": tag === "Senior Manager" }
              )}
            >
              Senior Manager
            </button>
            <button
              type="button"
              onClick={() => setTag("Regional")}
              className={classNames(
                "border border-black hover:bg-black hover:text-white text-center rounded-md px-3 py-2",
                { "bg-black text-white": tag === "Regional" }
              )}
            >
              Regional
            </button>
            <button
              type="button"
              onClick={() => setTag("Senior Regional")}
              className={classNames(
                "border border-black hover:bg-black hover:text-white text-center rounded-md px-3 py-2",
                { "bg-black text-white": tag === "Senior Regional" }
              )}
            >
              Senior Regional
            </button>
            <button
              type="button"
              onClick={() => setTag("Partner")}
              className={classNames(
                "border border-black hover:bg-black hover:text-white text-center rounded-md px-3 py-2",
                { "bg-black text-white": tag === "Partner" }
              )}
            >
              Partner
            </button>
          </div>
          <div className="mt-3">
            <input
              type="text"
              onChange={(e) => setSearch(e.target.value)}
              className="border w-full rounded-md border-zinc-300 p-2"
            />
          </div>

          <label className="flex items-center gap-2 mt-2 justify-end">
            <input
              type="checkbox"
              defaultChecked={
                selected.length === filteredUsers.length ||
                selected.length === 0
              }
              onChange={handleSelectAll}
            />
            Select All
          </label>

          {loadingUser ? (
            <p>Loading, please wait...</p>
          ) : (
            filteredUsers.map((item: any, index) => (
              <div key={item.badgeid} className="flex items-center gap-2 py-2 ">
                <div>
                  <input
                    checked={selected.includes(item.badgeid)}
                    type="checkbox"
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelected((prev) => [...prev, item.badgeid]);
                      } else {
                        setSelected((prev) =>
                          prev.filter((id) => id !== item.badgeid)
                        );
                      }
                    }}
                  />
                </div>
                <p>{item.name}</p>
              </div>
            ))
          )}
        </div>
        <div className="flex gap-2">
          <button
            type="button"
            onClick={() => {
              setTag("");
              setSelected([]);
              setShowModal(false);
            }}
            className="flex-1 rounded-md py-2 text-white bg-primary"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={handleClick}
            className="flex-1 rounded-md py-2 text-white bg-black"
          >
            Submit
          </button>
        </div>
      </Modal>
    </div>
  );
};
