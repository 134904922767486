import classNames from "classnames";
import { useEffect, useState } from "react";
import UserService from "services/user.service";
import Select from "react-select";
import {
  ALPHABETS,
  NEW_TIMEZONE,
  PackageList,
  PositionList,
  SeasonList,
  States,
} from "utils/constants";
import { useDebounce } from "hooks/useDebounce";
import { useNotifications } from "hooks/useNotification";
import { useRouter } from "hooks/useRouter";
import { convertTo12HourFormat } from "utils/funcs";

const service = new UserService();

export const AddMediaAd = () => {
  const [state, setState] = useState<any>({
    state: "",
    city_name: "",
    position: "",
    badge_id: "",
    ad_type: "RECRUITING",
    ad_approval_status: "UNDER REVIEW",
    who_is_paying_for_ad: "", //Myself , Someone else, Split
    bill_split_between: [
      // this will only be uncomment if who_is_paying_for_ad != Myself
    ],
    ad_status: "Upcoming",
    season: "",
    ad_amount: "",
    package_name: "",
    personal_gmail_account: "",
    package_start_date: new Date(),
    package_end_date: "",
    ad_run_day_count: 5,
    recruiting_interview: [
      {
        block_name: "A",
        date: "",
        start_time: "",
        time_zone: "",
        end_time: "",
        interview_type: "",
        custom_location: false,
      },
    ],
  });
  const [step, setStep] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [selectedPackage, setSelectedPackage] = useState(0);
  const [users, setUsers] = useState([]);
  const [error, setError] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [minSelectableDate, setMinSelectableDate] = useState("");

  const { push } = useRouter();
  const { successMessage, errorMessage } = useNotifications();

  const debbounced = useDebounce(searchValue, 500);

  useEffect(() => {
    if (state.package_end_date) {
      // Create new date object from the package end date
      const endDateObj = new Date(state.package_end_date);

      // Add 14 days
      const minDate = new Date(endDateObj);
      minDate.setDate(endDateObj.getDate() + 14);

      // Format dates to YYYY-MM-DD
      const endDateStr = endDateObj.toISOString().split("T")[0];
      const minDateStr = minDate.toISOString().split("T")[0];

      // Set the initial date to package_end_date
      setSelectedDate(endDateStr);

      // Set the minimum selectable date
      setMinSelectableDate(minDateStr);
    } else {
      setSelectedDate("");
      setMinSelectableDate("");
    }
  }, [state.package_end_date]);

  useEffect(() => {
    setLoadingUsers(true);
    service
      .getSignedReps({
        repName: debbounced,
      })
      .then((resp) => {
        setUsers(
          resp.data.data.map((item) => ({
            value: item._id,
            label: item.rep_name,
          }))
        );
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoadingUsers(false);
      });
  }, [debbounced]);

  const handleInterviewChange = (index, field, value) => {
    const updatedInterviews = [...state.recruiting_interview];
    updatedInterviews[index][field] = value;

    // If changing end_time and start_time exists, validate the 2-hour difference
    if (field === "end_time" && updatedInterviews[index].start_time) {
      validateTimeGap(updatedInterviews[index].start_time, value, index);
    }

    setState({
      ...state,
      recruiting_interview: updatedInterviews,
    });
  };

  const validateTimeGap = (startTime, endTime, index) => {
    if (!startTime || !endTime) return;

    // Parse times
    const [startHours, startMinutes] = startTime.split(":").map(Number);
    const [endHours, endMinutes] = endTime.split(":").map(Number);

    // Calculate time difference in minutes
    const startTotalMinutes = startHours * 60 + startMinutes;
    const endTotalMinutes = endHours * 60 + endMinutes;
    const diffMinutes = endTotalMinutes - startTotalMinutes;

    // Check if difference is at least 2 hours (120 minutes)
    if (diffMinutes < 120) {
      // Calculate the minimum valid end time (2 hours after start time)
      let minEndHours = startHours + 2;
      const minEndMinutes = startMinutes;
      const minEndTime = `${String(minEndHours).padStart(2, "0")}:${String(
        minEndMinutes
      ).padStart(2, "0")}`;

      // Show notification to user
      alert(
        `The end time must be at least 2 hours after the start time. The minimum end time should be ${minEndTime}.`
      );

      // Update with minimum valid end time
      const updatedInterviews = [...state.recruiting_interview];
      updatedInterviews[index].end_time = minEndTime;

      setState({
        ...state,
        recruiting_interview: updatedInterviews,
      });
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    setState({
      ...state,
      [name]: value,
    });
  };

  const calculateNewDate = (days, date) => {
    const updatedDate = new Date(date);
    updatedDate.setDate(updatedDate.getDate() + days);
    return updatedDate;
  };

  const onChangePackage = (days, packageName, amount) => {
    setSelectedPackage(days);
    setState({
      ...state,
      package_start_date: state.package_start_date,
      package_end_date: calculateNewDate(days, state.package_start_date),
      package_name: packageName,
      ad_amount: amount,
    });
  };

  const handleChangeStart = (date) => {
    const currentDays =
      state?.package_name === "PREMIUM"
        ? 5
        : state?.package_name === "BASIC"
        ? 3
        : 4;
    setState({
      ...state,
      package_start_date: date,
      package_end_date: calculateNewDate(currentDays, date),
    });
  };

  const addInterview = () => {
    // Get the next block letter based on current array length
    const nextBlockLetter = String.fromCharCode(
      65 + state.recruiting_interview.length
    );

    setState({
      ...state,
      recruiting_interview: [
        ...state.recruiting_interview,
        {
          block_name: nextBlockLetter,
          date: "",
          start_time: "",
          end_time: "",
          interview_type: "",
          custom_location: false,
        },
      ],
    });
  };

  const validateEmail = (inputEmail) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Check if email is empty
    if (!inputEmail) {
      return "Email is required";
    }

    // Check email format using regex
    if (!emailRegex.test(inputEmail)) {
      return "Please enter a valid email address";
    }

    return "";
  };

  const handleEmailChange = (e) => {
    const inputEmail = e.target.value;
    setState({
      ...state,
      personal_gmail_account: inputEmail,
    });
    setError(validateEmail(inputEmail));
  };

  const removeInterview = (index) => {
    // Create a copy of the interviews array
    const updatedInterviews = [...state.recruiting_interview];

    // Remove the interview at the specified index
    updatedInterviews.splice(index, 1);

    // Reassign block_name for all interviews based on their new position
    updatedInterviews.forEach((interview, idx) => {
      interview.block_name = String.fromCharCode(65 + idx);
    });

    setState({
      ...state,
      recruiting_interview: updatedInterviews,
    });
  };

  const handleNext = async () => {
    if(step === 1 && error) {
      return;
    }
    if (
      step === 1 &&
      (!state.personal_gmail_account ||
        !state.city_name ||
        !state.who_is_paying_for_ad ||
        !state.badge_id )
    ) {
      errorMessage("Please fill all fields");
      return;
    } else if (
      step === 2 &&
      (!state.season ||
        !state.position ||
        !state.package_name ||
        !state.package_start_date)
    ) {
      errorMessage("Please fill all fields");
      return;
    } else if (step === 3) {
      try {
        const payload = {
          ...state,
          recruiting_interview: state.recruiting_interview.map((item) => ({
            ...item,
            start_time: convertTo12HourFormat(item.start_time),
            end_time: convertTo12HourFormat(item.end_time),
          })),
          bill_split_between: state.bill_split_between.map((item) => ({
            name: item.label,
            isAccepted: false,
            badgeid: item.value,
          })),
        };
        await service.addMediaAd(payload);
        successMessage("Successfully added");
        push("/media-ads");
      } catch (error) {}
    } else {
      setStep((prev) => prev + 1);
    }
  };

  console.log(state);

  return (
    <form className="flex max-w-4xl border border-zinc-200 p-6 bg-white shadow-sm rounded-lg flex-col gap-3">
      <div
        className={classNames({
          hidden: step !== 1,
        })}
      >
        <h1 className="text-2xl mb-2 font-semibold text-zinc-800">
          Ad Marketing Location
        </h1>
        <div className={classNames("grid grid-cols-2 gap-3")}>
          <div className="flex-1 flex flex-col gap-1.5">
            <label className="text-gray-700">Badge ID</label>
            <input
              type="text"
              required
              name="badge_id"
              value={state.badge_id}
              onChange={handleChange}
              placeholder="Enter badge id"
              className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2  text-gray-800"
            />
          </div>
          <div className="flex-1 flex flex-col gap-1.5">
            <label className="text-gray-700">State</label>
            <select
              onChange={handleChange}
              name="state"
              value={state.state}
              className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2  text-gray-800"
            >
              <option value="">Select State</option>
              {States.map((item) => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>
          <div className="flex-1 flex flex-col gap-1.5">
            <label className="text-gray-700">City Name</label>
            <input
              type="text"
              name="city_name"
              value={state.city_name}
              required
              onChange={handleChange}
              placeholder="City Name"
              className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2  text-gray-800"
            />
          </div>
          <div className="flex-1 flex flex-col gap-1.5">
            <label className="text-gray-700">Email</label>
            <input
              type="email"
              required
              name="personal_gmail_account"
              value={state.personal_gmail_account}
              onChange={handleEmailChange}
              placeholder="Enter personal email address"
              className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2  text-gray-800"
            />
            {error && <p className="text-red-500 text-xs">{error}</p>}
          </div>
          <div className="flex-1 flex flex-col gap-1.5">
            <label className="text-gray-700">Who is paying for ad?</label>
            <select
              name="who_is_paying_for_ad"
              value={state.who_is_paying_for_ad}
              onChange={handleChange}
              className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2  text-gray-800"
            >
              <option value="">Select</option>
              <option value="Myself">Myself</option>
              <option value="Someone else">Someone else</option>
              <option value="Split">Split</option>
            </select>
          </div>
          {state.who_is_paying_for_ad &&
            state.who_is_paying_for_ad !== "Myself" && (
              <div className="flex-1 col-span-2 flex flex-col gap-1.5">
                <label className="text-gray-700">User</label>
                <Select
                  options={users}
                  placeholder="Search"
                  onChange={(value) =>
                    setState({
                      ...state,
                      bill_split_between: [...state.bill_split_between, value],
                    })
                  }
                  isLoading={loadingUsers}
                  onInputChange={(newValue) => setSearchValue(newValue)}
                  className="react-select"
                  classNamePrefix="react-select"
                />
              </div>
            )}
          <div className="flex-1 flex flex-col gap-1.5">
            <label className="text-gray-700">Ad Type</label>
            <div className="flex gap-3">
              <button className="rounded-md px-4 py-2 border border-black bg-black text-white">
                RECRUITING
              </button>
              <button
                disabled={true}
                className="disabled:cursor-not-allowed rounded-md px-4 py-2 border border-black "
              >
                SALES LEAD
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="flex gap-3">
         
          <div className="flex-1 flex flex-col gap-1.5">
            <label className="text-gray-700">Ad Status</label>
            <select
              onChange={(e) =>
                setSelected({
                  ...selected,
                  ad_status: e.target.value,
                })
              }
              value={selected.ad_status}
              className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2  text-gray-800"
            >
              <option value="All">All</option>
              <option value="In Process">In Process</option>
              <option value="Upcoming">Upcoming</option>
              <option value="Completed">Completed</option>
            </select>
          </div>
        </div>
        <div className="flex-1 flex flex-col gap-1.5">
          <label className="text-gray-700">Ad Spend</label>
          <input
            type="text"
            value={state.ad_spend}
            onChange={(e) => setState({ ...state, ad_spend: e.target.value })}
            placeholder="$0.00"
            className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2  text-gray-800"
          />
        </div>
        <div className="flex-1 flex flex-col gap-1.5">
          <label className="text-gray-700">Meet Link</label>
          <input
            type="text"
            value={selected.meet_link}
            onChange={(e) =>
              setSelected({ ...selected, meet_link: e.target.value })
            }
            placeholder="Link"
            className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2  text-gray-800"
          />
        </div> */}

      <div
        className={classNames({
          hidden: step !== 2,
        })}
      >
        <h1 className="text-2xl mb-2 font-semibold text-zinc-800">
          Ad Details
        </h1>
        <div className="flex gap-3">
          <div className="flex-1 flex flex-col gap-1.5">
            <label className="text-gray-700">Season</label>
            <select
              onChange={handleChange}
              name="season"
              value={state.season}
              className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2  text-gray-800"
            >
              <option value="">Select Season</option>
              {SeasonList.map((item) => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>
          <div className="flex-1 flex flex-col gap-1.5">
            <label className="text-gray-700">Position</label>
            <select
              onChange={handleChange}
              name="position"
              value={state.position}
              className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2  text-gray-800"
            >
              <option value="">Select Position</option>
              {PositionList.map((item) => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="flex-1 flex flex-col gap-1.5 mt-2">
          <label className="text-gray-700">Ad Package</label>
          <div className="flex gap-3">
            {PackageList.map((item) => (
              <div
                key={item.day}
                onClick={() =>
                  onChangePackage(item.day, item.packageName, item.price)
                }
                className={classNames(
                  "flex-1 text-center border border-black cursor-pointer hover:bg-black hover:text-white flex flex-col  px-4 py-3 rounded-md",
                  {
                    "bg-black text-white":
                      item.packageName === state.package_name,
                  }
                )}
              >
                <span className="text-lg font-semibold">${item.price}</span>
                <span className="">{item.day} Days</span>
              </div>
            ))}
          </div>
        </div>
        <div className="flex gap-3 mt-3">
          <div className="flex-1 flex flex-col gap-1.5">
            <label className="text-gray-700">Start Date</label>
            <input
              type="date"
              min={new Date().toISOString().split("T")[0]}
              value={
                state?.package_start_date &&
                new Date(state.package_start_date)?.toISOString().split("T")[0]
              }
              onChange={(e) => handleChangeStart(e.target.value)}
              className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2  text-gray-800"
            />
          </div>
          <div className="flex-1 flex flex-col gap-1.5">
            <label className="text-gray-700">End Date</label>
            <input
              type="date"
              disabled={true}
              value={
                state?.package_end_date &&
                new Date(state.package_end_date)?.toISOString().split("T")[0]
              }
              onChange={(e) =>
                setState({ ...state, package_end_date: e.target.value })
              }
              className="rounded-md disabled:bg-gray-100 focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2  text-gray-800"
            />
          </div>
        </div>
      </div>
      <div
        className={classNames({
          hidden: step !== 3,
        })}
      >
        <h1 className="text-2xl  font-semibold text-zinc-800">Recruiting</h1>
        {state.recruiting_interview.map((item, index) => (
          <div key={item.block_name} className="border-b pb-5">
            <div className="flex items-center gap-3">
              <h1 className="my-3 text-lg font-medium text-zinc-800">
                Interview Session | Block {ALPHABETS[index + 1]}
              </h1>
              {index !== 0 && (
                <button
                  type="button"
                  onClick={() => removeInterview(index)}
                  className="text-red-600"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="h5 w-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                    />
                  </svg>
                </button>
              )}
            </div>
            <div className="grid grid-cols-2 gap-3">
              <div className="flex-1 col-span-2 flex flex-col gap-1.5">
                <label className="text-gray-700">Date</label>
                <input
                  type="date"
                  onChange={(e) =>
                    handleInterviewChange(index, "date", e.target.value)
                  }
                  value={selectedDate}
                  min={selectedDate}
                  max={minSelectableDate}
                  placeholder="Date"
                  className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2  text-gray-800"
                />
              </div>
              <div className="flex-1 flex flex-col gap-1.5">
                <label className="text-gray-700">Start Time</label>
                <input
                  type="time"
                  value={item.start_time}
                  onChange={(e) =>
                    handleInterviewChange(
                      index,
                      "start_time",
                      e.target.value
                    )
                  }
                  placeholder="Start Time"
                  className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2  text-gray-800"
                />
              </div>
              <div className="flex-1 flex flex-col gap-1.5">
                <label className="text-gray-700">End Time</label>
                <input
                  type="time"
                  onChange={(e) =>
                    handleInterviewChange(
                      index,
                      "end_time",
                      e.target.value
                    )
                  }
                  placeholder="End Time"
                  className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2  text-gray-800"
                />
              </div>
              <div className="flex-1 flex flex-col gap-1.5">
                <label className="text-gray-700">Timezone</label>
                <select
                  onChange={(e) =>
                    handleInterviewChange(index, "time_zone", e.target.value)
                  }
                  name="position"
                  value={item.time_zone}
                  className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2  text-gray-800"
                >
                  <option value="">Select Timezone</option>
                  {NEW_TIMEZONE.map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex-1 flex flex-col gap-1.5">
                <label className="text-gray-700">Zoom</label>
                <select
                  onChange={(e) =>
                    handleInterviewChange(
                      index,
                      "interview_type",
                      e.target.value
                    )
                  }
                  name="interview_type"
                  value={item.interview_type}
                  className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2  text-gray-800"
                >
                  <option value="">Select</option>
                  <option value="Zoom">Zoom</option>
                  <option value="In-Person">In-Person</option>
                </select>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="flex gap-2 items-center justify-between">
        {step === 3 ? (
          <button type="button" onClick={addInterview}>
            Add
          </button>
        ) : (
          <div />
        )}
        <div className="flex gap-2">
          {step !== 1 && (
            <button
              onClick={() => setStep((prev) => prev - 1)}
              type="button"
              className="bg-black px-4 py-2 rounded-md text-white"
            >
              Previous
            </button>
          )}
          <button
            onClick={handleNext}
            type="button"
            className="bg-black px-4 py-2 rounded-md text-white"
          >
            {step === 3 ? "Submit" : "Next"}
          </button>
        </div>
      </div>
    </form>
  );
};
