import React from "react";
import { Switch, Route } from "react-router-dom";
import { PublicRoute } from "./PublicRoute";
import { DashboardLayout } from "components/layouts/DashboardLayout";
import { ProtectedRoute } from "./ProtectedRoute";
import useAccount from "hooks/useUser";
import { LoginPage } from "pages/Login";
import { Reimbursement } from "pages/Reimubersement";
import { Categories } from "pages/Categories";
import { SpecificCategory } from "pages/SpecificCategory";
import { Opportunities } from "pages/Opportunities";
import { Tech } from "pages/offices/Tech";
import { Sales } from "pages/offices/Sales";
import { Leads } from "pages/Leads";
import { SignedReps } from "pages/SignedReps";
import { MediaAds } from "pages/MediaAds";
import { Candidates } from "pages/candidates/Candidates";
import { WeeklyTrainings } from "pages/trainings/WeeklyTrainings";
import { SpecificTraining } from "pages/trainings/SpecificTraining";
import { Blitz } from "pages/blitz/Blitz";
import { SpecificBlitz } from "pages/blitz/SpecificBlitz";
import { RecruitingTools } from "pages/RecruitingTools";
import { GeneralData } from "pages/GeneralData";
import { Contacts } from "pages/Contacts";
import { Giveaway } from "pages/GiveAway";
import { Feedbacks } from "pages/Feedbacks";
import { Users } from "pages/account/Users";
import { Settings } from "pages/Settings";
import { HomePage } from "pages/dashboard2/HomePage";
import { Stats } from "pages/dashboard2/Stats";
import { SpecificStats } from "pages/dashboard2/SpecificStats";
import { VivintLeads } from "pages/VivintLeads";
import { Production } from "pages/Production";
import { Licenses } from "pages/Licenses";
import { Otp } from "pages/Otp";
import { SolarLeadManagement } from "pages/SolarLeadManagement";
import { SolarDetails } from "pages/SolarDetails";
import { RecruiterLocations } from "pages/RecruiterLocations";
import { RepsAndReimbursement } from "pages/RepaAndReimubersement";
import { RegionalReimbursement } from "pages/RegionalReimbursement";
import { Ecards } from "pages/Ecards";
import { InfoSessions } from "pages/InfoSessions";
import { TrainingUsers } from "components/weekly/TrainingUsers";
import { BreezyData } from "pages/BreezyData";
import { ContactReports } from "pages/ContactReports";
import { WeeklySttats } from "pages/trainings/WeeklyStats";
import { BlackSparrow } from "pages/BlackSparrow";
import { SparrowReport } from "pages/SparrowReport";
import { BlitzStats } from "pages/blitz/BlitzStats";
import { BreezyStats } from "pages/BreezyStats";
import { RegisteredUsers } from "pages/blitz/RegisteredUsers";
import { SparrowReportLocation } from "pages/SparrowReportLocation";
import { BlackSparrowUsers } from "pages/BlackSparrowUsers";
import { ContactStates } from "pages/ContactStates";
import { ContactLeader } from "pages/ContactLeader";
import { SpecificMediaAd } from "pages/SpecificMediaAd";
import { MediaStats } from "pages/MediaStats";
import { AddMediaAd } from "pages/media-ads/AddMediaAd";
import { Dealership } from "pages/Dealership";
import { AdminifyCalendar } from "pages/AdminifyCalendar";

export const Routes: React.FC = () => {
  const { isLoggedIn } = useAccount();
  return (
    <Switch>
      <PublicRoute
        path="/login"
        authorized={isLoggedIn}
        component={LoginPage}
      />
      <Route
        path="/dashboard2"
        authorized={isLoggedIn}
        exact
        component={() => <HomePage />}
      />
      <Route
        path="/dashboard2/stats/:name"
        authorized={isLoggedIn}
        exact
        component={() => <SpecificStats />}
      />

      <Route
        path="/dashboard2/stats"
        authorized={isLoggedIn}
        exact
        component={() => <Stats />}
      />
      <Route path="/verify" exact component={() => <Otp />} />

      <DashboardLayout>
        <ProtectedRoute
          path="/"
          permission="reimbursement"
          authorized={isLoggedIn}
          component={Reimbursement}
        />
        <ProtectedRoute
          path="/reps-reimbursement"
          permission="reps & reimbursement"
          authorized={isLoggedIn}
          component={RepsAndReimbursement}
        />
        <ProtectedRoute
          path="/regional-reimbursement"
          permission="reps & reimbursement"
          authorized={isLoggedIn}
          component={RegionalReimbursement}
        />
        <ProtectedRoute
          path="/categories"
          authorized={isLoggedIn}
          component={Categories}
          permission="video"
        />
        <ProtectedRoute
          path="/adminify-calendar"
          authorized={isLoggedIn}
          component={AdminifyCalendar}
          permission="Adminicalendar"
        />
        <ProtectedRoute
          path="/contact/reports"
          authorized={isLoggedIn}
          component={ContactReports}
        />
         <ProtectedRoute
          path="/blitz-stats"
          authorized={isLoggedIn}
          component={BlitzStats}
        />
        <ProtectedRoute
          path="/weekly-training-stats"
          authorized={isLoggedIn}
          component={WeeklySttats}
        />
         <ProtectedRoute
          path="/registered-users"
          authorized={isLoggedIn}
          component={RegisteredUsers}
        />
         <ProtectedRoute
          path="/contact/states"
          authorized={isLoggedIn}
          component={ContactStates}
        />
         <ProtectedRoute
          path="/media-ad/:id"
          authorized={isLoggedIn}
          component={SpecificMediaAd}
        />
        <ProtectedRoute
          path="/contact/leader"
          authorized={isLoggedIn}
          component={ContactLeader}
        />
          <ProtectedRoute
          path="/dealership"
          authorized={isLoggedIn}
          component={Dealership}
        />
        <ProtectedRoute
          path="/black-sparrow"
          authorized={isLoggedIn}
          component={BlackSparrow}
        />
         <ProtectedRoute
          path="/media-stats"
          authorized={isLoggedIn}
          component={MediaStats}
        />
         <ProtectedRoute
          path="/add/media-ad"
          authorized={isLoggedIn}
          component={AddMediaAd}
        />
        <ProtectedRoute
          path="/breezy-stats"
          authorized={isLoggedIn}
          component={BreezyStats}
        />
         <ProtectedRoute
          path="/sparrow-report/:id"
          authorized={isLoggedIn}
          component={SparrowReport}
        />
          <ProtectedRoute
          path="/sparrow-report-location/:location"
          authorized={isLoggedIn}
          component={SparrowReportLocation}
        />
          <ProtectedRoute
          path="/sparrow-users"
          authorized={isLoggedIn}
          component={BlackSparrowUsers}
        />
        <ProtectedRoute
          path="/vivint-leads"
          authorized={isLoggedIn}
          component={VivintLeads}
        />
        <ProtectedRoute
          path="/production"
          authorized={isLoggedIn}
          component={Production}
        />
        <ProtectedRoute
          path="/category/:category"
          authorized={isLoggedIn}
          component={SpecificCategory}
        />
        <ProtectedRoute
          path="/opportunities"
          authorized={isLoggedIn}
          component={Opportunities}
          permission="opportunities"
        />
        <ProtectedRoute
          path="/offices/tech"
          authorized={isLoggedIn}
          component={Tech}
          permission="techoffice"
        />
        <ProtectedRoute
          path="/leads"
          authorized={isLoggedIn}
          component={Leads}
          permission="leads"
        />
        <ProtectedRoute
          path="/offices/sales"
          authorized={isLoggedIn}
          component={Sales}
          permission="salesoffice"
        />
        <ProtectedRoute
          path="/feedbacks"
          authorized={isLoggedIn}
          component={Feedbacks}
          permission="feedbacks"
        />
        <ProtectedRoute
          path="/reps"
          authorized={isLoggedIn}
          component={SignedReps}
          permission="reps"
        />
        <ProtectedRoute
          path="/media-ads"
          authorized={isLoggedIn}
          component={MediaAds}
          permission="ads"
        />
        <ProtectedRoute
          path="/giveway"
          authorized={isLoggedIn}
          component={Giveaway}
          permission="giveaway"
        />
        <ProtectedRoute
          path="/solar-lead-management"
          authorized={isLoggedIn}
          component={SolarLeadManagement}
          permission="solar-lead-management"
        />
        <ProtectedRoute
          path="/locations"
          authorized={isLoggedIn}
          component={RecruiterLocations}
          permission="locations"
        />
        <ProtectedRoute
          path="/solar-details/:id"
          component={SolarDetails}
          authorized={isLoggedIn}
          permission="solar-lead-management"
        />
        <ProtectedRoute
          path="/candidates"
          authorized={isLoggedIn}
          permission="candidates"
          component={Candidates}
        />
        <ProtectedRoute
          path="/weekly-trainings"
          authorized={isLoggedIn}
          permission="weekly"
          component={WeeklyTrainings}
        />
        <ProtectedRoute
          path="/weekly-trainings/:id"
          authorized={isLoggedIn}
          component={SpecificTraining}
        />
        <ProtectedRoute
          path="/blitz"
          authorized={isLoggedIn}
          component={Blitz}
          permission="blitz"
        />
        <ProtectedRoute
          path="/blitz/:id"
          authorized={isLoggedIn}
          component={SpecificBlitz}
        />
        <ProtectedRoute
          path="/recruiting-tools"
          authorized={isLoggedIn}
          permission="tools"
          component={RecruitingTools}
        />
        <ProtectedRoute
          path="/general-data"
          authorized={isLoggedIn}
          component={GeneralData}
          permission="general"
        />
        <ProtectedRoute
          path="/contacts"
          authorized={isLoggedIn}
          permission="conctacts"
          component={Contacts}
        />
        <ProtectedRoute
          path="/accounts"
          authorized={isLoggedIn}
          component={Users}
        />
        <ProtectedRoute
          path="/settings"
          authorized={isLoggedIn}
          component={Settings}
        />
        <ProtectedRoute
          path="/licenses"
          authorized={isLoggedIn}
          component={Licenses}
        />
        <ProtectedRoute
          path="/ecards"
          authorized={isLoggedIn}
          component={Ecards}
        />
         <ProtectedRoute
          path="/breezy"
          authorized={isLoggedIn}
          component={BreezyData}
        />
        <ProtectedRoute
          path="/info-sessions"
          authorized={isLoggedIn}
          component={InfoSessions}
        />

        <ProtectedRoute
          path="/training-users"
          authorized={isLoggedIn}
          component={TrainingUsers}
        />

        <Route
          path="/not-authorized"
          component={() => (
            <div>You are not authoized, please contact admin.</div>
          )}
        />
        <Route
          path="/coming"
          component={() => (
            <div className="min-h-screen flex flex-col items-center justify-center">
              <h1 className="text-5xl text-gray-900 font-bold mb-4 animate-pulse">
                Coming Soon
              </h1>
              <p className="text-gray-700 text-lg mb-8">
                We're working hard to bring you something amazing. Stay tuned!
              </p>
            </div>
          )}
        />
      </DashboardLayout>
    </Switch>
  );
};
