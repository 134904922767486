import { Table } from "common/Table/Table";
import { useRouter } from "hooks/useRouter";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import UserService from "services/user.service";
import { exportToCSV } from "utils/funcs";

const service = new UserService();

 const TAGS = [
  "YESTERDAY",
  "TODAY",
  "WEEKTODATE",
  "LASTWEEK",
  "LASTMONTH",
  "MONTHTODATE",
  "YEARTODATE",
  "CUSTOM",
];



export const ContactLeader = () => {
  const { push, query } = useRouter();


  const columns = [
       
      {key: "badge_id", dbColName: "badge_id", title: "badge id"},
      {key: "leader_name", dbColName: "leader_name", title: "leader name"},
    //   {key: "manage", dbColName: "_id", title: "management group leader id", render: row => row.management_group_leader_id},
        {key: "count", dbColName: "count", title: "count"},
  ];

  const [categories, setCategories] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({
    filterType: "Exclude Black Sparrow",
    date: "YEARTODATE",
  });
    const [startDate, setStartDate] = useState<any>(null);
      const [endDate, setEndDate] = useState<any>(null);
      const [showCustom, setShowCustom] = useState(false);
 
  useEffect(() => {
    setLoading(true);
    service
      .getContactLeader(filter)
      .then((resp) => {
        setCategories(resp.data.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [filter]);

   const handleChangeDates = (dates) => {
        const [start, end] = dates;
    
        setStartDate(start);
        setEndDate(end);
    
        if ((!start && !end) || end) {
          setFilter({
            ...filter,
            date: `${moment(start).format("YYYY-MM-DD")},${moment(end).format(
              "YYYY-MM-DD"
            )}`,
          });
        }
      };

  
  const handleExport = () => {
    exportToCSV(categories, "categories");
  };

  return (
    <Fragment>
      <div className="flex justify-between items-center mb-4">
        <div className="flex  gap-2">
          <button
            type="button"
            onClick={() => push("/contacts")}
            className="bg-white rounded-md p-2 border"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="h-6 w-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
              />
            </svg>
          </button>
          <div>
            <h1 className="font-medium text-gray-700 tracking-wide text-xl">
              Contact States
            </h1>
            <p className="text-gray-500 text-sm">
              View contact states with management
            </p>
          </div>
        </div>
      </div>
    

      <div className={` ${!query.tab ? "block" : "hidden"} `}>
        <Table
          data={categories}
          loadingData={loading}
          handleExport={handleExport}
          totalItems={categories.length}
          filters={
            <div className="flex gap-2">
              <select
                className="rounded-md focus:border-gray-300 font-light focus:outline-none w-60 focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
                value={filter.date}
                onChange={(e) => {
                    if (e.target.value !== "CUSTOM") {
                      setFilter({ ...filter, date: e.target.value });
                    } else {
                      setShowCustom(true);
                    }
                  }}
              >
                {TAGS.map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </select>
                {showCustom && (
                                    <DatePicker
                                      selected={startDate}
                                      placeholderText="Select range"
                                      onChange={handleChangeDates}
                                      startDate={startDate}
                                      endDate={endDate}
                                      isClearable
                                      className="z-100 ml-2 py-2 w-60"
                                      customInput={
                                        <input
                                          placeholder="Select date"
                                          className=" rounded-md border w-60 border-gray-outline outline-none focus-visible:shadow-none placeholder:text-sm focus:ring-0 focus:border-gray-200 py-2 text-sm text-zinc-600 pl-4 pr-4 disabled:bg-zinc-50 disabled:text-zinc-500 disabled:cursor-not-allowed text-dark-8 font-TTHoves;"
                                        />
                                      }
                                      selectsRange
                                      selectsDisabledDaysInRange
                                    />
                                  )}
              <select
              className="rounded-md focus:border-gray-300 font-light focus:outline-none w-60 focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
              value={filter.filterType}
              onChange={e =>  setFilter({ ...filter, filterType: e.target.value })}
              >
                <option value="All">All</option>
                <option value="Group Interview - Black Sparrow">Group Interview - Black Sparrow</option>
                <option value="Exclude Black Sparrow">Exclude Black Sparrow</option>
              </select>
             
            </div>
          }
          columns={columns}
        />
      </div>
    
    </Fragment>
  );
};
