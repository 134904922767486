import { Table } from "common/Table/Table";
import { useEffect, useState } from "react";
import UserService from "services/user.service";
import { exportToCSV } from "utils/funcs";

const service = new UserService();

export const CollegeCampuses = () => {
  const columns = [
    {
      key: "UNIT_ID",
      title: "UNIT ID",
      label: "UNIT ID",
      dbColName: "UNIT_ID",
      freeze: true,
      freezeIndex: 'left-0'
    },
    {
      key: "INSTITUTION_NAME",
      title: "INSTITUTION NAME",
      label: "INSTITUTION NAME",
      dbColName: "INSTITUTION_NAME",
      freeze: true,
      freezeIndex: 'left-20'
    },
    {
      key: "NAME_OF_CHIEF_ADMINISTRATOR",
      title: "NAME OF CHIEF ADMINISTRATOR",
      label: "NAME OF CHIEF ADMINISTRATOR",
      dbColName: "NAME_OF_CHIEF_ADMINISTRATOR",
    },
    {
      key: "TITLE_OF_CHIEF_ADMINISTRATOR",
      title: "TITLE OF CHIEF ADMINISTRATOR",
      label: "TITLE OF CHIEF ADMINISTRATOR",
      dbColName: "TITLE_OF_CHIEF_ADMINISTRATOR",
    },
    {
      key: "GENERAL_INFORMATION_TELEPHONE",
      title: "GENERAL INFORMATION TELEPHONE",
      label: "GENERAL INFORMATION TELEPHONE",
      dbColName: "GENERAL_INFORMATION_TELEPHONE",
    },
    {
      key: "OFFICE_OF_POSTSECONDARY_EDUCATION_OPEID",
      title: "OFFICE OF POSTSECONDARY EDUCATION_OPEID",
      label: "OFFICE OF POSTSECONDARY EDUCATION_OPEID",
      dbColName: "OFFICE_OF_POSTSECONDARY_EDUCATION_OPEID",
    },
    {
      key: "COLLEGE_ADDRESS",
      title: "COLLEGE ADDRESS",
      label: "COLLEGE ADDRESS",
      dbColName: "COLLEGE_ADDRESS",
    },
    {
      key: "COLLEGE_CITY",
      title: "COLLEGE CITY",
      label: "COLLEGE CITY",
      dbColName: "COLLEGE_CITY",
    },
    {
      key: "COLLEGE_STATE",
      title: "COLLEGE STATE",
      label: "COLLEGE STATE",
      dbColName: "COLLEGE_STATE",
    },
    {
      key: "COLLEGE_ZIP_CODE",
      title: "COLLEGE_ZIP_CODE",
      label: "COLLEGE_ZIP_CODE",
      dbColName: "COLLEGE_ZIP_CODE",
    },
    {
      key: "COLLEGE_FULL_ZIP_CODE",
      title: "COLLEGE FULL ZIP CODE",
      label: "COLLEGE FULL ZIP CODE",
      dbColName: "COLLEGE_FULL_ZIP_CODE",
    },
    {
      key: "WEBSITE_ADDRESS",
      title: "WEBSITE ADDRESS",
      label: "WEBSITE ADDRESS",
      dbColName: "WEBSITE_ADDRESS",
    },
  ];

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 100,
  });

  useEffect(() => {
    setLoading(true);
    service
      .getCollegeCampuses(pagination)
      .then((resp) => {
        setData(resp.data.data);
        setTotalItems(+resp.data.total_docs);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [pagination]);

  const handleExport = () => {
    exportToCSV(data, "colleges")
  }

  return (
    <Table
      data={data}
      loadingData={loading}
      handleExport={handleExport}
      setPagination={setPagination}
      pagination={pagination}
      totalItems={totalItems}
      columns={columns}
    />
  );
};
