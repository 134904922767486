import { Table } from "common/Table/Table";
import { useRouter } from "hooks/useRouter";
import { exportToCSV, formatDate } from "utils/funcs";
import { Fragment, useCallback, useEffect, useState } from "react";
import UserService from "services/user.service";
import { useSelector } from "react-redux";
import { userSelector } from "redux/selectors/user";

const service = new UserService();

export const Blitz = () => {
  const { push } = useRouter();
  const user = useSelector(userSelector);

  const columns = [
    // {
    //   key: "actions",
    //   title: "View",
    //   dbColName: "blitz_key",
    //   render: (key: string) => (
    //     <button
    //       type="button"
    //       onClick={() => push(`/blitz/${key}`)}
    //       className="px-4 py-1.5 bg-green-500 text-xs text-white rounded-md"
    //     >
    //       View
    //     </button>
    //   ),
    // },
    // {
    //   key: "BLITZ_KEY",
    //   title: "BLITZ KEY",
    //   label: "BLITZ KEY",
    //   dbColName: "BLITZ_KEY",
    // },

    {
      key: "first_name",
      title: "blitz creator name",
      render: (values) => `${values.first_name} ${values?.last_name}`,
      freeze: true,
      freezeIndex: "left-0",
    },
    { key: "badge_id", dbColName: "badge_id", title: "Blitz badge id" },
    {
      key: "date",
      dbColName: "createdAt",
      title: "Blitz Creation date",
      render: (date) => new Date(date).toLocaleDateString(),
    },
    { key: "sale_office", dbColName: "sale_office", title: "sales office" },
    { key: "phone_number", dbColName: "phone_number", title: "phone number" },
    { key: "tech_office", dbColName: "tech_office", title: "tech office" },
    { key: "state", dbColName: "state", title: "state (Location)" },
    { key: "zip_code", dbColName: "zip_code", title: "zip code (Location)" },
    {
      key: "month_of_blitz",
      dbColName: "month_of_blitz",
      title: "month of blitz",
    },
    {
      key: "start_date",
      dbColName: "start_date",
      title: "start date of blitz",
    },
    { key: "end_date", dbColName: "end_date", title: "end date of blitz" },
    // {key: "vets", dbColName: "vets", title: "vets"},
    { key: "rookies", dbColName: "rookies", title: "estimated total rookies" },
    { key: "rookies", dbColName: "vets", title: "estimated experience reps" },
    {
      key: "techs_needed",
      dbColName: "techs_needed",
      title: "number of techs needed",
    },
    // {key: "est_accounts", dbColName: "est_accounts", title: "est accounts"},
    {
      key: "request_solar_closer",
      dbColName: "request_solar_closer",
      title: "solar Requested",
    },
    {
      key: "action",
      dbColName: "_id",
      freeze: true,
      freezeIndex: "right-0",
      title: "action",
      render: (id) => {
        return (
          <div className="flex items-center  justify-center">
            <button
              type="button"
              onClick={() => push(`/blitz/${id}`)}
              className="text-blue-500"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="h-6 w-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                />
              </svg>
            </button>
            <div />
          </div>
        );
      },
    },
    // {key: "opt_in_for_bonus", dbColName: "opt_in_for_bonus", title: "opt in for bonus"},
    // {key: "senior_region", dbColName: "senior_region", title: "senior region"},
    // {key: "lead_tech_id", dbColName: "lead_tech_id", title: "lead tech id"},
    // {key: "lead_tech_name", dbColName: "lead_tech_name", title: "lead tech name"},
    // {key: "lead_tech_phone", dbColName: "lead_tech_phone", title: "lead tech phone"},
    // {key: "website_url", dbColName: "website_url", title: "website url", render: url => <a className="text-blue-500" href={url} target="_blank" rel="noreferrer">{url}</a>},
    // {key: "blitz_key", dbColName: "blitz_key", title: "blitz key"},
    // {key: "sales_year", dbColName: "sales_year", title: "sales year"},
    // {key: "userlist", dbColName: "userlist", title: "userlist"},
  ];

  const [data, setData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 100,
    name: "",
    state: "",
    startDate: "",
    endDate: "",
    blitzMonth: "",
  });

  const [exporting, setExporting] = useState(false);

  const fetchData = useCallback(() => {
    setLoading(true);
    service
      .getBlitz(pagination)
      .then((resp) => {
        // .map((item) => ({
        //   ...item,
        //   START_DATE: formatDate(item.START_DATE),
        //   END_DATE: formatDate(item.END_DATE),
        //   BLITZ_CREATION_DATE: formatDate(item.BLITZ_CREATION_DATE),
        // }))
        // .sort((a, b) =>
        //   a.BLITZ_CREATION_DATE.localeCompare(b.BLITZ_CREATION_DATE)
        // )
        setData(resp.data.data);
        setTotalItems(resp.data.total_docs);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [pagination]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleExport = () => {
    setExporting(true);

    service
      .downloadBlitz()
      .then((resp) => {
        exportToCSV(resp.data.data, "blitz");
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setExporting(false);
      });
  };

  return (
    <Fragment>
      <div className="flex flex-col mb-4">
        <h1 className="font-medium text-gray-700 tracking-wide text-xl">
          Blitz
        </h1>
        <p className="text-gray-500 text-sm">View all Blitz</p>
      </div>
      <Table
        data={data}
        pagination={pagination}
        setPagination={setPagination}
        loadingData={loading}
        setData={setData}
        handleExport={handleExport}
        totalItems={totalItems}
        columns={columns}
        filters={<BlitzUsers />}
        columnFilters={
          <ColumnFilters
            pagination={pagination}
            setPagination={setPagination}
          />
        }
      />
    </Fragment>
  );
};

const ColumnFilters = ({ pagination, setPagination }) => {
  return (
    <div className="flex gap-4 mt-3">
      <div className="flex-1">
        <label className="text-xs text-gray-700">Blitz Month</label>
        <select
          className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
          placeholder="Blitz Month"
          onChange={(e) => {
            setPagination({
              ...pagination,
              blitzMonth: e.target.value,
            });
          }}
        >
          <option value="" disabled selected>
            Select Month
          </option>
          <option value="January">January</option>
          <option value="February">February</option>
          <option value="March">March</option>
          <option value="April">April</option>
          <option value="May">May</option>
          <option value="June">June</option>
          <option value="July">July</option>
          <option value="August">August</option>
          <option value="September">September</option>
          <option value="October">October</option>
          <option value="November">November</option>
          <option value="December">December</option>
        </select>
      </div>
      <div className="flex-1">
        <label className="text-xs text-gray-700">Creator</label>
        <input
          className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
          type="text"
          placeholder="Search by creator"
          onChange={(e) => {
            setPagination({
              ...pagination,
              name: e.target.value,
            });
          }}
        />
      </div>

      <div className="flex-1">
        <label className="text-xs text-gray-700">Blitz Start Date</label>
        <input
          className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
          type="date"
          onChange={(e) =>
            setPagination({
              ...pagination,
              startDate: e.target.value,
            })
          }
        />
      </div>

      <div className="flex-1">
        <label className="text-xs text-gray-700">Blitz End Date</label>
        <input
          placeholder="End date"
          className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
          type="date"
          onChange={(e) => {
            setPagination({
              ...pagination,
              endDate: e.target.value,
            });
          }}
        />
      </div>
      <div className="flex-1">
        <label className="text-xs text-gray-700">State</label>
        <input
          placeholder="State"
          className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
          type="text"
          onChange={(e) => {
            setPagination({
              ...pagination,
              state: e.target.value,
            });
          }}
        />
      </div>
    </div>
  );
};


const BlitzUsers = () => {
  const [exporting, setExporting] = useState(false)

  const handleExport = () => {
    setExporting(true);

    service
      .downloadBlitzUsers()
      .then((resp) => {
        exportToCSV(resp.data.data, "blitz_users");
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setExporting(false);
      });
  };
  return (
    <button
    onClick={handleExport}
    disabled={exporting}
    className="bg-black disabled:bg-opacity-40 text-white flex items-center gap-2 p-2.5 rounded-md"
  >
    <span className="text-xs">
      {exporting ? "Exporting..." : "Export Blitz Users"}
    </span>
    {!exporting ? (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-4 h-4 "
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
        />
      </svg>
    ) : (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="icon icon-tabler icon-tabler-loader-3 animate-spin"
        width="18"
        height="18"
        viewBox="0 0 24 24"
        stroke-width="2"
        stroke="currentColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M3 12a9 9 0 0 0 9 9a9 9 0 0 0 9 -9a9 9 0 0 0 -9 -9" />
        <path d="M17 12a5 5 0 1 0 -5 5" />
      </svg>
    )}
  </button>
  )
}