import { Table } from "common/Table/Table";
import { TAGS } from "pages/BlackSparrow";
import { useEffect, useState } from "react";
import UserService from "services/user.service";
import { exportToCSV } from "utils/funcs";

const service = new UserService();

export const BlackSparrowLocation = () => {
  const [locationWise, setLocationWise] = useState<any>([]);
  const [loadingLocation, setLodingLocation] = useState(false);
  const [filter, setFilter] = useState({
    flag: "YES",
    date: "WEEKTODATE",
  })

  const locationColumns = [
    { key: "location", dbColName: "location", title: "location",
      freeze: true,
      freezeIndex: "left-0"
     },
    //  {key: "candidates", dbColName: "candidates", title: "candidates"},
    //  {key: "screening_completed", dbColName: "screening_completed", title: "screening_completed"},
    //  {key: "screening_approved", dbColName: "screening_approved", title: "screening_approved"},
    {
      key: "group_infosession",
      dbColName: "group_infosession",
      title: "Group Infosession Scheduled",
      render: row => <p className="text-center">{row}</p>
    },
    {key: "screening_completed", dbColName: "screening_completed", title: "screening completed", render: row => <p className="text-center">{row} </p>},
    {key: "screening_approved", dbColName: "screening_approved", title: "screening approved", render: row => <p className="text-center">{row} </p>},
    {
      key: "group_infosession_raffle",
      dbColName: "group_infosession_raffle",
      title: "Group Infosession Attended",
      render: row => <p className="text-center">{row}</p>
    },
    {
      key: "attende_offer",
      dbColName: "attende_offer",
      title: "attendee offer",
      render: row => <p className="text-center">{row}</p>
    },
    { key: "attende_hire", dbColName: "attende_hire", title: "attendee hire", 

      render: row => <p className="text-center">{row}</p>

     },
    { key: "signedReps", dbColName: "signedReps", title: "signedReps", 

      render: row => <p className="text-center">{row}</p>

     },
    {
      key: "contract_sent",
      dbColName: "contract_sent",
      title: "contract sent",
      render: row => <p className="text-center">{row}</p>
    },
    {
      key: "contract_signed",
      dbColName: "contract_signed",
      title: "contract signed",
      render: row => <p className="text-center">{row}</p>

    },
    {
      key: "last_street_genie_login",
      dbColName: "last_street_genie_login",
      title: "street Genie Login",
      render: row => <p className="text-center">{row}</p>
    },
    {
      key: "completed_training",
      dbColName: "completed_training",
      title: "completed training",
      render: row => <p className="text-center">{row}</p>
    },
    {
      key: "last_credit_run_date",
      dbColName: "last_credit_run_date",
      title: "ran credit",
      render: row => <p className="text-center">{row}</p>
    },
    {
      key: "last_install_date",
      dbColName: "last_install_date",
      title: "sales install",
      render: row => <p className="text-center">{row}</p>
    },
    { key: "onboarded_date", dbColName: "onboarded_date", title: "onboarded" },
    { key: "upgrades", dbColName: "upgrades", title: "upgrades" },
    {
      key: "register_for_blitz",
      dbColName: "register_for_blitz",
      title: "registered for blitz",
      render: row => <p className="text-center">{row}</p>

    },
    {
      key: "attended_blitz",
      dbColName: "attended_blitz",
      title: "attended blitz",
      render: row => <p className="text-center">{row}</p>

    },
    { key: "goals_set", dbColName: "goals_set", title: "goals set", 
      render: row => <p className="text-center">{row}</p>

     },
    // {
    //   key: "action",
    //   freeze: true,
    //   freezeIndex: "right-0",
    //   title: "action",
    //   dbColName: "location",
    //   render: (row) => {
    //     return (
    //       <button
    //         type="button"
    //         onClick={() => push(`/sparrow-report-location/${row}`)}
    //         className="text-blue-500"
    //       >
    //         <svg
    //           xmlns="http://www.w3.org/2000/svg"
    //           fill="none"
    //           viewBox="0 0 24 24"
    //           strokeWidth={1.5}
    //           stroke="currentColor"
    //           className="h-6 w-6"
    //         >
    //           <path
    //             strokeLinecap="round"
    //             strokeLinejoin="round"
    //             d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
    //           />
    //           <path
    //             strokeLinecap="round"
    //             strokeLinejoin="round"
    //             d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
    //           />
    //         </svg>
    //       </button>
    //     );
    //   },
    // },
  ];
  useEffect(() => {
    setLodingLocation(true);
    service
      .getBlackSparrowStatsLocations(filter)
      .then((stats) => {
        setLocationWise(stats.data.total_docs);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLodingLocation(false);
      });
  }, [filter]);


    const handleExport = () => {
      exportToCSV(locationWise, "reportingbylocation");
    }

  return (
    <Table
      data={locationWise}
      loadingData={loadingLocation}
      handleExport={handleExport}
      totalItems={locationWise.length}
      filters={
        <div className="flex gap-2">
          <select
            className="rounded-md focus:border-gray-300 font-light focus:outline-none w-60 focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
            value={filter.date}
            onChange={(e) => setFilter({ ...filter, date: e.target.value })}
          >
            {TAGS.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </select>
          <select
              className="rounded-md focus:border-gray-300 font-light focus:outline-none w-60 focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
              value={filter.flag}
              onChange={(e) => setFilter({ ...filter, flag: e.target.value })}
            >
              {/* <option value="">Select</option> */}
              <option value="NO">Master Date</option>
              <option value="YES">Cohort Date</option>
              {/* <option value="NO">No</option> */}
            </select>
        </div>
      }
      columns={locationColumns}
    />
  )
}
