import { Table } from "common/Table/Table";
import { useDebounce } from "hooks/useDebounce";
import moment from "moment";
import { TAGS } from "pages/BlackSparrow";
import { formatPhone } from "pages/Contacts";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import UserService from "services/user.service";
import { exportToCSV } from "utils/funcs";

const service = new UserService();

export const RsvpTable = () => {
  const columns = [
    { key: "first_name", dbColName: "first_name", title: "first name" },
    { key: "last_name", dbColName: "last_name", title: "last name" },
    {
      key: "phone",
      dbColName: "phone",
      title: "phone",
      render: (phone) => formatPhone(phone),
    },
    // { key: "tenure", dbColName: "tenure", title: "tenure" },
    { key: "status", dbColName: "status", title: "status" },

    // { key: "location", dbColName: "location", title: "location" },
    // { key: "meeting_type", dbColName: "meeting_type", title: "meeting type" },
    // {
    //   key: "date_submission",
    //   dbColName: "date_submission",
    //   title: "date submission",
    //   render: date => formatDate(date)
    // },
    // { key: "cell_phone", dbColName: "cell_phone", title: "cell phone" },
    // { key: "rep_tenure", dbColName: "rep_tenure", title: "rep tenure" },
    // {
    //   key: "date_attended",
    //   dbColName: "date_attended",
    //   title: "date attended",
    // },
    // { key: "meeting_id", dbColName: "meeting_id", title: "meeting id" },
    // { key: "week_number", dbColName: "week_number", title: "week number" },
    // {
    //   key: "attended_last_week",
    //   dbColName: "attended_last_week",
    //   title: "attended last week",
    // },
    // {
    //   key: "sales_year_week_number",
    //   dbColName: "sales_year_week_number",
    //   title: "sales year week number",
    // },
    // { key: "recruiter_id", dbColName: "recruiter_id", title: "recruiter_id" },
    // {
    //   key: "recruiter_name",
    //   dbColName: "recruiter_name",
    //   title: "recruiter_name",
    // },
    // {
    //   key: "recruiter_phone_number",
    //   dbColName: "recruiter_phone_number",
    //   title: "recruiter phone number",
    // },
    // {
    //   key: "recruiter_email",
    //   dbColName: "recruiter_email",
    //   title: "recruiter email",
    // },
    // {
    //   key: "team_leader_id",
    //   dbColName: "team_leader_id",
    //   title: "team leader id",
    // },
    // {
    //   key: "team_leader_name",
    //   dbColName: "team_leader_name",
    //   title: "team leader name",
    // },
    // {
    //   key: "team_leader_phone_number",
    //   dbColName: "team_leader_phone_number",
    //   title: "team leader phone number",
    // },
    // {
    //   key: "team_leader_email",
    //   dbColName: "team_leader_email",
    //   title: "team_leader_email",
    // },
    // {
    //   key: "management_group_leader_id",
    //   dbColName: "management_group_leader_id",
    //   title: "management group leader id",
    // },
    // {
    //   key: "management_group_leader_name",
    //   dbColName: "management_group_leader_name",
    //   title: "management_group_leader_name",
    // },
    // {
    //   key: "management_group_leader_phone_number",
    //   dbColName: "management_group_leader_phone_number",
    //   title: "management_group_leader_phone_number",
    // },
    // {
    //   key: "management_group_leader_email",
    //   dbColName: "management_group_leader_email",
    //   title: "management_group_leader_email",
    // },
    // {
    //   key: "senior_management_group_leader_id",
    //   dbColName: "senior_management_group_leader_id",
    //   title: "senior_management_group_leader_id",
    // },
    // {
    //   key: "senior_management_group_leader",
    //   dbColName: "senior_management_group_leader",
    //   title: "senior_management_group_leader",
    // },
    // {
    //   key: "senior_management_group_leader_phone_number",
    //   dbColName: "senior_management_group_leader_phone_number",
    //   title: "senior_management_group_leader_phone_number",
    // },
    // {
    //   key: "senior_management_group_leader_email",
    //   dbColName: "senior_management_group_leader_email",
    //   title: "senior_management_group_leader_email",
    // },
    // {
    //   key: "region_leader_id",
    //   dbColName: "region_leader_id",
    //   title: "region_leader_id",
    // },
    // {
    //   key: "region_leader_name",
    //   dbColName: "region_leader_name",
    //   title: "region_leader_name",
    // },
    // {
    //   key: "region_leader_phone_number",
    //   dbColName: "region_leader_phone_number",
    //   title: "region_leader_phone_number",
    // },
    // {
    //   key: "region_leader_email",
    //   dbColName: "region_leader_email",
    //   title: "region_leader_email",
    // },
    // {
    //   key: "senior_region_id",
    //   dbColName: "senior_region_id",
    //   title: "senior_region_id",
    // },
    // {
    //   key: "senior_region_name",
    //   dbColName: "senior_region_name",
    //   title: "senior_region_name",
    // },
    // {
    //   key: "senior_region_leader_phone_number",
    //   dbColName: "senior_region_leader_phone_number",
    //   title: "senior_region_leader_phone_number",
    // },
    // {
    //   key: "senior_region_leader_email",
    //   dbColName: "senior_region_leader_email",
    //   title: "senior_region_leader_email",
    // },
    // {
    //   key: "partner_leader_id",
    //   dbColName: "partner_leader_id",
    //   title: "partner_leader_id",
    // },
    // {
    //   key: "partner_leader_name",
    //   dbColName: "partner_leader_name",
    //   title: "partner_leader_name",
    // },
    // {
    //   key: "partner_leader_phone_number",
    //   dbColName: "partner_leader_phone_number",
    //   title: "partner_leader_phone_number",
    // },
    // {
    //   key: "division_leader_id",
    //   dbColName: "division_leader_id",
    //   title: "division_leader_id",
    // },
    // {
    //   key: "division_leader_name",
    //   dbColName: "division_leader_name",
    //   title: "division_leader_name",
    // },
    // {
    //   key: "division_leader_phone_number",
    //   dbColName: "division_leader_phone_number",
    //   title: "division_leader_phone_number",
    // },
    // {
    //   key: "contact_leader_id",
    //   dbColName: "contact_leader_id",
    //   title: "contact_leader_id",
    // },
    // {
    //   key: "contact_leader_name",
    //   dbColName: "contact_leader_name",
    //   title: "contact_leader_name",
    // },
    // {
    //   key: "contact_leader_phone_number",
    //   dbColName: "contact_leader_phone_number",
    //   title: "contact_leader_phone_number",
    // },
    // {
    //   key: "contact_leader_email",
    //   dbColName: "contact_leader_email",
    //   title: "contact_leader_email",
    // },
  ];

  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [exporting, setExporting] = useState(false);
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [showCustom, setShowCustom] = useState(false);

  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 100,
    isDownload: "false",
    fullName: "",
    status: "",
    date: "YEARTODATE",
  })

  const debounced = useDebounce(pagination, 1000);

  useEffect(() => {
    setLoading(true);
    service
      .getRsvp(debounced)
      .then((resp) => {
        if (resp.data.success) {
          setData(resp.data.rsvp);
          setTotalItems(resp.data.totalDocument);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
}, [debounced]);

  const handleExport = () => {
    setExporting(true);
    const pagin: any = { ...pagination,  isDownload: "true" };
    delete pagin.pageNumber;
    delete pagin.pageSize;
    service
      .getRsvp(pagin)
      .then((resp) => {
        exportToCSV(resp.data.rsvp, "rsvp");
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setExporting(false);
      })
  }

  const handleChangeDates = (dates) => {
    const [start, end] = dates;

    setStartDate(start);
    setEndDate(end);

    if ((!start && !end) || end) {
      setPagination({
        ...pagination,
        date: `${moment(start).format("YYYY-MM-DD")},${moment(end).format(
          "YYYY-MM-DD"
        )}`,
      });
    }
  };
  return (
    <Table
      data={data}
      loadingData={loading}
      pagination={pagination}
      exporting={exporting}
      handleExport={handleExport}
      setPagination={setPagination}
      setData={setData}
      totalItems={totalItems}
      columns={columns}
      showSearch={false}
      filters={
        <div className="flex gap-4 relative flex-1">

<div className="relative flex-1">
        <input
          type="search"
          placeholder="Full Name..."
          value={pagination.fullName}
          onChange={(e) => setPagination({...pagination, fullName: e.target.value })}
          className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
        />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-5 h-5 absolute text-gray-500 right-2 top-2.5"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
          />
        </svg>
      </div>
      <div className="flex-1">
            <select onChange={e => setPagination({...pagination, status: e.target.value})} className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800">
            <option value="">Select</option>
              <option value="Not-Attended">Not-Attended</option>
              <option value="RSVP">RSVP</option>
            </select>
          </div>
          <select
            value={pagination.date}
            onChange={(e) => {
              if (e.target.value !== "CUSTOM") {
                setPagination({ ...pagination, date: e.target.value });
              } else {
                setShowCustom(true);
              }
            }}
            className="rounded-md focus:border-gray-300 font-light focus:outline-none flex-1 focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
          >
            {TAGS.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </select>
          {showCustom && (
            <DatePicker
              selected={startDate}
              placeholderText="Select range"
              onChange={handleChangeDates}
              startDate={startDate}
              endDate={endDate}
              isClearable
              className="z-100 ml-2 py-2 w-60"
              customInput={
                <input
                  placeholder="Select date"
                  className=" rounded-md border w-60 border-gray-outline outline-none focus-visible:shadow-none placeholder:text-sm focus:ring-0 focus:border-gray-200 py-2 text-sm text-zinc-600 pl-4 pr-4 disabled:bg-zinc-50 disabled:text-zinc-500 disabled:cursor-not-allowed text-dark-8 font-TTHoves;"
                />
              }
              selectsRange
              selectsDisabledDaysInRange
            />
          )}
          
        </div>
      }
    />
  );
};

const ColumnFilters = ({ pagination, setPagination }) => {
  return (
    <div className="flex gap-4 mt-2">
      <div className="flex-1">
        <label className="text-xs text-gray-700">Start Date</label>
        <input
          className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
          type="date"
          value={pagination.startDate}
          onChange={(e) =>
            setPagination({
              ...pagination,
              startDate: e.target.value,
            })
          }
        />
      </div>

      <div className="flex-1">
        <label className="text-xs text-gray-700">End Date</label>
        <input
          placeholder="Start date"
          className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
          type="date"
          value={pagination.endDate}
          onChange={(e) => {
            setPagination({
              ...pagination,
              endDate: e.target.value,
            });
          }}
        />
      </div>
    </div>
  );
};
