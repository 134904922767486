import { Table } from "common/Table/Table"
import { exportToCSV } from "utils/funcs"

export const ManagerStats = ({ loading, data }) => {
  // 1️⃣ Define a month order map for sorting
  const monthOrder = {
    Jan: 1,
    Feb: 2,
    March: 3,
    April: 4,
    May: 5,
    June: 6,
    July: 7,
    Aug: 8,
    Sept: 9,
    Oct: 10,
    Nov: 11,
    Dec: 12,
  }


  const formatDataForExport = (apiData) => {
    // Define month order
    const monthOrder = [
      "Jan", "Feb", "March", "April", "May", "June",
      "July", "Aug", "Sept", "Oct", "Nov", "Dec"
    ];
  
    // Extract all unique month-year combinations from all records
    const allMonths = new Set();
    apiData.forEach(person => {
      person.months.forEach(({ month, year }) => {
        allMonths.add(`${month} ${year}`);
      });
    });
  
    // Sort months based on predefined order
    const sortedMonths = Array.from(allMonths).sort((a: any, b: any) => {
      const [monthA, yearA] = a.split(" ");
      const [monthB, yearB] = b.split(" ");
      return yearA - yearB || monthOrder.indexOf(monthA) - monthOrder.indexOf(monthB);
    });
  
    // Map data to required format
    const formattedData = apiData.map(person => {
      let row = {
        "Full Name": `${person.first_name} ${person.last_name}`,
        "Badge ID": person.rep_info.rep_id,
        "Region": person.rep_info.region,
        "Partnership": person.rep_info.partnership,
        "Phone": person.rep_info.phone,
      };
  
      // Initialize all months with 0
      sortedMonths.forEach((monthYear: any) => {
        row[monthYear] = 0;
      });
  
      // Fill actual totalAdAmount values
      person.months.forEach(({ month, year, totalAdAmount }) => {
        row[`${month} ${year}`] = totalAdAmount;
      });
  
      return {...row, 

        "Total": person.total,

      };
    });
  
    return formattedData;
  };

  // 2️⃣ Extract unique month-year values and sort them in correct order
  const uniqueMonths = Array.from(
    new Set(
      data.flatMap((person) =>
        person.months.map(({ month, year }) => `${month} ${year}`)
      )
    )
  ).sort((a: any, b: any) => {
    const [monthA, yearA] = a.split(" ");
    const [monthB, yearB] = b.split(" ");
    return yearA - yearB || monthOrder[monthA] - monthOrder[monthB]
  })

  // 3️⃣ Define base columns (static fields)
  const baseColumns = [
    {
      key: "first_name",
      title: "Full Name",
      render: ({ first_name, last_name }) => (
        <span>
          {first_name} {last_name}
        </span>
      ),
    },
    {
      key: "rep_id",
      title: "Badge ID",
      render: ({ rep_info }) => <span>{rep_info.rep_id}</span>,
    },
    {
      key: "region",
      title: "Region",
      render: ({ rep_info }) => <span>{rep_info.region}</span>,
    },
    {
      key: "partnership",
      title: "Partnership",
      render: ({ rep_info }) => <span>{rep_info.partnership}</span>,
    },
  ]

  // 4️⃣ Generate month-year columns dynamically (sorted order)
  const monthColumns = uniqueMonths.map((monthYear) => ({
    key: monthYear,
    title: monthYear,
    render: (data) => {
      const monthData = data.months.find(
        ({ month, year }) => `${month} ${year}` === monthYear
      )
      return <span> {monthData ? `$ ${monthData.totalAdAmount}` : "-"}</span>
    },
  }))

  // 5️⃣ Combine all columns
  const columns = [
    ...baseColumns,
    ...monthColumns,
    {
      key: "total",
      dbColName: "total",
      title: "total",
      freeze: true,
      freeIndex: "right-0",
      render: (total) => <span>$ {total}</span>,
    },
  ]

  const handleExport = () => {
    exportToCSV(formatDataForExport(data), "mediastats")
  }

  return <Table loadingData={loading} handleExport={handleExport} columns={columns} data={data} totalItems={data?.length} />;
}
