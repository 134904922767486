import { Disclosure, Transition } from "@headlessui/react";
import classNames from "classnames";
import { NavLink } from "common/NavLink";
import useAccount from "hooks/useUser";
import { Link } from "react-router-dom";
import { FC } from "react";
import { useLocation } from "react-router-dom";
import { logOut } from "redux/actions/user";
import { useThunkDispatch } from "hooks/useThunkDispatch";

export const Sidebar: FC = () => {
  const { pathname } = useLocation();

  return (
    <div className="flex md:flex-shrink-0 h-full ">
      {/*{!user && <SmallLoader />}*/}
      <div className="flex flex-col w-60 overflow-x-hidden">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex flex-col flex-grow drop-shadow-md bg-black overflow-y-auto">
          <div className="flex-grow flex flex-col">
            <div className="mt-6 ">
              <div className="w-48 mx-auto mb-3">
                <img
                  alt="logo"
                  src="/images/logo.png"
                  className="h-full w-full mx-auto object-contain"
                />
              </div>
            </div>
            <nav className="flex-1 mt-3 overflow-x-hidden">
              <AdminSidebar pathname={pathname} />
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};


const AdminSidebar = ({ pathname }) => {
  const { user } = useAccount()
  const dispatch = useThunkDispatch()

  console.log(user)

  return (
    <div className="flex flex-col justify-between h-full">
      <div>
        {/* {user?.admin === "admin" && (
          <NavLink
            active={pathname === "/accounts"}
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M18 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0ZM3 19.235v-.11a6.375 6.375 0 0 1 12.75 0v.109A12.318 12.318 0 0 1 9.374 21c-2.331 0-4.512-.645-6.374-1.766Z"
                />
              </svg>
            }
            label="Accounts"
            to="/accounts"
          />
        )} */}
        {/* {(user?.permissions?.includes("All") ||
          user?.permissions?.includes("blitz")) && (
          <NavLink
            active={pathname?.includes("/blitz")}
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 6.878V6a2.25 2.25 0 012.25-2.25h7.5A2.25 2.25 0 0118 6v.878m-12 0c.235-.083.487-.128.75-.128h10.5c.263 0 .515.045.75.128m-12 0A2.25 2.25 0 004.5 9v.878m13.5-3A2.25 2.25 0 0119.5 9v.878m0 0a2.246 2.246 0 00-.75-.128H5.25c-.263 0-.515.045-.75.128m15 0A2.25 2.25 0 0121 12v6a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 18v-6c0-.98.626-1.813 1.5-2.122"
                />
              </svg>
            }
            label="Blitz"
            to="/blitz"
          />
        )} */}

        {/*  Candidates Dropdown */}

        <Disclosure as="div">
          {({ open }) => (
            <>
              <Disclosure.Button className="w-[300px]">
                <div className="flex items-center hover:bg-gray-300 hover:bg-opacity-20 pl-7 py-1">
                  <div
                    className={classNames(
                      "flex text-sm items-center tracking-wide gap-x-3.5  text-white rounded-lg  py-3"
                    )}
                  >
                    {/* <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-10 h-6 justify-self-start text-white"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008z"
                      />
                    </svg> */}
                    <p className="flex-1 text-sm text-white w-full font-light rounded-lg ">
                      Candidates
                    </p>
                  </div>

                  <div className="w-full justify-slef-end">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className={`${
                        open ? "rotate-180 transform" : ""
                      } transition delay-100 w-full h-4 justify-self-start text-white`}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                      />
                    </svg>
                  </div>
                </div>
              </Disclosure.Button>
              <div className="overflow-hidden">
                <Transition
                  show={open}
                  as="div"
                  enter="transition ease-in-out duration-300 transform"
                  enterFrom="-translate-y-full"
                  enterTo="translate-y-0"
                  leave="transition ease-in-out duration-300 transform"
                  leaveFrom="translate-y-0"
                  leaveTo="-translate-y-full"
                >
                  <Disclosure.Panel className="pl-4 divide-y divide-gray-700 shadow-inner flex flex-col transition-all duration-700 bg-gray-800 text-white ">
                    {/* {(user?.permissions?.includes("All") ||
          user?.permissions?.includes("candidates")) && (
          <NavLink
            active={pathname === "/candidates"}
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"
                />
              </svg>
            }
            
          />
        )} */}
                    {/* {(user?.permissions?.includes("All") ||
                      user?.permissions?.includes("breezy")) && (
                      <Link
                        to="/breezy"
                        className="pl-8 hover:text-red-400 py-4 text-sm tracking-wide"
                      >
                        Breezy Data
                      </Link>
                    )} */}
                    {/* <Link
                      to="/candidates"
                      className="pl-8 hover:text-red-400 py-4 text-sm tracking-wide"
                    >
                      Candidates
                    </Link> */}
                    {(user?.permissions?.includes("ecards") ||
                      user?.permissions?.includes("All")) && (
                      <Link
                        to="/ecards"
                        className="pl-8 hover:text-red-400 py-4 text-sm tracking-wide"
                      >
                        Ecards & Interviews
                      </Link>
                    )}
                    {(user?.permissions?.includes("All") ||
                      user?.permissions?.includes("infoSession")) && (
                      <Link
                        to="/info-sessions"
                        className="pl-8 hover:text-red-400 py-4 text-sm tracking-wide"
                      >
                        Info Sessions
                      </Link>
                    )}
                    {(user?.permissions?.includes("All") ||
                      user?.permissions?.includes("blitz")) && (
                      <Link
                        to="/blitz"
                        className="pl-8 hover:text-red-400 py-4 text-sm tracking-wide"
                      >
                        Preseason Blitz
                      </Link>
                    )}
                    {(user?.permissions?.includes("All") ||
                      user?.permissions?.includes("candidates")) && (
                      <Link
                        to="/recruiting-tools"
                        className="pl-8 hover:text-red-400 py-4 text-sm tracking-wide"
                      >
                        Candidates
                      </Link>
                    )}
                    {(user?.permissions?.includes("All") ||
                      user?.permissions?.includes("reps")) && (
                      <Link
                        to="/reps"
                        className="pl-8 hover:text-red-400 py-4 text-sm tracking-wide"
                      >
                        Signed Reps
                      </Link>
                    )}

                    {(user?.permissions?.includes("All") ||
                      user?.permissions?.includes("ads")) && (
                      <Link
                        to="/weekly-trainings"
                        className="pl-8 hover:text-red-400 py-4 text-sm tracking-wide"
                      >
                        Weekly Training
                      </Link>
                    )}
                  </Disclosure.Panel>
                </Transition>
              </div>
            </>
          )}
        </Disclosure>

        {/* Candidates Dropdown */}

        {(user?.permissions?.includes("All") ||
          user?.permissions?.includes("conctacts")) && (
          <NavLink
            active={pathname?.includes("/contacts")}
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21 12a2.25 2.25 0 00-2.25-2.25H15a3 3 0 11-6 0H5.25A2.25 2.25 0 003 12m18 0v6a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 18v-6m18 0V9M3 12V9m18 0a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 9m18 0V6a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 6v3"
                />
              </svg>
            }
            label="Contacts"
            to="/contacts"
          />
        )}
        {(user?.permissions?.includes("All") ||
          user?.permissions?.includes("feedbacks")) && (
          <NavLink
            active={pathname === "/feedbacks"}
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.5 18.75h-9m9 0a3 3 0 0 1 3 3h-15a3 3 0 0 1 3-3m9 0v-3.375c0-.621-.503-1.125-1.125-1.125h-.871M7.5 18.75v-3.375c0-.621.504-1.125 1.125-1.125h.872m5.007 0H9.497m5.007 0a7.454 7.454 0 0 1-.982-3.172M9.497 14.25a7.454 7.454 0 0 0 .981-3.172M5.25 4.236c-.982.143-1.954.317-2.916.52A6.003 6.003 0 0 0 7.73 9.728M5.25 4.236V4.5c0 2.108.966 3.99 2.48 5.228M5.25 4.236V2.721C7.456 2.41 9.71 2.25 12 2.25c2.291 0 4.545.16 6.75.47v1.516M7.73 9.728a6.726 6.726 0 0 0 2.748 1.35m8.272-6.842V4.5c0 2.108-.966 3.99-2.48 5.228m2.48-5.492a46.32 46.32 0 0 1 2.916.52 6.003 6.003 0 0 1-5.395 4.972m0 0a6.726 6.726 0 0 1-2.749 1.35m0 0a6.772 6.772 0 0 1-3.044 0"
                />
              </svg>
            }
            label="Feedback"
            to="/feedbacks"
          />
        )} 
        {(user?.permissions?.includes("All") ||
          user?.permissions?.includes("giveaway")) && (
          <NavLink
            active={pathname === "/giveway"}
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                />
              </svg>
            }
            label="Giveaway Images"
            to="/giveway"
          />
        )}
        {(user?.permissions?.includes("All") ||
          user?.permissions?.includes("general")) && (
          <NavLink
            active={pathname?.includes("/general-data")}
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125"
                />
              </svg>
            }
            label="Campuses & Zip Codes"
            to="/general-data"
          />
        )}

        {(user?.permissions?.includes("All") ||
          user?.permissions?.includes("licenses")) && (
          <NavLink
            active={pathname === "/licenses"}
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="h-6 w-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Z"
                />
              </svg>
            }
            label="Licenses"
            to="/licenses"
          />
        )}
        {(user?.permissions?.includes("All") ||
          user?.permissions?.includes("ads")) && (
          <NavLink
            active={pathname === "/media-ads"}
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 01-.659 1.591l-5.432 5.432a2.25 2.25 0 00-.659 1.591v2.927a2.25 2.25 0 01-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 00-.659-1.591L3.659 7.409A2.25 2.25 0 013 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0112 3z"
                />
              </svg>
            }
            label="Media Ads"
            to="/media-ads"
          />
        )}
        {(user?.permissions?.includes("All") ||
          user?.permissions?.includes("salesoffice") ||
          user?.permissions?.includes("techoffice")) && (
          <Disclosure as="div">
            {({ open }) => (
              <>
                <Disclosure.Button className="w-[340px]">
                  <div className="flex items-center hover:bg-gray-300 hover:bg-opacity-20 pl-6  py-1">
                    <div
                      className={classNames(
                        "flex text-sm items-center tracking-wide gap-x-3.5  text-white rounded-lg  py-3"
                      )}
                    >
                      {/* <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-10 h-6 justify-self-start text-white"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008z"
                        />
                      </svg> */}
                      <p className="flex-1 text-sm pl-1 text-white w-full font-light rounded-lg ">
                        Offices
                      </p>
                    </div>

                    <div className="w-full justify-slef-end">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } transition delay-100 w-full h-4 justify-self-start text-white`}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                        />
                      </svg>
                    </div>
                  </div>
                </Disclosure.Button>
                <div className="overflow-hidden">
                  <Transition
                    show={open}
                    as="div"
                    enter="transition ease-in-out duration-300 transform"
                    enterFrom="-translate-y-full"
                    enterTo="translate-y-0"
                    leave="transition ease-in-out duration-300 transform"
                    leaveFrom="translate-y-0"
                    leaveTo="-translate-y-full"
                  >
                    <Disclosure.Panel className="pl-4 divide-y divide-gray-700 shadow-inner flex flex-col transition-all duration-700 bg-gray-800 text-white ">
                      {(user?.permissions?.includes("All") ||
                        user?.permissions?.includes("salesoffice")) && (
                        <Link
                          to="/offices/sales"
                          className="pl-8 hover:text-red-400 py-4 text-sm tracking-wide"
                        >
                          Sales
                        </Link>
                      )}
                      {(user?.permissions?.includes("All") ||
                        user?.permissions?.includes("techoffice")) && (
                        <Link
                          to="/offices/tech"
                          className="pl-8 hover:text-red-400 py-4 text-sm tracking-wide"
                        >
                          Tech
                        </Link>
                      )}
                      {(user?.permissions?.includes("All") ||
                        user?.permissions?.includes("locations")) && (
                        <Link
                          to="/locations"
                          className="pl-8 hover:text-red-400 py-4 text-sm tracking-wide"
                        >
                          Recuriting Offices
                        </Link>
                      )}
                    </Disclosure.Panel>
                  </Transition>
                </div>
              </>
            )}
          </Disclosure>
        )}
        {/* {(user?.permissions?.includes("All") ||
          user?.permissions?.includes("opportunities")) && (
          <NavLink
            active={pathname === "/opportunities"}
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21 7.5l-9-5.25L3 7.5m18 0l-9 5.25m9-5.25v9l-9 5.25M3 7.5l9 5.25M3 7.5v9l9 5.25m0-9v9"
                />
              </svg>
            }
            label="Opportunities"
            to="/opportunities"
          />
        )} */}

        {/* <NavLink
            active={pathname === "/black-sparrow"}
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21 7.5l-9-5.25L3 7.5m18 0l-9 5.25m9-5.25v9l-9 5.25M3 7.5l9 5.25M3 7.5v9l9 5.25m0-9v9"
                />
              </svg>
            }
            label="Black Sparrow Report"
            to="/black-sparrow"
          /> */}
        {/* {user?.permissions?.includes("production") && (
          <NavLink
            active={pathname === "/production"}
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6 justify-self-start text-white"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008z"
                />
              </svg>
            }
            label="Production"
            to="/production"
          />
        )} */}

        {(user?.permissions?.includes("All") ||
          user?.permissions?.includes("reimbursement") ||
          user?.permissions?.includes("reps & reimbursement")) && (
          <Disclosure as="div">
            {({ open }) => (
              <>
                <Disclosure.Button className="w-[270px]">
                  <div className="flex items-center hover:bg-gray-300 hover:bg-opacity-20 pl-4  py-1">
                    <div
                      className={classNames(
                        "flex text-sm items-center tracking-wide gap-x-3.5  text-white rounded-lg  py-3"
                      )}
                    >
                      {/* <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M3.75 6A2.25 2.25 0 016 3.75h2.25A2.25 2.25 0 0110.5 6v2.25a2.25 2.25 0 01-2.25 2.25H6a2.25 2.25 0 01-2.25-2.25V6zM3.75 15.75A2.25 2.25 0 016 13.5h2.25a2.25 2.25 0 012.25 2.25V18a2.25 2.25 0 01-2.25 2.25H6A2.25 2.25 0 013.75 18v-2.25zM13.5 6a2.25 2.25 0 012.25-2.25H18A2.25 2.25 0 0120.25 6v2.25A2.25 2.25 0 0118 10.5h-2.25a2.25 2.25 0 01-2.25-2.25V6zM13.5 15.75a2.25 2.25 0 012.25-2.25H18a2.25 2.25 0 012.25 2.25V18A2.25 2.25 0 0118 20.25h-2.25A2.25 2.25 0 0113.5 18v-2.25z"
                        />
                      </svg> */}
                      <p className="flex-1 text-sm pl-3 text-white w-full font-light rounded-lg ">
                        Reimbursements
                      </p>
                    </div>

                    <div className="w-full justify-slef-end">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } transition delay-100 w-full h-4 justify-self-start text-white`}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                        />
                      </svg>
                    </div>
                  </div>
                </Disclosure.Button>
                <div className="overflow-hidden">
                  <Transition
                    show={open}
                    as="div"
                    enter="transition ease-in-out duration-300 transform"
                    enterFrom="-translate-y-full"
                    enterTo="translate-y-0"
                    leave="transition ease-in-out duration-300 transform"
                    leaveFrom="translate-y-0"
                    leaveTo="-translate-y-full"
                  >
                    <Disclosure.Panel className="pl-4 divide-y divide-gray-700 shadow-inner flex flex-col transition-all duration-700 bg-gray-800 text-white ">
                      {(user?.permissions?.includes("All") ||
                        user?.permissions?.includes("reimbursement")) && (
                        <Link
                          to="/"
                          className="pl-8 hover:text-red-400 py-4 text-sm tracking-wide"
                        >
                          General Reimbursement
                        </Link>
                      )}

                      <Link
                        to="/reps-reimbursement"
                        className="pl-8 hover:text-red-400 py-4 text-sm tracking-wide"
                      >
                        Rep Reimbursement
                      </Link>
                      <Link
                        to="/regional-reimbursement"
                        className="pl-8 hover:text-red-400 py-4 text-sm tracking-wide"
                      >
                        Regional Reimbursement
                      </Link>
                    </Disclosure.Panel>
                  </Transition>
                </div>
              </>
            )}
          </Disclosure>
        )}

        {/* {(user?.permissions?.includes("All") ||
          user?.permissions?.includes("tools")) && (
          <NavLink
            active={pathname?.includes("/recruiting-tools")}
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M11.42 15.17L17.25 21A2.652 2.652 0 0021 17.25l-5.877-5.877M11.42 15.17l2.496-3.03c.317-.384.74-.626 1.208-.766M11.42 15.17l-4.655 5.653a2.548 2.548 0 11-3.586-3.586l6.837-5.63m5.108-.233c.55-.164 1.163-.188 1.743-.14a4.5 4.5 0 004.486-6.336l-3.276 3.277a3.004 3.004 0 01-2.25-2.25l3.276-3.276a4.5 4.5 0 00-6.336 4.486c.091 1.076-.071 2.264-.904 2.95l-.102.085m-1.745 1.437L5.909 7.5H4.5L2.25 3.75l1.5-1.5L7.5 4.5v1.409l4.26 4.26m-1.745 1.437l1.745-1.437m6.615 8.206L15.75 15.75M4.867 19.125h.008v.008h-.008v-.008z"
                />
              </svg>
            }
            label="Recruiting Tools"
            to="/recruiting-tools"
          />
        )} */}
        {/* {(user?.permissions?.includes("All") ||
          user?.permissions?.includes("locations")) && (
          <NavLink
            active={pathname === "/locations"}
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
                />
              </svg>
            }
            label="Recuriter Center Location"
            to="/locations"
          />
        )} */}

        <Disclosure as="div">
          {({ open }) => (
            <>
              <Disclosure.Button className="w-[350px]">
                <div className="flex items-center hover:bg-gray-300 hover:bg-opacity-20 pl-4  py-1">
                  <div
                    className={classNames(
                      "flex text-sm items-center tracking-wide gap-x-3.5  text-white rounded-lg  py-3"
                    )}
                  >
                    {/* <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3.75 6A2.25 2.25 0 016 3.75h2.25A2.25 2.25 0 0110.5 6v2.25a2.25 2.25 0 01-2.25 2.25H6a2.25 2.25 0 01-2.25-2.25V6zM3.75 15.75A2.25 2.25 0 016 13.5h2.25a2.25 2.25 0 012.25 2.25V18a2.25 2.25 0 01-2.25 2.25H6A2.25 2.25 0 013.75 18v-2.25zM13.5 6a2.25 2.25 0 012.25-2.25H18A2.25 2.25 0 0120.25 6v2.25A2.25 2.25 0 0118 10.5h-2.25a2.25 2.25 0 01-2.25-2.25V6zM13.5 15.75a2.25 2.25 0 012.25-2.25H18a2.25 2.25 0 012.25 2.25V18A2.25 2.25 0 0118 20.25h-2.25A2.25 2.25 0 0113.5 18v-2.25z"
                      />
                    </svg> */}
                    <p className="flex-1 text-sm pl-3 text-white w-full font-light rounded-lg ">
                      Solar
                    </p>
                  </div>

                  <div className="w-full justify-slef-end">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className={`${
                        open ? "rotate-180 transform" : ""
                      } transition delay-100 w-full h-4 justify-self-start text-white`}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                      />
                    </svg>
                  </div>
                </div>
              </Disclosure.Button>
              <div className="overflow-hidden">
                <Transition
                  show={open}
                  as="div"
                  enter="transition ease-in-out duration-300 transform"
                  enterFrom="-translate-y-full"
                  enterTo="translate-y-0"
                  leave="transition ease-in-out duration-300 transform"
                  leaveFrom="translate-y-0"
                  leaveTo="-translate-y-full"
                >
                  <Disclosure.Panel className="pl-4 divide-y divide-gray-700 shadow-inner flex flex-col transition-all duration-700 bg-gray-800 text-white ">
                    {(user?.permissions?.includes("All") ||
                      user?.permissions?.includes("reimbursement")) && (
                      <Link
                        target="_blank"
                        to="/dashboard2"
                        className="pl-8 hover:text-red-400 py-4 text-sm tracking-wide"
                      >
                        Solar Dashboard
                      </Link>
                    )}

                    <Link
                      to="/leads"
                      className="pl-8 hover:text-red-400 py-4 text-sm tracking-wide"
                    >
                      App Leads
                    </Link>
                    <Link
                      to="/solar-lead-management"
                      className="pl-8 hover:text-red-400 py-4 text-sm tracking-wide"
                    >
                      Solar Offices
                    </Link>

                    <Link
                      to="/vivint-leads"
                      className="pl-8 hover:text-red-400 py-4 text-sm tracking-wide"
                    >
                      Form Leads
                    </Link>
                    {/* {(user?.permissions?.includes("All") ||
          user?.permissions?.includes("vivintLeads")) && (
          <NavLink
            active={pathname === "/vivint-leads"}
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 10.5v6m3-3H9m4.06-7.19-2.12-2.12a1.5 1.5 0 0 0-1.061-.44H4.5A2.25 2.25 0 0 0 2.25 6v12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9a2.25 2.25 0 0 0-2.25-2.25h-5.379a1.5 1.5 0 0 1-1.06-.44Z"
                />
              </svg>
            }
            label="Form Leads"
            to="/vivint-leads"
          />
        )} */}
                  </Disclosure.Panel>
                </Transition>
              </div>
            </>
          )}
        </Disclosure>

        {/* {(user?.permissions?.includes("All") ||
          user?.permissions?.includes("solar")) && (
          <NavLink
            active={pathname === "/dashboard2"}
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.75 6A2.25 2.25 0 016 3.75h2.25A2.25 2.25 0 0110.5 6v2.25a2.25 2.25 0 01-2.25 2.25H6a2.25 2.25 0 01-2.25-2.25V6zM3.75 15.75A2.25 2.25 0 016 13.5h2.25a2.25 2.25 0 012.25 2.25V18a2.25 2.25 0 01-2.25 2.25H6A2.25 2.25 0 013.75 18v-2.25zM13.5 6a2.25 2.25 0 012.25-2.25H18A2.25 2.25 0 0120.25 6v2.25A2.25 2.25 0 0118 10.5h-2.25a2.25 2.25 0 01-2.25-2.25V6zM13.5 15.75a2.25 2.25 0 012.25-2.25H18a2.25 2.25 0 012.25 2.25V18A2.25 2.25 0 0118 20.25h-2.25A2.25 2.25 0 0113.5 18v-2.25z"
                />
              </svg>
            }
            target="_blank"
            label="Solar Dashboard"
            to="/dashboard2"
          />
        )} */}
        {/* {(user?.permissions?.includes("All") ||
          user?.permissions?.includes("leads")) && (
          <NavLink
            active={pathname === "/leads"}
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M2.25 7.125C2.25 6.504 2.754 6 3.375 6h6c.621 0 1.125.504 1.125 1.125v3.75c0 .621-.504 1.125-1.125 1.125h-6a1.125 1.125 0 01-1.125-1.125v-3.75zM14.25 8.625c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v8.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 01-1.125-1.125v-8.25zM3.75 16.125c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v2.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 01-1.125-1.125v-2.25z"
                />
              </svg>
            }
            label="Solar Leads"
            to="/leads"
          />
        )} */}

        {/* {(user?.permissions?.includes("All") ||
          user?.permissions?.includes("solarLead")) && (
          <NavLink
            active={
              pathname === "/solar-lead-management" ||
              pathname?.includes("solar-details")
            }
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.75 6A2.25 2.25 0 016 3.75h2.25A2.25 2.25 0 0110.5 6v2.25a2.25 2.25 0 01-2.25 2.25H6a2.25 2.25 0 01-2.25-2.25V6zM3.75 15.75A2.25 2.25 0 016 13.5h2.25a2.25 2.25 0 012.25 2.25V18a2.25 2.25 0 01-2.25 2.25H6A2.25 2.25 0 013.75 18v-2.25zM13.5 6a2.25 2.25 0 012.25-2.25H18A2.25 2.25 0 0120.25 6v2.25A2.25 2.25 0 0118 10.5h-2.25a2.25 2.25 0 01-2.25-2.25V6zM13.5 15.75a2.25 2.25 0 012.25-2.25H18a2.25 2.25 0 012.25 2.25V18A2.25 2.25 0 0118 20.25h-2.25A2.25 2.25 0 0113.5 18v-2.25z"
                />
              </svg>
            }
            label="Solar Lead Mgt"
            to="/solar-lead-management"
          />
        )} */}

        {(user?.permissions?.includes("All") ||
          user?.permissions?.includes("video")) && (
          <NavLink
            active={
              pathname === "/categories" || pathname?.includes("category")
            }
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.375 19.5h17.25m-17.25 0a1.125 1.125 0 01-1.125-1.125M3.375 19.5h1.5C5.496 19.5 6 18.996 6 18.375m-3.75 0V5.625m0 12.75v-1.5c0-.621.504-1.125 1.125-1.125m18.375 2.625V5.625m0 12.75c0 .621-.504 1.125-1.125 1.125m1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125m0 3.75h-1.5A1.125 1.125 0 0118 18.375M20.625 4.5H3.375m17.25 0c.621 0 1.125.504 1.125 1.125M20.625 4.5h-1.5C18.504 4.5 18 5.004 18 5.625m3.75 0v1.5c0 .621-.504 1.125-1.125 1.125M3.375 4.5c-.621 0-1.125.504-1.125 1.125M3.375 4.5h1.5C5.496 4.5 6 5.004 6 5.625m-3.75 0v1.5c0 .621.504 1.125 1.125 1.125m0 0h1.5m-1.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125m1.5-3.75C5.496 8.25 6 7.746 6 7.125v-1.5M4.875 8.25C5.496 8.25 6 8.754 6 9.375v1.5m0-5.25v5.25m0-5.25C6 5.004 6.504 4.5 7.125 4.5h9.75c.621 0 1.125.504 1.125 1.125m1.125 2.625h1.5m-1.5 0A1.125 1.125 0 0118 7.125v-1.5m1.125 2.625c-.621 0-1.125.504-1.125 1.125v1.5m2.625-2.625c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125M18 5.625v5.25M7.125 12h9.75m-9.75 0A1.125 1.125 0 016 10.875M7.125 12C6.504 12 6 12.504 6 13.125m0-2.25C6 11.496 5.496 12 4.875 12M18 10.875c0 .621-.504 1.125-1.125 1.125M18 10.875c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125m-12 5.25v-5.25m0 5.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125m-12 0v-1.5c0-.621-.504-1.125-1.125-1.125M18 18.375v-5.25m0 5.25v-1.5c0-.621.504-1.125 1.125-1.125M18 13.125v1.5c0 .621.504 1.125 1.125 1.125M18 13.125c0-.621.504-1.125 1.125-1.125M6 13.125v1.5c0 .621-.504 1.125-1.125 1.125M6 13.125C6 12.504 5.496 12 4.875 12m-1.5 0h1.5m-1.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125M19.125 12h1.5m0 0c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h1.5m14.25 0h1.5"
                />
              </svg>
            }
            label="Video Categories"
            to="/categories"
          />
        )}

        {/* {(user?.permissions?.includes("All") ||
          user?.permissions?.includes("weekly")) && (
          <NavLink
            active={pathname?.includes("/weekly-trainings")}
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M7.5 14.25v2.25m3-4.5v4.5m3-6.75v6.75m3-9v9M6 20.25h12A2.25 2.25 0 0020.25 18V6A2.25 2.25 0 0018 3.75H6A2.25 2.25 0 003.75 6v12A2.25 2.25 0 006 20.25z"
                />
              </svg>
            }
            label="Weekly Trainings"
            to="/weekly-trainings"
          />
        )} */}
      </div>
      <div className="p-2">
        <div className="border-t w-full border-gray-500 flex justify-between items-center gap-3 py-5 ">
          {/* <div className="rounded-full flex-shrink-0  overflow-hidden h-8 w-8 bg-gray-400"> */}
          {/* <img
              alt="illustration"
              className="h-full w-full rounded-full object-cover"
              src="/images/illustration.png"
            /> */}
          {/* </div> */}
          <div className="flex flex-col w-44 ">
            <div className="flex items-center gap-1">
              <p className="text-white text-sm font-medium">{user?.fullname}</p>
              <Link to="/settings">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-5 h-5 text-white"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.325.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 0 1 1.37.49l1.296 2.247a1.125 1.125 0 0 1-.26 1.431l-1.003.827c-.293.241-.438.613-.43.992a7.723 7.723 0 0 1 0 .255c-.008.378.137.75.43.991l1.004.827c.424.35.534.955.26 1.43l-1.298 2.247a1.125 1.125 0 0 1-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.47 6.47 0 0 1-.22.128c-.331.183-.581.495-.644.869l-.213 1.281c-.09.543-.56.94-1.11.94h-2.594c-.55 0-1.019-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 0 1-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 0 1-1.369-.49l-1.297-2.247a1.125 1.125 0 0 1 .26-1.431l1.004-.827c.292-.24.437-.613.43-.991a6.932 6.932 0 0 1 0-.255c.007-.38-.138-.751-.43-.992l-1.004-.827a1.125 1.125 0 0 1-.26-1.43l1.297-2.247a1.125 1.125 0 0 1 1.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.086.22-.128.332-.183.582-.495.644-.869l.214-1.28Z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                </svg>
              </Link>
            </div>
            <p className="text-gray-300 text-xs">{user?.email}</p>
          </div>
          <button type="button" onClick={() => dispatch(logOut())}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon text-gray-200  icon-tabler icon-tabler-logout"
              width="26"
              height="26"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2" />
              <path d="M9 12h12l-3 -3" />
              <path d="M18 15l3 -3" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};
