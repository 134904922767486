import swal from "sweetalert";
import { Modal } from "common/Modal";
import { Table } from "common/Table/Table";
import { useNotifications } from "hooks/useNotification";
import { Fragment, SyntheticEvent, useCallback, useEffect, useState } from "react";
import UserService from "services/user.service";
import { exportToCSV, formatDate } from "utils/funcs";

const service = new UserService();

export const Dealership = () => {
  const columns = [
    { key: "badge_id", dbColName: "badge_id", title: "badge id" },
    { key: "fullname", dbColName: "fullname", title: "full name" },
    { key: "phone_number", dbColName: "phone_number", title: "phone number" },
    { key: "email", dbColName: "email", title: "email" },
    // {key: "scholarship_link", dbColName: "scholarship_link", title: "scholarship link"},
    {
      key: "createdAt",
      dbColName: "createdAt",
      title: "created At",
      render: (date) => formatDate(date),
    },
    {
        key: "action",
        freeze: true,
        freezeIndex: "right-0",
        title: "action",
        render: ({ badge_id }) => {
          return (
            <button
              type="button"
              className="text-red-500 text-right"
              onClick={() => handleDelete(badge_id)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="h-6 w-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                />
              </svg>
            </button>
          );
        },
      },
  ];

  const [state, setState] = useState({
    first_name: "",
    last_name: "",
    phone_number: "",
    email: "",
  });
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const { errorMessage, successMessage } = useNotifications();

  const fetchData = useCallback(() => {
    setLoading(true);
    service
      .getDealership()
      .then((resp) => {
        setData(resp.data.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [])

  useEffect(() => {
    fetchData()
  }, []);

  const handleDelete = (id: string) => {
      swal({
        title: "Are you sure, you want to delete this user?",
        icon: "warning",
        className: "w-[500px]",
        buttons: {
          cancel: {
            text: "Cancel",
            visible: true,
          },
          confirm: {
            text: "Delete",
            className: "bg-primary text-white",
          },
        },
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          setLoading(true);
          service.deleteDealership(id).then((resp) => {
              successMessage("User deleted successfully");
            //   const shallowCopy = [...data];
            //   const newData = shallowCopy.filter(
            //     (user) => user.badge_id !== id
            //   );
            //   setData(newData);
          }).catch((err) => {
            console.log(err)
          }).finally(() => {
            setLoading(false)
          }) 
        }
      });
    };

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();

    const isValidForm = Object.values(state).every((value) => value);

    if (isValidForm) {
      service
        .addDealership(state)
        .then((resp) => {
          fetchData()
          successMessage("User added successfully");
          setState({
            first_name: "",
            last_name: "",
            phone_number: "",
            email: "",
          })
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      errorMessage("Please fill al fields");
    }
  };

  const handleExport = () => {
    exportToCSV(data, "dealership");
  };

  return (
    <Fragment>
      <div className="flex justify-between items-center mb-4">
        <div>
          <h1 className="font-medium text-gray-700 tracking-wide text-xl">
            Dealership
          </h1>
          <p className="text-gray-500 text-sm">View all </p>
        </div>
        <button
          type="button"
          onClick={() => setShowModal(true)}
          className="bg-black text-white rounded-md px-4 py-2.5 gap-2 text-sm flex items-center"
        >
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 6v12m6-6H6"
              />
            </svg>
          </span>
          Add User
        </button>
      </div>

      <Table
        data={data}
        loadingData={loading}
        handleExport={handleExport}
        totalItems={data.length}
        exportButton={false}
        columns={columns}
      />
      <Modal
        width="w-full md:w-1/3"
        showModal={showModal}
        setShowModal={setShowModal}
        title="Add New User"
      >
        <form onSubmit={handleSubmit} className="flex flex-col gap-4">
          <input
            type="text"
            placeholder="First Name"
            value={state.first_name}
            onChange={(e) => setState({ ...state, first_name: e.target.value })}
            className="rounded-md focus:border-gray-400 focus:outline-none w-full focus:ring-0 border-gray-300 px-4 py-2.5 text-sm text-gray-800"
          />
          <input
            type="text"
            placeholder="Last Name"
            value={state.last_name}
            onChange={(e) => setState({ ...state, last_name: e.target.value })}
            className="rounded-md focus:border-gray-400 focus:outline-none w-full focus:ring-0 border-gray-300 px-4 py-2.5 text-sm text-gray-800"
          />
          <input
            type="email"
            placeholder="Email"
            value={state.email}
            onChange={(e) => setState({ ...state, email: e.target.value })}
            className="rounded-md focus:border-gray-400 focus:outline-none w-full focus:ring-0 border-gray-300 px-4 py-2.5 text-sm text-gray-800"
          />
          <input
            type="text"
            value={state.phone_number}
            placeholder="Phone"
            onChange={(e) =>
              setState({ ...state, phone_number: e.target.value })
            }
            className="rounded-md focus:border-gray-400 focus:outline-none w-full focus:ring-0 border-gray-300 px-4 py-2.5 text-sm text-gray-800"
          />

          <button
            type="submit"
            className="bg-black disabled:cursor-not-allowed rounded-md font-medium text-sm disabled:bg-green-300 text-white p-2.5"
          >
            Save
          </button>
        </form>
      </Modal>
    </Fragment>
  );
};
