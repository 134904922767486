import React, { FC, Fragment } from "react";

import { Menu, Transition } from "@headlessui/react";
import { Link } from "react-router-dom";
import useAccount from "hooks/useUser";
import { logout } from "redux/reducers/userReducer";
import { useThunkDispatch } from "hooks/useThunkDispatch";
import { logOut } from "redux/actions/user";

type Props = {
  setSidebarOpen: (value: boolean) => void;
};

export const Banner: FC<Props> = ({ setSidebarOpen }) => {
  const { user } = useAccount();
  const dispatch = useThunkDispatch();

  return (
    <div className="relative  md:hidden  z-10 flex-shrink-0 flex rounded-lg mb-6">
      <button
        className="pr-4 text-gray-500 focus:outline-none lg:hidden"
        onClick={() => setSidebarOpen(true)}
      >
        <span className="sr-only">Open sidebar</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
          />
        </svg>
      </button>
      <div className="flex items-center justify-between w-full gap-3">
        {/* Profile dropdown */}
        <div />
        {/* <div className="hidden md:block flex-1 relative ">
                    <input
                        type="search"
                        placeholder="Search"
                        className="p-3 w-full bg-white focus:outline-none focus:ring-1 ring-lime-400 rounded-lg border border-gray-200"
                    />
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6 absolute right-3 text-gray-500 top-3.5">
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                        />
                    </svg>
                </div> */}
        {/* <div className="flex gap-2 border-r pr-3">
                    <div className="w-11 h-11 flex items-center justify-center bg-sky-500 bg-opacity-20 rounded-[15px]">
                        <img alt="" src={bellIcon} />
                    </div>
                    <div className="w-11 h-11 flex items-center justify-center bg-sky-500 bg-opacity-20 rounded-[15px]">
                        <img alt="" src={messageIcon} />
                    </div>
                    <div className="w-11 h-11 flex items-center justify-center bg-slate-500 bg-opacity-20 rounded-[15px]">
                        <img alt="" src={giftIcon} />
                    </div>
                    <div className="w-11 h-11 flex items-center justify-center bg-rose-500 bg-opacity-20 rounded-[15px]">
                        <img alt="" src={settingsIcon} />
                    </div>
                </div> */}
        {/* <Menu as="div" className=" relative">
          {({ open }) => (
            <>
              <Menu.Button className="max-w-xs gap-2 flex items-center focus:outline-none">
                <p className="text-gray-700 tracking-wide">
                  Hello,{" "}
                  <span className="font-semibold text-gray-900">
                    {user?.fullname}
                  </span>
                </p>

                <div className="h-11 w-11 bg-gray-500 rounded-full">
                  <img
                    alt="profile"
                    className="h-full w-full object-cover rounded-full"
                    src="https://via.placeholder.com/56x56"
                  />
                </div>
              </Menu.Button>
              <Transition
                show={open}
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-100"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  static
                  className="origin-top-right flex flex-col absolute right-0 mt-2 mr-3 w-52 rounded-md shadow-lg divide-y bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                  <Menu.Item>
                    <Link
                      to="/profile"
                      className="p-3 text-sm hover:text-logo-yellow text-gray-800 flex items-center gap-x-2"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1}
                        stroke="currentColor"
                        className="w-5 h-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                      </svg>
                      Profile
                    </Link>
                  </Menu.Item>
                  <Menu.Item>
                    <button
                      type="button"
                      onClick={() => dispatch(logOut())}
                      className="p-3 text-sm hover:text-logo-yellow text-gray-800 flex items-center gap-x-2"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75"
                        />
                      </svg>
                      Logout
                    </button>
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </>
          )}
        </Menu> */}
      </div>
    </div>
  );
};
