import swal from "sweetalert";
import { Modal } from "common/Modal";
import { Table } from "common/Table/Table";
import { useNotifications } from "hooks/useNotification";
import {
  Fragment,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import UserService from "services/user.service";
import { exportToCSV, formatDate } from "utils/funcs";
import { useRouter } from "hooks/useRouter";

const service = new UserService();

export const AdminifyCalendar = () => {
  const [state, setState] = useState({
    meetingLocation: "",
    calendarId: "",
  });
  const columns = [
    { key: "locationId", dbColName: "locationId", title: "location Id" },
    { key: "name", dbColName: "name", title: "name" },
    // { key: "description", dbColName: "description", title: "description" },
    {
      key: "meetingLocation",
      dbColName: "meetingLocation",
      title: "meeting Location",
    },

    {
      key: "action",
      freeze: true,
      freezeIndex: "right-0",
      title: "action",
      render: ({ meetingLocation, id }) => {
        return (
            <div className="flex gap-2">
                
          <button
            type="button"
            className="text-blue-500 text-right"
            onClick={() => {
              setShowModal(true);
              setState({
                meetingLocation,
                calendarId: id,
              });
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="h-6 w-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
              />
            </svg>
          </button>
          <button
              type="button"
              className="text-red-500 text-right"
              onClick={() => handleDelete(id)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="h-6 w-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                />
              </svg>
            </button>
          </div>
        );
      },
    },
  ];

  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const { push } = useRouter()

  const { errorMessage, successMessage } = useNotifications();

  const fetchData = useCallback(() => {
    setLoading(true);
    service
      .adminifyCalendar()
      .then((resp) => {
        console.log(resp);
        setData(resp.data.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const handleDelete = (id: string) => {
    swal({
      title: "Are you sure, you want to delete this data?",
      icon: "warning",
      className: "w-[500px]",
      buttons: {
        cancel: {
          text: "Cancel",
          visible: true,
        },
        confirm: {
          text: "Delete",
          className: "bg-primary text-white",
        },
      },
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setLoading(true);
        service.deleteCalendar(id).then((resp) => {
            successMessage("User deleted successfully");
          //   const shallowCopy = [...data];
          //   const newData = shallowCopy.filter(
          //     (user) => user.badge_id !== id
          //   );
          //   setData(newData);
          fetchData()
        }).catch((err) => {
          console.log(err)
        }).finally(() => {
          setLoading(false)
        }) 
      }
    });
  };


  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();

    const isValidForm = Object.values(state).every((value) => value);

    if (isValidForm) {
      service
        .updateCalendar(state)
        .then((resp) => {
          fetchData();
          setShowModal(false)
          setState({
            meetingLocation: "",
            calendarId: "",
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      errorMessage("Please fill al fields");
    }
  };

  const handleExport = () => {
    exportToCSV(data, "dealership");
  };

  return (
    <Fragment>
      <div className="flex  items-center gap-3 mb-4">
      <button
            type="button"
            onClick={() => push("/media-ads")}
            className="bg-white rounded-md p-2 border"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="h-6 w-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
              />
            </svg>
          </button>
        <div>
          <h1 className="font-medium text-gray-700 tracking-wide text-xl">
            Adminify Calendar
          </h1>
          <p className="text-gray-500 text-sm">View calendar </p>
        </div>
        
      </div>

      <Table
        data={data}
        loadingData={loading}
        handleExport={handleExport}
        totalItems={data.length}
        exportButton={false}
        columns={columns}
      />
      <Modal
        width="w-full md:w-1/3"
        showModal={showModal}
        setShowModal={setShowModal}
        title="Update Calendar"
      >
        <form onSubmit={handleSubmit} className="flex flex-col gap-4">
          <input
            type="text"
            placeholder="Meet Link"
            value={state.meetingLocation}
            onChange={(e) => setState({ ...state, meetingLocation: e.target.value })}
            className="rounded-md focus:border-gray-400 focus:outline-none w-full focus:ring-0 border-gray-300 px-4 py-2.5 text-sm text-gray-800"
          />
          {/* <input
            type="text"
            placeholder="Last Name"
            value={state.cal}
            onChange={(e) => setState({ ...state, last_name: e.target.value })}
            className="rounded-md focus:border-gray-400 focus:outline-none w-full focus:ring-0 border-gray-300 px-4 py-2.5 text-sm text-gray-800"
          /> */}

          <button
            type="submit"
            className="bg-black disabled:cursor-not-allowed rounded-md font-medium text-sm disabled:bg-green-300 text-white p-2.5"
          >
            Save
          </button>
        </form>
      </Modal>
    </Fragment>
  );
};
