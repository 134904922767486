import ReactApexChart from "react-apexcharts";

export const AdSpending = ({ stats }) => {
  return (
    <ReactApexChart
    height={400}
      series={[
        {
          name: "Total Ads",
          data: stats.map(
            (item: { total_ads: number }) => item.total_ads
          ),
        },
        {
          name: "Total Spent",
          data: stats.map(
            (item: { total_spent: number }) => item.total_spent
          ),
        },
      ]}
      options={{
        title: {
          text: "Total Ads Spending",
          align: "left",
        },
        chart: {
          height: 250,
          type: "area",
        },
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: "smooth",
        },
        xaxis: {
            labels: {
                formatter: function (value) {
                  return `Ad Package: ${value}`;
                }
              },
          categories: stats
            .map((item: { _id: string }) => item._id),
        },
      }}
    />
  );
};
