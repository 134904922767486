import { Table } from "common/Table/Table"
import { useDebounce } from "hooks/useDebounce"
import { Fragment, useCallback, useEffect, useState } from "react"
import UserService from "services/user.service"
import { exportToCSV, formatDate } from "utils/funcs"
import swal from "sweetalert";
import { useNotifications } from "hooks/useNotification"
import { formatPhone } from "./Contacts"


const service = new UserService();

export const MediaAds = () => {
  const columns = [
    {
      key: "badge_id",
      title: "badge id",
      label: "badge_id",
      dbColName: "badge_id",
    },
    {
      key: "firstName",
      title: "First name",
      label: "First name",
      isSort: true,
      dbColName: "userid",
      render: (value: any) => <p className="w-32">{value?.first_name}</p>,
    },
    {
      key: "userid.last_name",
      title: "Last name",
      dbColName: "userid",
      label: "Last name",
      render: (value: any) => <p className="w-32">{value?.last_name}</p>,
    },
    {
      key: "phone",
      title: "Phone",
      dbColName: "userid",
      render: (user) => formatPhone(user?.phone_number)
    },
    {
      key: "city",
      title: "city",
      isSort: true,
      label: "city",
      dbColName: "city",
    },
    {
      key: "state",
      title: "state",
      label: "state",
      isSort: true,
      dbColName: "state",
    },
    {
      key: "season",
      title: "season",
      label: "season",
      dbColName: "season",
    },
    // {
    //   key: "start_date",
    //   title: "start date",
    //   label: "start_date",
    //   dbColName: "start_date",
    // },
    // {
    //   key: "end_date",
    //   title: "end date",
    //   label: "end_date",
    //   dbColName: "end_date",
    // },
    {
      key: "position_for_list",
      title: "position for list",
      label: "position for list",
      dbColName: "position_for_list",
    },
    {
      key: "recruiting_support",
      title: "recruiting support",
      label: "recruiting support",
      dbColName: "recruiting_support",
    },
    {
      key: "amount",
      title: "amount",
      label: "amount",
      dbColName: "amount",
    },
    {
      key: "createdAt",
      title: "createdAt",
      label: "createdAt",
      dbColName: "createdAt",
      render: date => formatDate(date)
    },
    {
      key: "actions",
      title: "actions",
      freeze: true,
      freezeIndex: "right-0",
      render: ({  status, _id }) => {
        return (
           
            
            <button
              onClick={() => handleDelete(_id)}
              className="  text-red-500 rounded-md text-xs p-2 "
            >
               <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="h-6 w-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
              />
            </svg>
            </button>
        );
      },
    },
  ]

  const [data, setData] = useState([])
  const [totalItems, setTotalItems] = useState(0)
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 100,
    // state: "",
    // season: "",
    // recruiting_support: "",
  })

  const { successMessage } = useNotifications()

  const debounced = useDebounce(pagination, 500)

  const fetchData = useCallback(() => {
    setLoading(true);
    service
      .getMediaAds(debounced)
      .then((resp) => {
        setData(
          resp.data.data
        )
        setTotalItems(resp.data.totalDocument)
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [debounced])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const handleExport = () => {
    exportToCSV(data, "mediaads");
  }

  const handleDelete = id => {
    swal({
      title: "Are you sure, you want to delete this ad?",
      icon: "warning",
      className: "w-[500px]",
      buttons: {
        cancel: {
          text: "Cancel",
          visible: true,
        },
        confirm: {
          text: "Delete",
          className: "bg-primary text-white",
        },
      },
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setLoading(true);
        service.deleteMedia(id).then((resp) => {
          if (resp.data.success) {
            successMessage("Media ad deleted successfully");
            const shallowCopy = [...data];
            const newData = shallowCopy.filter((d: any) => d._id !== id);
            setData(newData);
          }
        }).catch((error) => {
          console.log(error);
        }).finally (() => {
          setLoading(false);
        })
      }
    })
  }

  const clearFilter = () => {
    setPagination(
      {
        pageNumber: 1,
        pageSize: 100,
      }
    )
  }

  return (
    <Fragment>
      <div className="flex flex-col mb-4">
        <h1 className="font-medium text-gray-700 tracking-wide text-xl">
          Media Ads
        </h1>
        <p className="text-gray-500 text-sm">View all Media Ads</p>
      </div>
      <Table
        data={data}
        pagination={pagination}
        setPagination={setPagination}
        loadingData={loading}
        setData={setData}
        handleExport={handleExport}
        filters={<Filters pagination={pagination} clearFilter={clearFilter} />}
        totalItems={totalItems}
        columns={columns}
        // columnFilters={
        //   <ColumnFilters
        //     pagination={pagination}
        //     setPagination={setPagination}
        //   />
        // }
      />
    </Fragment>
  )
}

const Filters = ({ clearFilter, pagination }) => {
  const filters = { ...pagination }
  delete filters.pageNumber
  delete filters.pageSize
  const hasFilters = Object.values(filters).some((item) => item)
  
  return hasFilters ? (
      <button
        onClick={() => clearFilter()}
        className="bg-black whitespace-nowrap rounded-lg text-white px-3 py-2 text-sm "
      >
        Clear Filters
      </button>
    ) : null
  
}

const ColumnFilters = ({ pagination, setPagination }) => {
  return (
    <div className="flex gap-4 mt-3">
      <input
        className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
        type="text"
        value={pagination.state}
        placeholder="Search by State"
        onChange={(e) => {
          setPagination({
            ...pagination,
            state: e.target.value,
          })
        }}
      />
      <input
        className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
        type="text"
        value={pagination.season}
        placeholder="Season"
        onChange={(e) => {
          setPagination({
            ...pagination,
            season: e.target.value,
          })
        }}
      />
      <input
        className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
        type="text"
        value={pagination.recruiting_support}
        placeholder="Recuriting Support"
        onChange={(e) => {
          setPagination({
            ...pagination,
            recruiting_support: e.target.value,
          })
        }}
      />
    </div>
  );
};
