import { Table } from "common/Table/Table";
import { useEffect, useState } from "react";
import UserService from "services/user.service";
import { exportToCSV } from "utils/funcs";

const service = new UserService();

export const CampusReel = () => {
  const columns = [
    { key: "UID", label: "UID", title: "UID", dbColName: "UID" },
    {
      key: "APPLICANT_ID",
      label: "APPLICANT_ID",
      title: "APPLICANT ID",
      dbColName: "APPLICANT_ID",
    },
    {
      key: "FIRST_NAME",
      label: "FIRST_NAME",
      title: "FIRST NAME",
      dbColName: "FIRST_NAME",
    },
    {
      key: "LAST_NAME",
      label: "LAST_NAME",
      title: "LAST NAME",
      dbColName: "LAST_NAME",
    },
    {
      key: "FULL_NAME",
      label: "FULL_NAME",
      title: "FULL NAME",
      dbColName: "FULL_NAME",
    },
    {
      key: "RECRUITER_NAME",
      label: "RECRUITER_NAME",
      title: "RECRUITER NAME",
      dbColName: "RECRUITER_NAME",
    },
    {
      key: "RECRUITER_PHONE",
      label: "RECRUITER_PHONE",
      title: "RECRUITER PHONE",
      dbColName: "RECRUITER_PHONE",
    },
    {
      key: "EVO_SOCIAL_MEDIA",
      label: "EVO_SOCIAL_MEDIA",
      title: "EVO SOCIAL MEDIA",
      dbColName: "EVO_SOCIAL_MEDIA",
    },
    {
      key: "SOCIAL_MEDIA_2",
      label: "SOCIAL_MEDIA_2",
      title: "SOCIAL MEDIA 2",
      dbColName: "SOCIAL_MEDIA_2",
    },
    {
      key: "SOCIAL_MEDIA_3",
      label: "SOCIAL_MEDIA_3",
      title: "SOCIAL MEDIA 3",
      dbColName: "SOCIAL_MEDIA_3",
    },
    {
      key: "EVO_VEMIO",
      label: "EVO_VEMIO",
      title: "EVO VEMIO",
      dbColName: "EVO_VEMIO",
    },
    {
      key: "EVO_WEBSITE",
      label: "EVO_WEBSITE",
      title: "EVO WEBSITE",
      dbColName: "EVO_WEBSITE",
    },
    { key: "PHONE", label: "PHONE", title: "PHONE", dbColName: "PHONE" },
    { key: "EMAIL", label: "EMAIL", title: "EMAIL", dbColName: "EMAIL" },
    { key: "SOURCE", label: "SOURCE", title: "SOURCE", dbColName: "SOURCE" },
    {
      key: "DATE_APPLIED",
      label: "DATE_APPLIED",
      title: "DATE APPLIED",
      dbColName: "DATE_APPLIED",
    },
    {
      key: "COUNTRY",
      label: "COUNTRY",
      title: "COUNTRY",
      dbColName: "COUNTRY",
    },
    {
      key: "HOME_ADDRESS",
      label: "HOME_ADDRESS",
      title: "HOME ADDRESS",
      dbColName: "HOME_ADDRESS",
    },
    {
      key: "HOME_CITY",
      label: "HOME_CITY",
      title: "HOME CITY",
      dbColName: "HOME_CITY",
    },
    {
      key: "HOME_STATE",
      label: "HOME_STATE",
      title: "HOME STATE",
      dbColName: "HOME_STATE",
    },
    {
      key: "HOME_ZIP_CODE",
      label: "HOME_ZIP_CODE",
      title: "HOME ZIP CODE",
      dbColName: "HOME_ZIP_CODE",
    },
    {
      key: "APPLICANT_TYPE",
      label: "APPLICANT_TYPE",
      title: "APPLICANT TYPE",
      dbColName: "APPLICANT_TYPE",
    },
    {
      key: "DEGREE_TYPE",
      label: "DEGREE_TYPE",
      title: "DEGREE TYPE",
      dbColName: "DEGREE_TYPE",
    },
    {
      key: "ENROLLED_DATE",
      label: "ENROLLED_DATE",
      title: "ENROLLED DATE",
      dbColName: "ENROLLED_DATE",
    },
    {
      key: "GRADUATION_YEAR",
      label: "GRADUATION_YEAR",
      title: "GRADUATION YEAR",
      dbColName: "GRADUATION_YEAR",
    },
    { key: "MAJORS", label: "MAJORS", title: "MAJORS", dbColName: "MAJORS" },
    {
      key: "COLLEGE_NAME",
      label: "COLLEGE_NAME",
      title: "COLLEGE NAME",
      dbColName: "COLLEGE_NAME",
    },
    {
      key: "COLLEGE_ADDRESS",
      label: "COLLEGE_ADDRESS",
      title: "COLLEGE ADDRESS",
      dbColName: "COLLEGE_ADDRESS",
    },
    {
      key: "COLLEGE_CITY",
      label: "COLLEGE_CITY",
      title: "COLLEGE CITY",
      dbColName: "COLLEGE_CITY",
    },
    {
      key: "COLLEGE_STATE",
      label: "COLLEGE_STATE",
      title: "COLLEGE STATE",
      dbColName: "COLLEGE_STATE",
    },
    {
      key: "COLLEGE_ZIP_CODE",
      label: "COLLEGE_ZIP_CODE",
      title: "COLLEGE ZIP CODE",
      dbColName: "COLLEGE_ZIP_CODE",
    },
    {
      key: "BIRTH_DAY",
      label: "BIRTH_DAY",
      title: "BIRTH DAY",
      dbColName: "BIRTH_DAY",
    },
    {
      key: "TIME_STAMP",
      label: "TIME_STAMP",
      title: "TIME STAMP",
      dbColName: "TIME_STAMP",
    },
    {
      key: "CREATED_AT",
      label: "CREATED_AT",
      title: "CREATED AT",
      dbColName: "CREATED_AT",
    },
    {
      key: "RECUITER_BADGE_ID",
      label: "RECUITER_BADGE_ID",
      title: "RECUITER BADGE ID",
      dbColName: "RECUITER_BADGE_ID",
    },
    {
      key: "CO_REGISTER",
      label: "CO_REGISTER",
      title: "CO REGISTER",
      dbColName: "CO_REGISTER",
    },
    {
      key: "JOB_TYPE",
      label: "JOB_TYPE",
      title: "JOB TYPE",
      dbColName: "JOB_TYPE",
    },
  ];

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 100,
  });

  useEffect(() => {
    setLoading(true);
    service
      .getCampusReels(pagination)
      .then((resp) => {
        setData(resp.data.data);
        setTotalItems(+resp.data.total_docs);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [pagination]);

  const handleExport = () => {
    exportToCSV(data, "reels")
  }

  return (
    <Table
      data={data}
      handleExport={handleExport}
      loadingData={loading}
      setPagination={setPagination}
      pagination={pagination}
      totalItems={totalItems}
      columns={columns}
    />
  );
};
