import React, { useState, useRef, useEffect } from "react";

const MultiSelectDropdown = ({
  options,
  onSelectionChange,
  defaultSelected = [],
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState<any>(defaultSelected);
  const dropdownRef = useRef<any>(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const toggleItem = (itemId) => {
    const newSelection = selectedItems.includes(itemId)
      ? selectedItems.filter((id) => id !== itemId)
      : [...selectedItems, itemId];

    setSelectedItems(newSelection);
    onSelectionChange?.(newSelection);
  }

  const toggleAll = () => {
    const allItemIds = options.map(option => option.id);
    const newSelection = selectedItems.length === options.length ? [] : allItemIds;
    
    setSelectedItems(newSelection);
    onSelectionChange?.(newSelection);
  };

  const isAllSelected = options.length > 0 && selectedItems.length === options.length;

  return (
    <div className="relative w-52" ref={dropdownRef}>
      {/* Dropdown Button */}
      <button
        onClick={toggleDropdown}
        className="w-full px-4 py-2 text-left bg-white border shadow-sm rounded-lg text-sm flex justify-between items-center  focus:outline-none focus:ring-0"
      >
        <span className="text-gray-700">
          {selectedItems.length === 0
            ? "Select items..."
            : `${selectedItems.length} selected`}
        </span>
        {isOpen ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="h-4 w-4"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m4.5 15.75 7.5-7.5 7.5 7.5"
            />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="h-4 w-4"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m19.5 8.25-7.5 7.5-7.5-7.5"
            />
          </svg>
        )}
      </button>

      {/* Dropdown Menu */}
      {isOpen && (
        <div className="absolute mt-1 w-full bg-white border rounded-lg shadow-lg z-10">
         
          <ul className="py-1">
               {/* Select All Option */}
               <li className="border-b">
              <button
                onClick={toggleAll}
                className="w-full px-4 py-2 text-left flex items-center space-x-2 hover:bg-gray-100 font-medium"
              >
                <div className={`w-4 h-4 border rounded flex items-center justify-center
                  ${isAllSelected 
                    ? 'bg-blue-500 border-blue-500' 
                    : 'border-gray-300'}`}
                >
                  {isAllSelected && (
                    <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="h-4  w-4 text-white"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m4.5 12.75 6 6 9-13.5"
                    />
                  </svg>
                  )}
                </div>
                <span className="text-gray-700">Select All</span>
              </button>
            </li>
            {options.map((option) => (
              <li key={option.id}>
                <button
                  onClick={() => toggleItem(option.id)}
                  className="w-full px-4 py-2 text-left flex items-center space-x-2 hover:bg-gray-100"
                >
                  <div
                    className={`w-4 h-4 border rounded flex items-center justify-center
                    ${
                      selectedItems.includes(option.id)
                        ? "bg-blue-500 border-blue-500"
                        : "border-gray-300"
                    }`}
                  >
                    {selectedItems.includes(option.id) && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="h-4  w-4 text-white"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m4.5 12.75 6 6 9-13.5"
                        />
                      </svg>
                    )}
                  </div>
                  <span className="text-gray-700">{option.label}</span>
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default MultiSelectDropdown;
