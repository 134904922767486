import { Table } from "common/Table/Table";
import { useEffect, useState } from "react";
import UserService from "services/user.service";
import { exportToCSV } from "utils/funcs";

const service = new UserService();

export const ZipCodes = () => {
  const columns = [
    { key: "ZIP", title: "ZIP", label: "ZIP", dbColName: "ZIP" },
    { key: "CITY", title: "CITY", label: "CITY", dbColName: "CITY" },
    {
      key: "STATE_ID",
      title: "STATE ID",
      label: "STATE ID",
      dbColName: "STATE_ID",
    },
    {
      key: "STATE_NAME",
      title: "STATE NAME",
      label: "STATE NAME",
      dbColName: "STATE_NAME",
    },
    {
      key: "COUNTRY",
      title: "COUNTRY",
      label: "COUNTRY",
      dbColName: "COUNTRY",
    },
    {
      key: "TIMEZONE",
      title: "TIMEZONE",
      label: "TIMEZONE",
      dbColName: "TIMEZONE",
    },
  ];

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 100,
  });

  useEffect(() => {
    setLoading(true);
    service
      .getZipCodes(pagination)
      .then((resp) => {
        setData(resp.data.data);
    
        setTotalItems(+resp.data.total_docs);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [pagination]);

  const handleExport = () => {
    exportToCSV(data, "zpicodes")
  }

  return (
    <Table
      data={data}
      loadingData={loading}
      handleExport={handleExport}
      setPagination={setPagination}
      pagination={pagination}
      totalItems={totalItems}
      columns={columns}
    />
  );
};
