import classNames from "classnames";
import { Table } from "common/Table/Table";
import { useRouter } from "hooks/useRouter";
import { useEffect, useState } from "react";
import UserService from "services/user.service";
import { tabStyles } from "./GeneralData";
import { exportToCSV, formatDate } from "utils/funcs";
import { formatPhone } from "./Contacts";

const service = new UserService();

export const BlackSparrowUsers = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>({});
  const { push, query } = useRouter();

  const [tab, setTab] = useState(query?.tab || "attende_hire");

  const training = [
    { key: "rep_id", dbColName: "rep_id", title: "rep id" },
    { key: "rep_name", dbColName: "rep_name", title: "rep name" },
    { key: "tenure", dbColName: "tenure", title: "tenure" },
    { key: "max_position", dbColName: "max_position", title: "max position" },
    { key: "email", dbColName: "email", title: "email" },
    { key: "phone", dbColName: "phone", title: "phone" },
    {
      key: "completed_sales_training_date",
      dbColName: "completed_sales_training_date",
      title: "completed sales training date",
      render: (date) => formatDate(date),
    },
    { key: "manager_name", dbColName: "manager_name", title: "manager name" },
    {
      key: "meeting_date",
      dbColName: "meeting_date",
      title: "meeting date",
      render: (date) => formatDate(date),
    },
    { key: "location", dbColName: "location", title: "location" },
    // {key: "meeting_time", dbColName: "meeting_time", title: "meeting_time"},
  ];

  const ran = [
    { key: "rep_id", dbColName: "rep_id", title: "rep_id" },
    { key: "phone", dbColName: "phone", title: "phone" },
    { key: "manager_name", dbColName: "manager_name", title: "manager_name" },
    {
      key: "meeting_date",
      dbColName: "meeting_date",
      title: "meeting_date",
      render: (date) => formatDate(date),
    },
    { key: "location", dbColName: "location", title: "location" },
    //  {key:"meeting_time", dbColName: "meeting_time", title: "meeting_time"},
  ];

  const onboard = [
    { key: "rep_id", dbColName: "rep_id", title: "rep id" },
    { key: "rep_name", dbColName: "rep_name", title: "rep name" },
    { key: "location", dbColName: "location", title: "location" },
    { key: "tenure", dbColName: "tenure", title: "tenure" },
    { key: "max_position", dbColName: "max_position", title: "max position" },
    {
      key: "onboarded_date",
      dbColName: "onboarded_date",
      title: "onboarded date",
      render: (date) => formatDate(date),
    },
    { key: "email", dbColName: "email", title: "email" },
    { key: "phone", dbColName: "phone", title: "phone" },
    { key: "manager_name", dbColName: "manager_name", title: "manager name" },
    // { key: "meeting_time", dbColName: "meeting_time", title: "meeting time" },
    {
      key: "meeting_date",
      dbColName: "meeting_date",
      title: "meeting_date",
      render: (date) => formatDate(date),
    },
  ];

  const lastGenie = [
    { key: "rep_id", dbColName: "rep_id", title: "rep id" },
    { key: "rep_name", dbColName: "rep_name", title: "rep name" },
    { key: "tenure", dbColName: "tenure", title: "tenure" },
    { key: "max_position", dbColName: "max_position", title: "max position" },
    { key: "location", dbColName: "location", title: "location" },
    { key: "email", dbColName: "email", title: "email" },
    { key: "phone", dbColName: "phone", title: "phone" },
    { key: "manager_name", dbColName: "manager_name", title: "manager name" },
    {
      key: "last_genie_login_date",
      dbColName: "last_genie_login_date",
      title: "last_genie_login_date",
      render: (date) => formatDate(date),
    },
    {
      key: "meeting_date",
      dbColName: "meeting_date",
      title: "meeting_date",
      render: (date) => formatDate(date),
    },
    // { key: "meeting_time", dbColName: "meeting_time", title: "meeting time" },
  ];

  const attendeeColumns = [
    {
      key: "first_name",
      dbColName: "first_name",
      title: "first name",
      freeze: true,
      freezeIndex: "left-0",
    },
    { key: "last_name", dbColName: "last_name", title: "last name" },
    { key: "title", dbColName: "title", title: "title" },
    {
      key: "phone",
      dbColName: "phone",
      title: "phone",
      render: (phone) => formatPhone(phone),
    },
    { key: "status", dbColName: "status", title: "status" },
    { key: "location", dbColName: "location", title: "location" },
    { key: "manager_name", dbColName: "manager_name", title: "manager name" },
    {
      key: "meeting_date",
      dbColName: "meeting_date",
      title: "meeting date",
      render: (date) => formatDate(date),
    },
    {
      key: "meeting_start_time",
      dbColName: "meeting_start_time",
      title: "meeting start time",
    },
    {
      key: "meeting_end_time",
      dbColName: "meeting_end_time",
      title: "meeting end time",
    },
    {
      key: "createdAt",
      dbColName: "createdAt",
      title: "createdAt",
      render: (date) => formatDate(date),
    },
  ];

  const goalset = [
    { key: "badge_id", dbColName: "badge_id", title: "badge id" },
    {
      key: "fullname",
      dbColName: "fullname",
      title: "full name",
      freeze: true,
      freezeIndex: "left-0",
    },
    { key: "phone_number", dbColName: "phone_number", title: "phone number" },
    { key: "email", dbColName: "email", title: "email" },
    {
      key: "personal_preseason_efp",
      dbColName: "personal_preseason_efp",
      title: "personal preseason efp",
    },
    {
      key: "personal_preseason_reps",
      dbColName: "personal_preseason_reps",
      title: "personal preseason reps",
    },
    {
      key: "personal_preseason_reps_sale",
      dbColName: "personal_preseason_reps_sale",
      title: "personal preseason reps sale",
    },
    {
      key: "personal_preseason_rookies_sale",
      dbColName: "personal_preseason_rookies_sale",
      title: "personal preseason rookies sale",
    },
    {
      key: "personal_sale_season_efp",
      dbColName: "personal_sale_season_efp",
      title: "personal sale season efp",
    },
    {
      key: "personal_sale_season_reps",
      dbColName: "personal_sale_season_reps",
      title: "personal sale season reps",
    },
    {
      key: "personal_sale_season_reps_sale",
      dbColName: "personal_sale_season_reps_sale",
      title: "personal sale season reps sale",
    },
    {
      key: "personal_sale_season_rookies_sale",
      dbColName: "personal_sale_season_rookies_sale",
      title: "personal sale season rookies sale",
    },
    { key: "manager_name", dbColName: "manager_name", title: "manager name" },
    {
      key: "meeting_date",
      dbColName: "meeting_date",
      title: "meeting date",
      render: (date) => formatDate(date),
    },
    { key: "location", dbColName: "location", title: "location" },
    { key: "meeting_time", dbColName: "meeting_time", title: "meeting time" },
    { key: "rep_id", dbColName: "rep_id", title: "rep id" },
  ];

  const columns = [
    {
      key: "first_name",
      dbColName: "first_name",
      title: "first name",
      freeze: true,
      freezeIndex: "left-0",
    },
    { key: "last_name", dbColName: "last_name", title: "last name" },
    {
      key: "phone",
      dbColName: "phone",
      title: "phone",
    },
    {
      key: "date_submission",
      dbColName: "date_submission",
      title: "date submission",
    },
    {
      key: "rep_tenure",
      dbColName: "rep_tenure",
      title: "rep tenure",
    },
    {
      key: "createdAt",
      dbColName: "createdAt",
      title: "createdAt",
      render: (date) => formatDate(date),
    },
  ];

  const installsUprades = [
      {key: "rep_id", dbColName: "rep_id", title: "rep id"},
      {key: "manager_name", dbColName: "manager_name", title: "manager name"},
      {key: "meeting_date", dbColName: "meeting_date", title: "meeting date", render: date=> formatDate(date)},
      {key: "location", dbColName: "location", title: "location"},
      {key: "meeting_time", dbColName: "meeting_time", title: "meeting time"},
  ]

  // const proData = [
  //   { key: "rep_id", dbColName: "rep_id", title: "rep id" },
  //   {
  //     key: "opportunity_hash",
  //     dbColName: "opportunity_hash",
  //     title: "opportunity hash",
  //   },
  //   { key: "service_hash", dbColName: "service_hash", title: "service hash" },
  //   { key: "payable_rmr", dbColName: "payable_rmr", title: "payable rmr" },
  //   { key: "is_upgrade", dbColName: "is_upgrade", title: "is upgrade" },
  //   { key: "is_funded", dbColName: "is_funded", title: "is funded" },
  //   {
  //     key: "install_date_and_time",
  //     dbColName: "install_date_and_time",
  //     title: "install date and time",
  //     render: date => new Date(date).toLocaleString()
  //   },
  //   { key: "finance_type", dbColName: "finance_type", title: "finance type" },
  //   { key: "tenure", dbColName: "tenure", title: "tenure" },
  //   {
  //     key: "ytd_funded_filter",
  //     dbColName: "ytd_funded_filter",
  //     title: "ytd funded filter",
  //   },
  //   { key: "sales_year", dbColName: "sales_year", title: "sales year" },
  //   { key: "efp", dbColName: "efp", title: "efp" },
  //   { key: "phone", dbColName: "phone", title: "phone", render: phone => formatPhone(phone) },
  //   // { key: "training_id", dbColName: "training_id", title: "training id" },
  //   { key: "manager_name", dbColName: "manager_name", title: "manager name" },
  //   { key: "type", dbColName: "type", title: "type" },
  //   { key: "title", dbColName: "title", title: "title" },
  //   { key: "meeting_date", dbColName: "meeting_date", title: "meeting date", render: date => formatDate(date) },
  //   { key: "location", dbColName: "location", title: "location" },
  //   { key: "meeting_time", dbColName: "meeting_time", title: "meeting time" },
  // ];

  const repColumns = [
    { key: "rep_id", dbColName: "rep_id", title: "rep id" },
    {
      key: "rep_name",
      dbColName: "rep_name",
      title: "rep name",
      freeze: true,
      freezeIndex: "left-0",
    },
    { key: "tenure", dbColName: "tenure", title: "tenure" },
    { key: "max_position", dbColName: "max_position", title: "max position" },
    { key: "email", dbColName: "email", title: "email" },
    {
      key: "phone",
      dbColName: "phone",
      title: "phone",
      render: (phone) => formatPhone(phone),
    },
    {
      key: "agreement_sent_date",
      dbColName:
        tab === "onboarded_date"
          ? "onboarded_date"
          : tab === "contract_signed"
          ? "agreement_signed_date"
          : "agreement_sent_date",
      title:
        tab === "onboarded_date" ? "onboarded date" : "agreement sent date",
      render: (date) => formatDate(date),
    },
  ];

  const register_for_blitz = [
    {
      key: "first_name",
      dbColName: "first_name",
      title: "first_name",
      freeze: true,
      freezeIndex: "left-0",
    },
    { key: "last_name", dbColName: "last_name", title: "last_name" },
    { key: "tenure", dbColName: "tenure", title: "tenure" },
    { key: "badge_id", dbColName: "badge_id", title: "badge_id" },
    { key: "rep_end_date", dbColName: "rep_end_date", title: "rep end date" },
    { key: "position", dbColName: "position", title: "position" },
    { key: "manager_name", dbColName: "manager_name", title: "manager name" },
    {
      key: "meeting_date",
      dbColName: "meeting_date",
      title: "meeting date",
      render: (date) => formatDate(date),
    },
    { key: "location", dbColName: "location", title: "location" },
    // {key: "meeting_time", dbColName: "meeting_time", title: "meeting time"},
    {
      key: "createdAt",
      dbColName: "createdAt",
      title: "createdAt",
      render: (date) => formatDate(date),
    },
  ];

  const [production, setProduction] = useState([])

  useEffect(() => {
    setLoading(true);
    service
      .getSparrowUsers(query)
      .then((resp) => {
        setData(resp.data);
        setProduction(resp.data.production_data);
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setLoading(false);
      });
  }, [query]);

  const handleExport = () => {
    exportToCSV(data[tab], "users");
  };

  return (
    <div>
      <div className="flex gap-4 items-start">
        <button
          type="button"
          onClick={() => push("/black-sparrow?tab=Reports")}
          className="bg-white rounded-md p-2 border"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="h-6 w-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
            />
          </svg>
        </button>
        <div className="flex flex-col mb-4">
          <h1 className="font-medium text-gray-700 tracking-wide text-xl">
            Black Sparrow Users
          </h1>
          <p className="text-gray-500 text-sm">View all users</p>
        </div>
      </div>

      <div className="text-sm mb-4 p-2 text-center text-gray-500 rounded-md bg-gray-200">
        <div className="flex flex-wrap -mb-px gap-3">
          <button
            type="button"
            onClick={() => setTab("attende_hire")}
            className={classNames(tabStyles.default, "text-xs", {
              [tabStyles.active]: tab === "attende_hire",
            })}
          >
            Attendee Hire
          </button>
          <button
            type="button"
            onClick={() => setTab("attende_offer")}
            className={classNames(tabStyles.default, "text-xs", {
              [tabStyles.active]: tab === "attende_offer",
            })}
          >
            Attendee Offer
          </button>
          <button
            type="button"
            onClick={() => setTab("goalsSet")}
            className={classNames(tabStyles.default, "text-xs", {
              [tabStyles.active]: tab === "goalsSet",
            })}
          >
            Goals Set
          </button>
          <button
            type="button"
            onClick={() => setTab("group_infosession")}
            className={classNames(tabStyles.default, "text-xs", {
              [tabStyles.active]: tab === "group_infosession",
            })}
          >
            Group Infosession Scheduled
          </button>
          <button
            type="button"
            onClick={() => setTab("group_infosession_raffle")}
            className={classNames(tabStyles.default, "text-xs", {
              [tabStyles.active]: tab === "group_infosession_raffle",
            })}
          >
            Group Infosession Attended
          </button>
          <button
            type="button"
            onClick={() => setTab("register_for_blitz")}
            className={classNames(tabStyles.default, "text-xs", {
              [tabStyles.active]: tab === "register_for_blitz",
            })}
          >
            Register for Blitz
          </button>
          <button
            type="button"
            onClick={() => setTab("contract_sent")}
            className={classNames(tabStyles.default, "text-xs", {
              [tabStyles.active]: tab === "contract_sent",
            })}
          >
            Contract Sent
          </button>
          <button
            type="button"
            onClick={() => setTab("contract_signed")}
            className={classNames(tabStyles.default, "text-xs", {
              [tabStyles.active]: tab === "contract_signed",
            })}
          >
            Agreement Signed
          </button>
          <button
            type="button"
            onClick={() => setTab("last_credit_run_date")}
            className={classNames(tabStyles.default, "text-xs", {
              [tabStyles.active]: tab === "last_credit_run_date",
            })}
          >
            Ran
          </button>
          <button
            type="button"
            onClick={() => setTab("upgrades")}
            className={classNames(tabStyles.default, "text-xs", {
              [tabStyles.active]: tab === "upgrades",
            })}
          >
            Upgrades
          </button>
          <button
            type="button"
            onClick={() => setTab("installs")}
            className={classNames(tabStyles.default, "text-xs", {
              [tabStyles.active]: tab === "installs",
            })}
          >
            Sales Install
          </button>
          <button
            type="button"
            onClick={() => setTab("last_street_genie_login")}
            className={classNames(tabStyles.default, "text-xs", {
              [tabStyles.active]: tab === "last_street_genie_login",
            })}
          >
            Street Genie Login
          </button>
          <button
            type="button"
            onClick={() => setTab("completed_training")}
            className={classNames(tabStyles.default, "text-xs", {
              [tabStyles.active]: tab === "completed_training",
            })}
          >
            Completed Training
          </button>
        </div>
      </div>
      
        <Table
          loadingData={loading}
          handleExport={handleExport}
          columns={
            tab === "completed_training" ||
            tab === "contract_sent" ||
            tab === "contract_signed" ||
            tab === "onboarded_date"
              ? repColumns
              : tab === "attende_hire" ||
                tab === "attende_offer" ||
                tab === "group_infosession_raffle" ||
                tab === "group_infosession"
              ? attendeeColumns
              : tab === "goalsSet"
              ? goalset
              : tab === "onboarded_date"
              ? onboard
              : tab === "onboarded_date"
              ? onboard
              : tab === "last_street_genie_login"
              ? lastGenie
              : tab === "completed_training"
              ? training
              : tab === "last_credit_run_date"
              ? ran
              : tab === "register_for_blitz"
              ? register_for_blitz
              : tab === "installs" || tab === "upgrades"
              ? installsUprades
              : columns
          }
          data={Object.keys(data).length > 0 && data[tab] ? data[tab] : []}
          totalItems={0}
        />
      
    </div>
  );
};
