import { Table } from "common/Table/Table";
import { useDebounce } from "hooks/useDebounce";
import { Fragment, useCallback, useEffect, useState } from "react";
import UserService from "services/user.service";
import { exportToCSV, formatDate } from "utils/funcs";
import swal from "sweetalert";
import { useNotifications } from "hooks/useNotification";
import { formatPhone } from "./Contacts";
import { useRouter } from "hooks/useRouter";
import { AddMediaModal } from "components/AddMediaModal";
import { useSelector } from "react-redux";
import { userSelector } from "redux/selectors/user";
import CopyText from "common/CopyText";

const service = new UserService();

export const access = [
  "muhammadubaid8@gmail.com",
  "cthatcher@vivint.com",
  "crosspalalas@gmail.com",
  "kaxhiftaj@gmail.com",
  "axiftaj@gmail.com",
];

export const MediaAds = () => {
  const router = useRouter();
  const user = useSelector(userSelector);
  const [selected, setSelected] = useState({
    ad_status: "",
    ad_approval_status: "",
    ad_type: "",
    state: "",
    city_name: "",
    package_name: "",
    meet_link: "",
    package_start_date: "",
    package_end_date: "",
    season: "",
    position: "",
    id: "",
  });

  const columns = [
    {
      key: "badge_id",
      dbColName: "_id",
      title: "Ad id",
      render: (id) => (
        <CopyText copyText={id} text={`${id.toString().slice(0, 8)}...`} />
      ),
    },
    { key: "badge_id", dbColName: "badge_id", title: "badge id" },
    {
      key: "firstName",
      title: "First name",
      label: "First name",
      dbColName: "first_name",
    },
    {
      key: "last_name",
      title: "Last name",
      dbColName: "last_name",
      label: "Last name",
    },
    {key: "zip_code", dbColName: "personal_gmail_account", title: "email"},

    {
      key: "phone",
      title: "Phone",
      dbColName: "userid",
      render: (user) => formatPhone(user?.phone_number),
    },
    {
      key: "meeting_link",
      dbColName: "meet_link",
      title: "meeting link",
      render: (link) => <CopyText copyText={link} text={link} />,
    },
    { key: "state", dbColName: "state", title: "state" },
    { key: "city_name", dbColName: "city_name", title: "city name" },
    { key: "ad_type", dbColName: "ad_type", title: "ad type" },
    { key: "ad_user_type", dbColName: "ad_user_type", title: "ad user type" },
    {
      key: "ad_approval_status",
      dbColName: "ad_approval_status",
      title: "ad approval status",
    },
    { key: "ad_status", dbColName: "ad_status", title: "ad status" },
    { key: "season", dbColName: "season", title: "season" },
    {
      key: "ad_amount",
      dbColName: "ad_amount",
      title: "ad amount",
      render: (amount) => `$${amount}`,
    },
    // {key: "package_name", dbColName: "package_name", title: "package name"},
    // {key: "first_name", dbColName: "first_name", title: "first_name"},
    // {key: "last_name", dbColName: "last_name", title: "last_name"},
    // {key: "full_name", dbColName: "full_name", title: "full name"},
    {
      key: "package_start_date",
      dbColName: "package_start_date",
      title: "start date",
      isSort: true,
      render: (date) => formatDate(date),
    },
    {
      key: "package_end_date",
      dbColName: "package_end_date",
      title: " end date",
      render: (date) => formatDate(date),
    },
    {
      key: "ad_run_day_count",
      dbColName: "ad_run_day_count",
      title: "ad run day count",
    },
    {
      key: "createdAt",
      dbColName: "createdAt",
      title: "createdAt",
      render: (date) => formatDate(date),
    },

    {
      key: "actions",
      title: "actions",
      freeze: true,
      freezeIndex: "right-0",
      render: ({
        ad_approval_status,
        ad_type,
        city_name,
        state,
        ad_status,
        meet_link,
        season,
        position,
        package_name,
        package_start_date,
        package_end_date,
        _id,
      }) => {
        return (
          access.includes(user.email) && (
            <div className="flex items-center gap-2">
              <button
                type="button"
                onClick={() => router.push(`/media-ad/${_id}`)}
                className="text-blue-500"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="h-6 w-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                </svg>
              </button>
              <button
                onClick={() => handleDelete(_id)}
                className="  text-red-500 rounded-md text-xs p-2 "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="h-6 w-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                  />
                </svg>
              </button>
              <button
                type="button"
                onClick={() => {
                  setShowModal(true);
                  setSelected({
                    ...selected,
                    state: state,
                    season: season,
                    position: position,
                    city_name: city_name,
                    package_start_date: package_start_date,
                    package_end_date: package_end_date,
                    package_name: package_name,
                    ad_type: ad_type,
                    ad_approval_status: ad_approval_status,
                    ad_status: ad_status,
                    meet_link: meet_link,
                    id: _id,
                  });
                }}
                className="  text-blue-500 rounded-md text-xs p-2 "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="h-6 w-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                  />
                </svg>
              </button>
            </div>
          )
        );
      },
    },
  ];

  const [data, setData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 100,
    fullName: "",
    addStatus: "",
    adUserType: "",
    adApprovalStatus: "",
    season: "",
  });

  const { successMessage } = useNotifications();
  const { push } = useRouter();

  const debounced = useDebounce(pagination, 500);

  const fetchData = useCallback(() => {
    setLoading(true);
    service
      .getMediaAds(debounced)
      .then((resp) => {
        if (resp.data.success) {
          setData(resp.data.data);
          setTotalItems(resp.data.totalDocument);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [debounced]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleExport = () => {
    exportToCSV(data, "mediaads");
  };

  const handleDelete = (id) => {
    swal({
      title: "Are you sure, you want to delete this ad?",
      icon: "warning",
      className: "w-[500px]",
      buttons: {
        cancel: {
          text: "Cancel",
          visible: true,
        },
        confirm: {
          text: "Delete",
          className: "bg-primary text-white",
        },
      },
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setLoading(true);
        service
          .deleteMedia(id)
          .then((resp) => {
            if (resp.data.success) {
              successMessage("Media ad deleted successfully");
              const shallowCopy = [...data];
              const newData = shallowCopy.filter((d: any) => d._id !== id);
              setData(newData);
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    });
  };

  const clearFilter = () => {
    setPagination({
      pageNumber: 1,
      pageSize: 100,
      fullName: "",
      addStatus: "",
      adUserType: "",
      adApprovalStatus: "",
      season: "",
    });
  };

  return (
    <Fragment>
      <div className="flex items-center justify-between">
        <div className="flex flex-col mb-4">
          <h1 className="font-medium text-gray-700 tracking-wide text-xl">
            Media Ads
          </h1>
          <p className="text-gray-500 text-sm">View all Media Ads</p>
        </div>
        <div className="flex gap-2 items-center">
          <button
            type="button"
            onClick={() => push("/media-stats")}
            className="bg-black px-4 gap-2 flex items-center text-sm py-2.5 rounded-md text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="h-5 w-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 0 1 3 19.875v-6.75ZM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 0 1-1.125-1.125V8.625ZM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 0 1-1.125-1.125V4.125Z"
              />
            </svg>
            <span>Media Stats</span>
          </button>
          <button
            type="button"
            onClick={() => push("/add/media-ad")}  
            className="bg-black px-4 gap-2 flex items-center text-sm py-2.5 rounded-md text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
               className="h-5 w-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 4.5v15m7.5-7.5h-15"
              />
            </svg>

            <span>Request Ad</span>
          </button>
          <button
                type="button"
                onClick={() =>
                  push('/adminify-calendar')
                }
                className="bg-black px-4 gap-2 flex items-center text-sm py-2.5 rounded-md text-white"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="h-5 w-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
                  />
                </svg>

                <span>View Adminify Calendar</span>
              </button>
        </div>
      </div>
      <Table
        data={data}
        pagination={pagination}
        setPagination={setPagination}
        loadingData={loading}
        setData={setData}
        handleExport={handleExport}
        filters={<Filters pagination={pagination} clearFilter={clearFilter} />}
        totalItems={totalItems}
        columns={columns}
        columnFilters={
          <ColumnFilters
            pagination={pagination}
            setPagination={setPagination}
          />
        }
      />
      <AddMediaModal
        selected={selected}
        fetchData={fetchData}
        setSelected={setSelected}
        showModal={showModal}
        setShowModal={setShowModal}
      />
    </Fragment>
  );
};

const Filters = ({ clearFilter, pagination }) => {
  const filters = { ...pagination };
  delete filters.pageNumber;
  delete filters.pageSize;
  const hasFilters = Object.values(filters).some((item) => item);

  return hasFilters ? (
    <button
      onClick={() => clearFilter()}
      className="bg-black whitespace-nowrap rounded-lg text-white px-3 py-2 text-sm "
    >
      Clear Filters
    </button>
  ) : null;
};

const ColumnFilters = ({ pagination, setPagination }) => {
  return (
    <div className="flex gap-4 mt-3">
      <div className="flex-1">
        <label className="text-xs text-gray-700">Name</label>
        <input
          className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
          type="text"
          value={pagination.fullName}
          placeholder="Search by Name"
          onChange={(e) => {
            setPagination({
              ...pagination,
              fullName: e.target.value,
            });
          }}
        />
      </div>
      <div className="flex-1">
        <label className="text-xs text-gray-700">Ad Status</label>
        <select
          value={pagination.addStatus}
          className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
          onChange={(e) =>
            setPagination({
              ...pagination,
              addStatus: e.target.value,
            })
          }
        >
          <option value="All">All</option>
          <option value="In Process">In Process</option>
          <option value="Upcoming">Upcoming</option>
          <option value="Completed">Completed</option>
        </select>
      </div>
      <div className="flex-1">
        <label className="text-xs text-gray-700">Approval Status</label>
        <select
          className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
          value={pagination.adApprovalStatus}
          onChange={(e) =>
            setPagination({
              ...pagination,
              adApprovalStatus: e.target.value,
            })
          }
        >
          <option value="All">All</option>
          <option value="UNDER REVIEW">UNDER REVIEW</option>
          <option value="DENIED">DENIED</option>
          <option value="APPROVED">APPROVED</option>
        </select>
      </div>
      <div className="flex-1">
        <label className="text-xs text-gray-700">Season</label>
        <select
          className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
          value={pagination.season}
          onChange={(e) =>
            setPagination({
              ...pagination,
              season: e.target.value,
            })
          }
        >
          <option value="All">All</option>
          <option value="Summer">Summer</option>
          <option value="Year Round">Year Round</option>
          <option value="Summer & Year Round">Summer & Year Round</option>
        </select>
      </div>
      <div className="flex-1">
        <label className="text-xs text-gray-700">Ad User Type</label>
        <select
          className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
          value={pagination.adUserType}
          onChange={(e) =>
            setPagination({
              ...pagination,
              adUserType: e.target.value,
            })
          }
        >
          <option value="">Select</option>
          <option value="User">User</option>
          <option value="Dealership">Dealership</option>
        </select>
      </div>
    </div>
  );
};
